// import React from "react";
import { useAppSelector, useAppDispatch } from "./utils/hooks";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    useRouteMatch,
    useLocation,
    useHistory,
} from "react-router-dom";
import MyModal, { Message } from "./presentation/Modal";
import Spinner from "./presentation/Spinner";
import Sidebar from "./presentation/Sidebar";
import Home from "./container/home";
import Auth from "./container/auth";
import { toggleModal } from "./store/modal/actions";
import { appThunkLogout, initWalletConnect } from "./store/auth/actions";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { MODAL_TOGGLE_OFF } from "./store/modal/types";
import { WebErrorType } from "./utils/error";
import COLOR from "./styled/colors";
import ToolBar from "./presentation/ToolBar";
import CreatePlugin from "./container/plugin/CreatePlugin";
import Plugins from "./container/plugin/Plugins";
import UploadForm from "./container/form/UploadForm";
import Users from "./container/monitor/users";
import BusinessAccount from "./container/monitor/business/businessAccount";
import AnimalsCount from "./container/monitor/angus/animalsCount";
import ActiveBusiness from "./container/monitor/business/activeBusiness";
import AngusToken from "./container/monitor/angus/angusToken";
import PluginActive from "./container/monitor/business/pluginActive";
import PromotionCount from "./container/monitor/promotion/promotionCount";
import BlockchainSearch from "./container/monitor/blockchain/blockchainSearchPage";
import BlockchainTransactions from "./container/monitor/blockchain/blockchainTransactions";
import UploadAmbioxera from "./container/form/ambioxera/UploadAmbioxera";
import BusinessUserManageList from "./container/monitor/add/businessUserManageList";
import BusinessPluginManage from "./container/monitor/business/businessPluginManage/BusinessPluginManage";
import BusinessPluginInfo from "./container/monitor/business/businessPluginInfo";
import SingleUserManage from "./container/monitor/add/singleUserManage";
import DeleteTokenManage from "./container/monitor/delete/DeleteTokenManage";
import KillSheetManage from "./container/monitor/killsheet";
import UploadMackas from "./container/form/uploadCSV/UploadMackas";

import QaDashboard from "./container/qa/Dashboard";
import CreateAccount from "./container/qa/CreateAccount";
import AccountInfo from "./container/qa/AccountInfo";
import CSV from "./container/generation/CSV";
import CreateSession from "./container/generation/CreateSession";
import ViewSession from "./container/generation/ViewSession";
import GenerateCSV from "./container/generation/GenerateCSV";
import AlertDashboard from "./container/alert/Dashboard";
import CreateAlert from "./container/alert/CreateAlert";
import AlertInfo from "./container/alert/AlertInfo";

export default function RootRoute() {
    const dispatch = useAppDispatch();
    const { isLoggedIn } = useAppSelector((state) => state.auth);
    const { isOpen: isSpinnerOpen, loadingMessage } = useAppSelector((state) => state.spinner);
    const { isOpen: isModalOpen, ...modalState } = useAppSelector(
        (state) => state.modal
    );
    const modalConfig: Message = modalState; // TS could not infer the correct type

    return (
        <Router>
            {/*
        A <Switch> looks through all its children <Route>
        elements and renders the first one whose path
        matches the current URL. Use a <Switch> any time
        you have multiple routes, but you want only one
        of them to render at a time
      */}
            <Switch>
                <Route exact path="/">
                    <Auth />
                </Route>
                <PrivateRoute isLoggedIn={isLoggedIn} path="/private">
                    <ProtectedPages />
                </PrivateRoute>
            </Switch>
            <MyModal
                isOpen={isModalOpen}
                closeHandler={() => dispatch({ type: MODAL_TOGGLE_OFF })}
                message={modalConfig}
            />
            <Spinner isOpen={isSpinnerOpen} loadingMessage={loadingMessage} />
        </Router>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.background.default,
    },
    sidebar: {
        backgroundColor: theme.palette.secondary.main,
        minWidth: "200px",
    },
    content: {
        backgroundColor: theme.palette.secondary.main,
        margin: "5px 32px 0 32px",
        padding: "48px",
        borderRadius: "20px 20px 0 0",
        minHeight: "100vh",
        width: "calc(100% - 64px)",
        flex: 1,
    },
    browserAlert: {
        backgroundColor: COLOR.GRAY_SOLID,
        padding: "15px 30px",
        width: "100%",
    },
}));

function ProtectedPages() {
    const businessProfileData = useAppSelector(
        (state) => state.user.businessProfileData
    );
    const isPlants = businessProfileData.industryType === "PLANTS";
    const classes = useStyles();
    const { path } = useRouteMatch();
    const currentURL = useLocation();
    const currentEnv = useAppSelector((state) => state.system.currentEnv);
    
    return (
        <Grid container wrap="nowrap" className={classes.root}>
            {/* sidebar on the left*/}
            <Sidebar />

            {/* main content */}
            <Grid item container xs={10} direction="column">
                <ToolBar />
                <Grid item container className={classes.content}>
                    <Switch>
                        <Route exact path={path}>
                            <Home />
                        </Route>
                        <Route exact path={`${path}/plugin`}>
                            <Plugins />
                        </Route>
                        <Route exact path={`${path}/plugin/details`}>
                            <CreatePlugin />
                        </Route>
                        <Route exact path={`${path}/form/upload`}>
                            <UploadForm />
                        </Route>
                        <Route exact path={`${path}/form/upload/ambioxera`}>
                            <UploadAmbioxera />
                        </Route>
                        <Route exact path={`${path}/form/upload/mackas`}>
                            <UploadMackas />
                        </Route>
                        <Route exact path={`${path}/monitor/Users`}>
                            <Users />
                        </Route>
                        <Route exact path={`${path}/monitor/killsheet`}>
                            <KillSheetManage />
                        </Route>

                        <Route
                            exact
                            path={`${path}/monitor/promotion/PromotionCount`}
                        >
                            <PromotionCount />
                        </Route>

                        <Route
                            exact
                            path={`${path}/monitor/business/BusinessAccount`}
                        >
                            <BusinessAccount />
                        </Route>

                        <Route
                            exact
                            path={`${path}/monitor/business/ActiveBusiness`}
                        >
                            <ActiveBusiness />
                        </Route>

                        <Route
                            exact
                            path={`${path}/monitor/add/users/:businessId`}
                        >
                            <BusinessUserManageList />
                        </Route>

                        <Route
                            exact
                            path={`${path}/monitor/business/pluginManage/:businessId`}
                        >
                            <BusinessPluginManage />
                        </Route>

                        <Route
                            exact
                            path={`${path}/monitor/business/BusinessPluginInfo`}
                        >
                            <BusinessPluginInfo />
                        </Route>

                        

                        <Route
                            exact
                            path={`${path}/monitor/add/users/:businessId/add`}
                        >
                            <SingleUserManage type="add" />
                        </Route>

                        <Route
                            exact
                            path={`${path}/monitor/add/users/:businessId/view/:inviteId`}
                        >
                            <SingleUserManage type="view" />
                        </Route>

                        <Route
                            exact
                            path={`${path}/monitor/add/users/:businessId/edit/:inviteId`}
                        >
                            <SingleUserManage type="edit" />
                        </Route>

                        <Route
                            exact
                            path={`${path}/monitor/business/PluginActive`}
                        >
                            <PluginActive />
                        </Route>

                        <Route
                            exact
                            path={`${path}/monitor/angus/AnimalsCount`}
                        >
                            <AnimalsCount />
                        </Route>
                        <Route exact path={`${path}/monitor/angus/AngusToken`}>
                            <AngusToken />
                        </Route>

                        <Route exact path={`${path}/monitor/blockchain/search`}>
                            <BlockchainSearch />
                        </Route>
                        <Route
                            exact
                            path={`${path}/monitor/blockchain/transactions`}
                        >
                            <BlockchainTransactions />
                        </Route>

                        <Route exact path={`${path}/monitor/deleteToken`}>
                            <DeleteTokenManage />
                        </Route>

                        <Route exact path={`${path}/session/csv`}>
                            <CSV />
                        </Route>
                        <Route exact path={`${path}/session/csv/create`}>
                            <CreateSession />
                        </Route>
                        <Route exact path={`${path}/session/csv/view/:sessionId`}>
                            <ViewSession />
                        </Route>
                        <Route exact path={`${path}/session/csv/view/:sessionId/generate`}>
                            <GenerateCSV />
                        </Route>
                        <Route exact path={`${path}/alert`}>
                            <AlertDashboard />
                        </Route>
                        <Route exact path={`${path}/alert/create`}>
                            <CreateAlert />
                        </Route>
                        <Route exact path={`${path}/alert/view/:uuid`}>
                            <AlertInfo />
                        </Route>
                        <Route exact path={`${path}/alert/edit/:uuid`}>
                            <CreateAlert />
                        </Route>
                        {/* QA */}
                        {(["uat", "dev","dev2"].includes(currentEnv)) && (
                            <>
                                <Route exact path={`${path}/qa/account`}>
                                    <QaDashboard />
                                </Route>
                                <Route exact path={`${path}/qa/account/create`}>
                                    <CreateAccount />
                                </Route>
                                <Route
                                    exact
                                    path={`${path}/qa/account/view/:id`}
                                >
                                    <AccountInfo />
                                </Route>
                            </>
                        )}
                        
                        <Redirect to="/" />
                    </Switch>
                </Grid>
            </Grid>
        </Grid>
    );
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, isLoggedIn, ...rest }) {
    const history = useHistory();
    const dispatch = useAppDispatch();

    // React.useEffect(() => {
    //   dispatch(initWalletConnect(history)).catch((e) => {
    //     const error = e as WebErrorType;
    //     dispatch(
    //       toggleModal({
    //         status: "failed",
    //         title: error.title,
    //         subtitle: error.message,
    //         button: "Logout",
    //         CTAHandler: () => dispatch(appThunkLogout()),
    //       })
    //     );
    //   });
    // }, []);

    return (
        <Route
            {...rest}
            render={() => (isLoggedIn ? children : <Redirect to="/" />)}
        />
    );
}
