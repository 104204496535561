export const flatten = (obj: Record<string, any>): Record<string, any> => {
  let result: Record<string, any> = {};
  for (const i in obj) {
    if (typeof obj[i] === 'object'  && obj[i] != null && !Array.isArray(obj[i])) {
      const inner = flatten(obj[i]);
      for (const j in inner) {
        result[`${i}${j.replace(/^./, (str) => str.toUpperCase())}`] = inner[j];
      }
    } else if (Array.isArray(obj[i])) {
      result[i] = obj[i];
    }
    else {
      result[i] = obj[i];
    }
  }
  return result;
};

export const camelToSentence = (str: string): string => {
  return (
    str
      // myID -> my ID
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      // NLISId -> NLIS Id
      .replace(/\b([A-Z]+)([A-Z])([a-z])/g, '$1 $2$3')
      // uppercase first character
      .replace(/^./, (str) => str.toUpperCase())
  );
}