import React, { useReducer, useState, useMemo } from "react";
import {
    Grid,
    Typography,
    makeStyles,
    TextField,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import COLOR from "../../../styled/colors";
import MyButton from "../../../presentation/button";
import { PageHeader } from "../../../presentation/withHeader";
import { useAppDispatch } from "../../../utils/hooks";
import { toggleModal } from "../../../store/modal/actions";
import { deleteTokenV2, changeTokenStatus } from "../../../store/monitor/actions";
import CSV from "papaparse";

const useStyle = makeStyles((theme) => ({
    button: {
        color: COLOR.GREEN_BUTTON,
        borderColor: COLOR.GREEN_BUTTON,
        marginRight: 50,
    },
    uploadField: {
        marginTop: 10,
        marginBottom: 10,
    },
    uploadButton: {
        height: "2.7em",
    },
    fileName: {
        height: 48,
        width: "100%",
        maxWidth: window.innerWidth / 2,
    },
    textBox: {
        marginTop: 10,
        marginBottom: 20,
    },
    fileNameLabel: {
        display: "flex",
        flexDirection: "row",
        gap: "0.2em",
        border: `1px solid ${COLOR.GRAY_BORDER}`,
        borderRadius: "0.2em",
        padding: "0.45em",
        cursor: "pointer",
        userSelect: "none",
        "&:hover": {
            border: `1px solid ${COLOR.GREEN}`,
        },
    },
    currentFile: {
        height: "2.7em",
        display: "flex",
        flexDirection: "row",
        padding: "0.5em",
        alignItems: "center",
        border: `1px solid ${COLOR.GRAY_BORDER}`,
        borderRadius: "0.2em",
    },
    formControl: {
        width: "100%",
    },
    cancelIcon: {
        color: COLOR.GRAY_ICON,
        "&:hover": {
            color: COLOR.GREEN,
        },
    },
    subTitle: {
        marginTop: theme.spacing(3),
        fontWeight: 600, // TODO
    },
    selectDropdown: {
        marginTop: 15,
        width: "100%",
    },
    downloadLabel: {
        fontSize: "0.9rem",
    },
    statusLabel: {
        fontSize: "0.9rem",
    },
    downloadItem: {
        cursor: "default",
    },
    downloadLink: {
        textDecoration: "underline",
        width: "100%",
        height: "100%",
        cursor: "pointer",
    },
    select: {
        borderColor: COLOR.GRAY_BORDER,
    },
    radioButton: {
        marginRight: 20,
    },
}));

type rows = Array<{ rfid?: string }>;

const initialState = {
    selectedBusinessType: "",
    rfid: "",
    selectstatus: "",
    warakirristatus: "",
    rfidOption: "Main Status",
    rows: [],
    fileName: "",
};

type Action =
    | { type: "change/rfid"; rfid: string }
    | { type: "change/selectstatus"; selectstatus: string }
    | { type: "change/warakirristatus"; warakirristatus: string }
    | { type: "change/rfidOption"; rfidOption: string }
    | { type: "change/rows"; rows: rows }
    | { type: "change/fileName"; fileName: string };

const reducer = (
    prevState: typeof initialState,
    action: Action
): typeof initialState => {
    const { type, ...actionData } = action;
    switch (action.type) {
        default:
            return { ...prevState, ...actionData };
    }
};

const Assets: React.FC<{}> = () => {
    const classes = useStyle();
    const dispatch = useAppDispatch();
    const [state, localDispatch] = useReducer(reducer, initialState);
    const csvFileDialogRef = React.useRef<HTMLInputElement>(null);

    const handleChange = (value: string) => {
        localDispatch({
            type: "change/rfid",
            rfid: value,
        });
    };

    const onRadioChange = (value: string) => {
        localDispatch({
            type: "change/rfidOption",
            rfidOption: value,
        });
    };

    const onStatusChange = (value: string) => {
        localDispatch({
            type: "change/selectstatus",
            selectstatus: value,
        });
    };

    const onWarakirriChange = (value: string) => {
        localDispatch({
            type: "change/warakirristatus",
            warakirristatus: value,
        });
    };

    const uploadCsvFileHandler = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.files[0].size > 10485760) {
                dispatch(
                    toggleModal({
                        status: "failed",
                        title: "Upload Failed",
                        subtitle: "Upload image size more than 10mb",
                    })
                );
            } else {
                const inputFile = e.target.files[0];
                if (!inputFile) {
                    return;
                }
                const fileName = inputFile.name;

                const reader = new FileReader();
                reader.onload = async ({ target }) => {
                    let csvData = target.result;
                    if (typeof csvData === "string") {
                        csvData = (csvData as string).trim();
                    }
                    const csv = CSV.parse(csvData, {
                        header: true,
                        delimiter: "\n",
                    });
                    if (
                        csv.meta.fields.length != 1 ||
                        csv.meta.fields[0] != "rfid"
                    ) {
                        dispatch(
                            toggleModal({
                                status: "failed",
                                title: "Invalid CSV",
                                subtitle: 'Ensure header is "rfid"',
                            })
                        );
                        handleRemoveFile();
                        return;
                    }
                    console.log(csv.data);
                    localDispatch({
                        type: "change/rows",
                        rows: csv.data,
                    });
                };
                const blob = inputFile.slice(0, inputFile.size, "file");
                reader.readAsText(blob);
                csvFileDialogRef.current.value = null;
                localDispatch({
                    type: "change/fileName",
                    fileName: fileName,
                });
            }
        }
    };

    const handleUploadClick = () => {
        csvFileDialogRef.current?.click();
    };

    const handleRemoveFile = () => {
        localDispatch({
            type: "change/fileName",
            fileName: "",
        });
        localDispatch({
            type: "change/rows",
            rows: [],
        });
    };

    return (
        <PageHeader
            config={{
                title: "",
                margin: 1,
            }}
        >
            <Grid container>
                <Typography
                    variant="h6"
                    className={classes.subTitle}
                    role="label"
                >
                    {"RFID"}
                </Typography>

                <Grid container direction="row" className={classes.textBox}>
                    <Grid item xs={9}>
                        <TextField
                            style={{ width: "50%" }}
                            variant="outlined"
                            value={state.rfid}
                            onChange={(e) => handleChange(e.target.value)}
                        />
                    </Grid>
                </Grid>

                <Grid container direction="row">
                    <Typography
                        variant="caption"
                        className={classes.radioButton}
                        role="label"
                    >
                        <input
                            type="radio"
                            value="Main Status"
                            name="rfidOption"
                            id="mainstatus"
                            checked={state.rfidOption === "Main Status"}
                            onChange={(e) => onRadioChange(e.target.value)}
                        />
                        Modify Main Status
                    </Typography>
                    <Typography
                        variant="caption"
                        className={classes.radioButton}
                        role="label"
                    >
                        <input
                            type="radio"
                            value="Warakirri Status"
                            name="rfidOption"
                            id="warakirristatus"
                            checked={state.rfidOption === "Warakirri Status"}
                            onChange={(e) => onRadioChange(e.target.value)}
                        />
                        Modify Warakirri Status
                    </Typography>
                    <Typography
                        variant="caption"
                        className={classes.radioButton}
                        role="label"
                    >
                        <input
                            type="radio"
                            value="Delete Asset"
                            name="rfidOption"
                            id="deleteasset"
                            checked={state.rfidOption === "Delete Asset"}
                            onChange={(e) => onRadioChange(e.target.value)}
                        />
                        Delete Asset
                    </Typography>
                </Grid>

                {state.rfidOption == "Main Status" && (
                    <Grid>
                        <Grid className={classes.uploadField} spacing={3}>
                            <FormControl
                                className={classes.selectDropdown}
                                size="medium"
                                variant="filled"
                            >
                                <InputLabel
                                    id="select-status-label"
                                    className={classes.statusLabel}
                                >
                                    Select Status
                                </InputLabel>

                                <Select
                                    labelId="select-status-label"
                                    id="select-status"
                                    className={classes.select}
                                    value={state.selectstatus}
                                    onChange={(e) =>
                                        onStatusChange(e.target.value as string)
                                    }
                                >
                                    <MenuItem value={"deleted"}>
                                        Delete
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <MyButton
                            text={"Apply"}
                            disabled={!state.rfid || !state.selectstatus}
                            variant="contained"
                            onClick={() => {
                                dispatch(
                                    changeTokenStatus(
                                        "asset",
                                        [{ rfid: state.rfid }],
                                        "rfid",
                                        state.selectstatus,
                                        () => {
                                            localDispatch({
                                                type: "change/rfid",
                                                rfid: "",
                                            });
                                            localDispatch({
                                                type: "change/selectstatus",
                                                selectstatus: "",
                                            });
                                        }
                                    )
                                    
                                );
                            }}
                        />
                    </Grid>
                )}

                {state.rfidOption == "Warakirri Status" && (
                    <Grid>
                        <Grid
                            container
                            direction="row"
                            className={classes.textBox}
                        >
                            <Grid item xs={9}>
                                <TextField
                                    style={{ width: "100%" }}
                                    variant="outlined"
                                    value={state.warakirristatus}
                                    onChange={(e) =>
                                        onWarakirriChange(e.target.value)
                                    }
                                />
                            </Grid>
                        </Grid>
                        <MyButton
                            text={"Apply"}
                            disabled={!state.rfid || !state.warakirristatus}
                            variant="contained"
                            onClick={() => {
                                dispatch(
                                    changeTokenStatus(
                                        "asset",
                                        [{ rfid: state.rfid }],
                                        "rfid",
                                        state.warakirristatus,
                                        () => {
                                            localDispatch({
                                                type: "change/rfid",
                                                rfid: "",
                                            });
                                            localDispatch({
                                                type: "change/warakirristatus",
                                                warakirristatus: "",
                                            });
                                        }
                                    )
                                );
                            }}
                        />
                    </Grid>
                )}

                {state.rfidOption == "Delete Asset" && (
                    <Grid className={classes.uploadField}>
                        <MyButton
                            text={"Delete"}
                            disabled={!state.rfid}
                            variant="contained"
                            onClick={() => {
                                dispatch(
                                    deleteTokenV2(
                                        "asset",
                                        [{ rfid: state.rfid }],
                                        "rfid",
                                        () => {
                                            localDispatch({
                                                type: "change/rfid",
                                                rfid: "",
                                            });
                                        }
                                        
                                    )
                                );
                            }}
                        />
                    </Grid>
                )}
            </Grid>

            <Grid container>
                <Typography
                    variant="h6"
                    className={classes.subTitle}
                    role="label"
                >
                    {"RFID CSV File"}
                </Typography>

                <Grid
                    container
                    direction="row"
                    className={classes.uploadField}
                    spacing={3}
                >
                    <Grid item className={classes.fileName} xs={4}>
                        <Box className={classes.currentFile}>
                            {state.fileName ? (
                                <Box className={classes.fileNameLabel}>
                                    <Typography>{state.fileName}</Typography>
                                    <Box
                                        className={classes.cancelIcon}
                                        onClick={handleRemoveFile}
                                    >
                                        <CloseIcon />
                                    </Box>
                                </Box>
                            ) : null}
                        </Box>
                    </Grid>
                    <Grid container item xs={2}>
                        <MyButton
                            text={"Upload File"}
                            variant="outlined"
                            onClick={handleUploadClick}
                            buttonClass={classes.uploadButton}
                        />
                        <input
                            type="file"
                            accept=".csv"
                            data-cy="uploadFile"
                            onChange={uploadCsvFileHandler}
                            ref={csvFileDialogRef}
                            style={{ height: 0, width: 0 }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <MyButton
                text={"Delete CSV RFID"}
                disabled={!state.fileName}
                variant="contained"
                onClick={() => {
                    console.log(state.rows);
                    dispatch(
                        deleteTokenV2(
                            "asset",
                            state.rows,
                            "rfid",
                            () => {
                                localDispatch({
                                    type: "change/rfid",
                                    rfid: "",
                                });
                            }
                            
                        )
                    );
                    handleRemoveFile();
                }}
            />
        </PageHeader>
    );
};

export default Assets;
