import {
  recordSiresSchema,
  aiJoiningSchema,
  sireGroupsSchema,
  recordAnimalsSchema,
} from './schema';

const WEB_LOGIN_PREFIX_ = 'WEB_LOGIN_PREFIX_';

export enum updateByCSV {
  updateWeight = 'updateWeight',
  updatePregnancyStatus = 'updatePreganacyStatus',
  updateDeceaseStatus = 'updateDeceaseStatus',
  updateAngusSireGroup = 'updateAngusSireGroup',
  angusAIJoining = 'angusAIJoining',
}




const ASSETTYPE = {
  USER: 'user',
  BUSINESS: 'business',
};

const OPERATIONTYPE = {
  TOKEN: 'TOKEN',
  ACTIVITY: 'ACTIVITY',
};

const WALLETCONNECT = {
  BRIDGE: 'https://walletconnect.aglive.com',
  SESSION_UPDATE: 'session_update',
  CONNECT: 'connect',
  DISCONNECT: 'disconnect',
  WALLET_CONNECT: 'walletconnect',
  CALL_REQUEST: 'call_request',
};

const CODE_PREFIX = 'https://scan.aglive.com/s/';

const DEVICE_ID = 'device_id';
const GOOGLE_CLIENT_ID='599680892165-fijbn5sk2gjr2nb4857nihat4k9mngh6.apps.googleusercontent.com' // old google client

const PHONE_COUNTRY_CODES = ['AU', 'CA', 'AR', 'US'];

const EN_LABELS = {
  //Invit User Label
  readOnly: 'Read Only',
  search: 'Search',
  createNew: 'Create New',
  filters: 'Filters',
  inactive: 'Inactive',
  active: 'Active',
  pending: 'Pending',
  userLibrary: 'User Library',
  name: 'Name',
  phone: 'Cell/Mobile',
  email: 'Email',
  emailAddress: 'Email Address',
  role: 'Role',
  status: 'Status',
  action: 'Action',
  admin: 'Admin',
  user: 'User',
  none: 'none',
  addUser: 'Add User',
  editUser: 'Edit User',
  viewUser: 'View User',
  enterPhoneNumber: 'Enter phone number',
  inputValidEmail: 'Please input a valid email',
  pendingAcceptance: 'Pending for Acceptance',
  inviteAgain: 'Invite Again',
  invite: 'Invite',
  invited: 'Invited',
  invitedMessage: 'The SMS invitation sent. New user code is',
  save: 'Save',
  mobileWarning: 'Please input a valid mobile number',
  //User Profile
  signature: 'Signature',
  edit: 'Edit',
  viewUserProfile: 'View User Profile',
  editUserProfile: 'Edit User Profile',
  requiredField: 'This field is required',
  validNumber: 'Please enter a valid mobile number',
  validEmail: 'Please enter your email address in format: yourname@example.com',
  enterNumber: 'Enter number',
  fullName: 'Full Name',
  //Business Profile
  delete: 'Delete',
  deleted: 'Deleted',
  cancel: 'Cancel',
  addLocation: 'Add Location',
  addEstablishment: 'Add Establishment',
  addMoreEstablishment: 'Add More Establishments', 
  addEstablishmentUsers: 'Add Establishment Users',
  updated: 'Updated',
  close: 'Close',
  pleaseSelect: 'Please Select',
  cannptUndone: 'This action cannot be undone',
  companyName: 'Company/Business Name',
  companyNumber: 'Company/Business Number',
  licenseNumber: 'License Number',
  licenseExpiryDate: 'License Expiry Date',
  permits: 'Permits',
  permit: 'Permit',
  permitNumber: 'Permit Number',
  permitExpiryDate: 'Permit Expiry Date',
  addPermit: 'Add Permit',
  myLocation: 'My Location',
  myEstablishment: 'My Establishments',
  locationId: 'Location ID',
  locationNickname: 'Location Nickname',
  establishmentId: 'Establishment ID',
  establishmentNickname: 'Establishment Nickname',
  establishmentUsers: 'Establishment Users',
  duplicatePIC: 'This PIC already exists',
  country: 'Country',
  state: 'State',
  provinceTerritory: 'Province/Territory',
  suburb: 'Town or Suburb',
  cityTownSuburb: 'City/Town/Suburb',
  postcode: 'Postcode',
  zipcode: 'Zip Code',
  address: 'Address',
  lpaUser: 'LPA Username',
  lpaPassword: 'LPA Password',
  nlisUser: 'NLIS Username',
  nlisPassword: 'NLIS Password',
  CattleIdentification: 'Cattle Identification',
  brandDescription: 'Brand/Eartag Description',
  cattleBrandSymbol: 'Cattle Brand Symbol',
  editBusinessProfile: 'Edit Business Profile',
  viewBusinessProfile: ' View Business Profile',
  CUIG: 'CUIG',
  validCUIG: 'Please enter CUIG in the format as per example provided',
  validCUIT: 'Please enter CUIT in the format as per example provided',
  validRenspa: 'Please enter Renspa in the format as per example provided',
  license: 'License',
  startDate: 'Start Date',
  expiryDate: 'Expiry Date',
  certificateNumber: 'Certificate Number',
  odcLicense: 'ODC License',
  odcLicenseNumber: 'ODC License Number',
  odcLicenseStartDate: 'ODC License Start Date',
  odcLicenseExpiryDate: 'ODC License Expiry Date',
  odcPermits: 'ODC Permits',
  addOdcPermit: 'Add ODC Permit',
  addOdcLicenseAndPermit: 'Add ODC License and Permit',
  plantsLimit: '# Plants Limit',
  gmpLicense: 'GMP License',
  gmpLicenseNumber: 'GMP License Number',
  gmpLicenseStartDate: 'GMP License Start Date',
  gmpLicenseExpiryDate: 'GMP License Expiry Date',
  gmpCertificates: 'GMP Certificates',
  gmpCertificateNumber: 'GMP Certificates',
  addGmpCertificate: 'Add GMP Certificate',
  addGmpLicenseAndCertificates: 'Add GMP License and Certificates',
  poisonPermit: 'Poison Permit',
  poisonPermitNumber: 'Poison Permit Number',
  poisonPermitStartDate: 'Poison Permit Start Date',
  poisonPermitExpiryDate: 'Poison Permit Expiry Date',
  addPoisonPermit: 'Add Poison Permit',
  permitUpdateWarning: 'Any change on this permit will reflect on all linked plant codes.',
  continue: 'Continue',
  update: 'Update',
  yes: 'Yes',
  no: 'No',
  thcValue: 'THC Value',
  plugin: "Plugin"
};

const CONSTANT= {
  WEB_LOGIN_PREFIX_,
  ASSETTYPE,
  OPERATIONTYPE,
  WALLETCONNECT,
  CODE_PREFIX,
  DEVICE_ID,
  GOOGLE_CLIENT_ID,
  PHONE_COUNTRY_CODES,
  EN_LABELS
};

export default CONSTANT