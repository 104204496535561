import React, { useEffect } from "react";
import {
    IconButton,
    makeStyles,
    Box,
    Tooltip,
    Typography,
} from "@material-ui/core";
import { useHistory, useRouteMatch } from "react-router-dom";
import COLOR from "../../styled/colors";

import {
    fetchAccount,
    deleteAccount,
    loginAccount,
} from "../../store/qa/actions";
import { toggleModal, toggleModalOff } from '../../store/modal/actions';


import withHeader from "../../presentation/withHeader";
import MyButton from "../../presentation/button";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../utils/hooks";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import FaceIcon from '@material-ui/icons/Face';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ConfirmButtons from "../../presentation/ConfirmButtons";

const useStyle = makeStyles((theme) => ({
    button: {
        color: COLOR.GREEN_BUTTON,
        borderColor: COLOR.GREEN_BUTTON,
        marginRight: 50,
    },
    tooltip: {
        color: COLOR.WHITE,
        fontSize: theme.typography.fontSize * 0.9
    }
}));

const Dashboard: React.FC = () => {

    const classes = useStyle();
    const history = useHistory();
    const dispatch = useDispatch();
    const { path } = useRouteMatch();
    const accounts = useAppSelector((state) => state.qa.accounts);
    const currentEnv = useAppSelector((state) => state.system.currentEnv);

    const onDelete = (e: any) => {
        const accountId = e.currentTarget.value;
        dispatch(toggleModal({
            status: 'warning',
            title: 'Delete Account',
            subtitle: 'Confirm delete?',
            renderButton: <ConfirmButtons 
                confirmText='Delete'
                onCancel={() => {
                    dispatch(toggleModalOff())
                }}
                onConfirm={() => {
                    dispatch(
                        deleteAccount(accountId, () => {
                            dispatch(fetchAccount());
                        })
                    );
                }}
            />
        }))
    };

    const onDownload = async (e:any) => {
        const accountId = e.currentTarget.value;
        const blob = new Blob([JSON.stringify({accountId: accountId})], { type: "text/json" });
        const link = document.createElement("a");

        link.download = `${e.currentTarget.dataset['name']}.json`;
        link.href = window.URL.createObjectURL(blob);
        link.dataset.downloadurl = ["text/json", link.download, link.href].join(":");

        const evt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: true,
        });

        link.dispatchEvent(evt);
        link.remove()
    }

    const onLogin = async (e: any) => {
        const accountId = e.currentTarget.value;
        const res: any = await dispatch(loginAccount(accountId));
        
        if (currentEnv !== "prod" && currentEnv !== "localhost") {
            window.open(`https://${currentEnv}.web.aglive.com?authorizationCode=${res.authorizationCode}`, "_blank")
        } else {
            dispatch(
                toggleModal({
                    status: 'failed',
                    title: "Unexpected Error",
                    subtitle: "Not yet able to handle Production",
                    button: 'Close'
                }),
            )
        }
    };

    useEffect(() => {
        console.log("fetchAccount");
        dispatch(fetchAccount());
    }, []);

    return (
        <Box>
            <Box>
                <MyButton
                    text={"Add"}
                    variant="outlined"
                    buttonClass={classes.button}
                    onClick={() => {
                        history.push(`${path}/create`);
                    }}
                />
            </Box>
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                {accounts.map((account) => (
                    <ListItem
                        key={account.id}
                        disableGutters
                        sx={{paddingRight: 0}}
                        secondaryAction={
                            <Box>
                                <Tooltip title={<Typography className={classes.tooltip}>Delete</Typography>}
                                    placement="top"
                                    arrow
                                >
                                    <IconButton
                                        aria-label="delete"
                                        onClick={onDelete}
                                        value={account.id}
                                    >
                                        <DeleteForeverIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={<Typography className={classes.tooltip}>Download</Typography>}
                                    placement="top"
                                    arrow
                                >
                                    <IconButton
                                        aria-label="download"
                                        onClick={onDownload}
                                        data-name={account.name}
                                        value={account.id}
                                        color="primary"
                                        style={{
                                            color: COLOR.GREEN_HIGHLIGHT,
                                        }}
                                    >
                                        <ArrowDownwardIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={<Typography className={classes.tooltip}>Login</Typography>}
                                    placement="top"
                                    arrow
                                >
                                    <IconButton
                                        aria-label="login"
                                        onClick={onLogin}
                                        value={account.id}
                                        color="primary"
                                        style={{
                                            color: COLOR.GREEN_HIGHLIGHT,
                                        }}
                                    >
                                        <FaceIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        }
                    >
                        <ListItemButton
                            component="a"
                            href={`/private/qa/account/view/${account.id}`}
                        >
                            <ListItemText primary={account.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

export default withHeader(
    {
        title: "Business List",
        margin: 60,
    },
    Dashboard
);
