import { createTheme } from "@material-ui/core/styles";
import COLOR from "./colors";

export const theme = createTheme({
  palette: {
    primary: {
      light: COLOR.GREEN_LIGHT,
      main: COLOR.GREEN,
      100: COLOR.GREEN_BUTTON,
      200: COLOR.GREENT_TEXT,
      300: COLOR.GREEN_OPTION,
    },
    secondary: {
      main: COLOR.WHITE,
    },
    info: {
      main: COLOR.BLACK_BG,
    },
    error: {
      light: COLOR.RED_WARNING,
      main: COLOR.RED,
    },
    background: {
      default: "linear-gradient(180deg, #1e7b3e 0%, #87d978 99.49%)",
    },
    grey: {
      50: COLOR.GRAY_SOLID,
      100: COLOR.GRAY_BUTTON,
      200: COLOR.GRAY_BORDER,
      300: COLOR.GRAY_ICON,
      500: COLOR.BLACK_BG,
    },
    common: {
      black: COLOR.BLACK,
      white: COLOR.WHITE,
    },
  },
  typography: {
    fontFamily: "Open Sans",
    fontSize: 16,
    h1: {
      fontFamily: "Oswald",
      fontSize: 50,
      fontWeight: 400,
      color: COLOR.BLACK_BG,
    },
    h2: {
      fontFamily: "Oswald",
      fontSize: 35,
      fontWeight: 400,
      color: COLOR.BLACK_BG,
    },
    h3: {
      fontFamily: "Oswald",
      fontSize: 25,
      fontWeight: 400,
      color: COLOR.BLACK_BG,
    },
    h4: {
      fontFamily: "Oswald",
      fontSize: 22,
      fontWeight: 400,
      color: COLOR.BLACK_BG,
    },
    h5: {
      fontFamily: "Oswald",
      fontSize: 18,
      fontWeight: 400,
    },
    h6: {
      fontFamily: "Open Sans",
      fontSize: 16,
      fontWeight: 400,
      color: COLOR.BLACK_BG,
    },
    body1: {
      fontFamily: "Open Sans",
      fontSize: 18,
      color: COLOR.BLACK_BG,
    },
    body2: {
      fontFamily: "Open Sans",
      fontSize: 16,
      color: COLOR.BLACK_BG,
    },
    subtitle1: {
      fontFamily: "Open Sans",
      fontWeight: "bold",
      fontSize: 9,
      color: COLOR.BLACK_BG,
    },
  },
  props: {
    // MuiOutlinedInput: { 
    //   notched: false,
    // },
  }
});
