import { defaultEnv } from './../../env';
import Web3 from 'web3';
import AgliveCore from '../../config/AgliveCore.json';

let web3 = null;
let contract = null;


const init = async () => {
  if (web3 != null || contract != null) {
    return;
  }
  const { blockchainService, blockchainContractAddr } = defaultEnv;
  web3 = new Web3(blockchainService);
  contract = new web3.eth.Contract(
    AgliveCore.abi as any[],
    blockchainContractAddr
  );
  const blockNumber =  await web3.eth.getBlockNumber();
  console.log('eth blocknumber: ', blockNumber);
};

export const web3Service = {
  getAssetByToken(tokenId: string) {
    init();
    return new Promise((resolve, reject) => {
      contract.methods.getAssetById(tokenId).call().then((resolve)).catch(reject);
    });
  },
  getTotalAssets(): Promise<number> {
    init();
    return new Promise((resolve, reject) => {
      contract.methods.getTotalAssets().call().then(resolve).catch(reject);
    });
  },
  getAssetByRange(start: string, end: string) : Promise<Array<any>> {
    init();
    return new Promise((resolve, reject) => {
      contract.methods.getAssetByRange(start, end).call().then(resolve).catch(reject);
    });
  }
};
