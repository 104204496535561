import { AppThunk } from '../types';
import { SPINNER_TOGGLE_ON, SPINNER_TOGGLE_OFF } from '../spinner/types';
import { WebErrorType } from '../../utils/error';
import { callAPI, getAPI } from '../../utils/network';
import CONSTANT from '../../config/constant';
import { toggleModal } from '../modal/actions';
import { GET_TOKEN_DETAIL_LIST, ManageTokenType, UPDATE_TOKEN_LIST } from './types';

export function getAllTokenList(): AppThunk<Promise<void>> {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: SPINNER_TOGGLE_ON });
            dispatch({
                type: UPDATE_TOKEN_LIST,
                payload: [],
            });

            const resp = await callAPI({
                url: getAPI().GET.getAllTokenCountSummary,
                method: 'GET',
            });

            const res_jsonData = resp.data


            let tempData: ManageTokenType[] = []
            if (res_jsonData) {
                for (const item of res_jsonData) {
                    if (item._id) {
                        tempData.push({ type: item._id, totalNumber: item.count })
                    }
                }
            }

            dispatch({
                type: UPDATE_TOKEN_LIST,
                payload: tempData,
            });

        }
        catch (e) {

        }
        finally {
            dispatch({ type: SPINNER_TOGGLE_OFF });
        }

    }


}

export function clearTokenDataList(): AppThunk<Promise<void>> {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: SPINNER_TOGGLE_ON });

            dispatch({
                type: GET_TOKEN_DETAIL_LIST,
                payload: [],
            });

        } catch (error) {

        } finally {
            dispatch({ type: SPINNER_TOGGLE_OFF });
        }
    }
}

export function getTokenDataList(tokenType: string): AppThunk<Promise<void>> {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: SPINNER_TOGGLE_ON });

            dispatch({
                type: GET_TOKEN_DETAIL_LIST,
                payload: [],
            });

            const resp = await callAPI({
                url: getAPI().POST.fetchTokenDetails,
                method: 'POST',
                data: { tokenType: tokenType },
            });

            const res_jsonData = JSON.parse(resp.data)

            dispatch({
                type: GET_TOKEN_DETAIL_LIST,
                payload: res_jsonData,
            });

        }
        catch (e) {

        }
        finally {
            dispatch({ type: SPINNER_TOGGLE_OFF });
        }

    }
}

export function deleteToken(tokenType: string, tokenId: string, successCB?: () => void): AppThunk<Promise<void>> {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: SPINNER_TOGGLE_ON, payload: {
                message: `[Deleting] - ID: ${tokenId}, Type: ${tokenType}`
            } });
            const resp = await callAPI({
                url: getAPI().DELETE.deleteToken,
                method: 'DELETE',
                data: { tokenList: [{ tokenType: tokenType, tokenAgliveId: tokenId }] },
            });
        } catch (error) {
            console.log('error delete token', error)
        } finally {
            dispatch({ type: SPINNER_TOGGLE_OFF });
        }

        successCB()

    }
}

