
export interface ManageTokenType {
    type: string,
    totalNumber: number
}


export interface typeDeleteState {
    readonly tokenList: ManageTokenType[];
    readonly tokenDataDetails: any[];
}

export const UPDATE_TOKEN_LIST = 'UPDATE_TOKEN_LIST'
export const GET_TOKEN_DETAIL_LIST = 'GET_TOKEN_DETAIL_LIST'
