import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "./index.css";
import { store, persistor } from "./store/index";
import Route from "./route";
import * as serviceWorker from "./utils/serviceWorker";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./styled/theme";
import { VERSION } from "./env";
import { initiateAxiosInterceptor } from "./utils/network";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_CLIENT_ID_V2 } from './env';

initiateAxiosInterceptor();

Sentry.init({
  dsn: "https://46d6cd033d934cfd8dcec585422436ea@o458183.ingest.sentry.io/5455271",
  environment: store.getState().system.currentEnv,
  release: "Dev ui" + VERSION,
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const console = ((oldCon) => {
  //custom console only for development
  return {
    ...oldCon,
    log: (...text) => {
      if (process.env.NODE_ENV === "development") {
        oldCon.log(...text);
      }
    },
  };
})(window.console);
window.console = console;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          {/* <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog> */}
          <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID_V2}>
            <Route />
          </GoogleOAuthProvider>
          {/* </Sentry.ErrorBoundary> */}
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
