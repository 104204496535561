import React, { useEffect, useState, useReducer, useRef } from "react";
import { Grid, Typography, Box, makeStyles } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import MyButton from "../../presentation/button";
import COLOR from "../../styled/colors";
import { useAppSelector, useAppDispatch } from "../../utils/hooks";
import { Line } from "../../presentation/Line";
import {
    fileUploader,
    createPlugin,
    editPlugin,
} from "../../store/plugin/actions";
import { toggleModal } from '../../store/modal/actions';
import { PluginModel } from "@aglive/data-model";
import { PageHeader } from "../../presentation/withHeader";
import { PluginRefObj, PluginForm } from "@aglive/web-core";
import CloseIcon from "@material-ui/icons/Close";

type stateType = {
    uuid?: string;
};
const initialState = {
    pluginUuid: "",
    image: "",
    imageName: "Please upload image",
    jsonFile: "" as string,
    jsonFileName: "Please upload file",
    jsonObject: {} as PluginModel.Plugin,
    credentialsState: {},
    isEdit: false,
};

type Action =
    | { type: "upload/image"; image: string; imageName: string }
    | {
          type: "upload/file";
          jsonFile: string;
          jsonFileName: string;
          jsonObject: PluginModel.Plugin;
      }
    | {
          type: "set/plugin";
          pluginUuid: string;
          jsonObject: PluginModel.Plugin;
          isEdit: boolean;
      }
    | { type: "set/isEdit"; isEdit: boolean }
    | {
          type: "update/credentialsState";
          credentialsState: { [key: string]: string };
      }
    | { type: "update/jsonObject"; jsonObject: PluginModel.Plugin };

const reducer = (
    prevState: typeof initialState,
    action: Action
): typeof initialState => {
    const { type, ...actionData } = action;
    switch (action.type) {
        case "upload/image":
        case "upload/file":
        case "set/plugin":
        case "set/isEdit":
        case "update/credentialsState":
        case "update/jsonObject":
            return { ...prevState, ...actionData };
    }
};

const useStyle = makeStyles((theme) => ({
    button: {
        color: COLOR.GREEN_BUTTON,
        borderColor: COLOR.GREEN_BUTTON,
        marginRight: 50,
    },
    MainInformation: {
        marginTop: 30,
        marginBottom: 30,
        flexDirection: "column",
    },
    uploadField: {
        marginTop: 30,
        marginBottom: 30,
    },
    uploadButton: {
        marginTop: -10,
    },
    fileName: {
        height: 48,
        width: "100%",
        maxWidth: window.innerWidth / 2,
        borderColor: COLOR.GRAY_BORDER,
        border: "1px solid",
        padding: theme.spacing(1),
        borderRadius: 4,
    },
    subTitle: {
        marginTop: theme.spacing(3),
        fontWeight: 600, // TODO
    },
    PluginForm: {
        marginTop: 50,
        marginBottom: 150,
    },
    PluginTitle: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
    },
}));
const CreatePlugin: React.FC<{}> = () => {
    const classes = useStyle();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const pluginArray = useAppSelector((state) => state.plugin.plugins);
    const routeState: stateType = useLocation().state;
    const [state, localDispatch] = useReducer(reducer, initialState);
    const displayImageUpload = React.useRef<HTMLInputElement>(null);
    const displayJsonFileUpload = React.useRef<HTMLInputElement>(null);
    const imageHandleClick = () => {
        displayImageUpload.current?.click();
    };
    const jsonHandleClick = () => {
        displayJsonFileUpload.current?.click();
    };
    const pluginFormRef = useRef<PluginRefObj>(null);
    const uploadImageHandler = async (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.files[0].size > 10485760) {
                dispatch(
                    toggleModal({
                        status: 'failed',
                        title: "Internal Error",
                        subtitle: "Upload image size more than 10mb ！",
                        button: 'Close'
                    }),
                )
            } else {
                // setImageName(e.target.files[0].name)
                const response = await fileUploader(
                    e.target.files[0],
                    "plugin"
                );
                const responseData = response[0].data;
                if (typeof responseData == "string") {
                    // setImage(responseData);
                    localDispatch({
                        type: "upload/image",
                        image: responseData,
                        imageName: e.target.files[0].name,
                    });
                }
            }
        }
    };
    const uploadJsonFileHandler = async (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.files[0].size > 10485760) {
                dispatch(
                    toggleModal({
                        status: 'failed',
                        title: "Internal Error",
                        subtitle: "Upload image size more than 10mb ！",
                        button: 'Close'
                    }),
                )
            } else {
                const fileName = e.target.files[0].name;
                // setJsonFileName(e.target.files[0].name)
                const fileReader = new FileReader();
                fileReader.readAsText(e.target.files[0], "UTF-8");
                fileReader.onload = (e) => {
                    // setJsonFile(e.target.result)
                    const fileObject = JSON.parse(String(e.target.result));
                    fileObject["imageUrl"] = state.image;
                    // setJsonObject(fileObject)
                    localDispatch({
                        type: "upload/file",
                        jsonFile: String(e.target.result),
                        jsonFileName: fileName,
                        jsonObject: fileObject,
                    });
                };
            }
        }
    };
    const createPluginSubmit = () => {
        const updateObject = {
            ...state.jsonObject,
            imageUrl: state.image,
        } as PluginModel.Plugin;
        dispatch(
            createPlugin(updateObject, () => {
                history.goBack();
            })
        );
    };
    const updatePluginSubmit = () => {
        dispatch(
            editPlugin(
                state.pluginUuid,
                state.image,
                state.jsonObject as PluginModel.Plugin,
                state.imageName !== "Please upload image",
                state.jsonFileName !== "Please upload file",
                () => {
                    history.goBack();
                }
            )
        );
    };
    const submitDisable = () => {
        if (state.isEdit) {
            return (
                state.image === "" &&
                state.jsonFileName === "Please upload file"
            );
        } else {
            return (
                state.image === "" ||
                state.jsonFileName === "Please upload file"
            );
        }
    };
    useEffect(() => {
        /**edit plugin fetch json */
        if (routeState?.uuid) {
            localDispatch({
                type: "set/plugin",
                pluginUuid: routeState.uuid,
                jsonObject: pluginArray.find(
                    (item) => item.uuid === routeState.uuid
                ),
                isEdit: true,
            });
        }
    }, []);
    useEffect(() => {
        /**get credential */
        let credentialsState = {};
        state.jsonObject?.credentialForm?.sections.forEach((section) => {
            const inputFields = section.fields.filter(
                (field) => field.type === "text" || field.type === "schedule"
            );
            inputFields.forEach((field) => {
                credentialsState[field["fieldName"]] = "";
            });
        });
        localDispatch({
            type: "update/credentialsState",
            credentialsState: credentialsState,
        });
    }, [state.jsonObject]);
    useEffect(() => {
        /**update image */
        if (state.image && Object.keys(state.jsonObject).length !== 0) {
            localDispatch({
                type: "update/jsonObject",
                jsonObject: { ...state.jsonObject, imageUrl: state.image },
            });
        }
    }, [state.image]);

    const CreatePluginContent = (
        <Grid container>
            <Grid
                item
                container
                justifyContent="flex-start"
                className={classes.MainInformation}
            >
                <Typography
                    variant="h2"
                    role="label"
                    style={{ fontWeight: 400 }}
                >
                    {"Main Information"}
                </Typography>
            </Grid>
            <Typography variant="h6" className={classes.subTitle} role="label">
                {"Image File"}
            </Typography>
            <Grid
                container
                direction="row"
                className={classes.uploadField}
                spacing={3}
            >
                <Grid container item className={classes.fileName} xs={9}>
                    <Typography noWrap={true}>{state.imageName}</Typography>
                </Grid>
                <Grid container item xs={3}>
                    <MyButton
                        text={"Upload Image"}
                        variant="outlined"
                        onClick={imageHandleClick}
                        buttonClass={classes.uploadButton}
                    />
                    <input
                        type="file"
                        accept="image/*"
                        data-cy="uploadImage"
                        onChange={uploadImageHandler}
                        ref={displayImageUpload}
                        style={{ height: 0, width: 0 }}
                    />
                </Grid>
            </Grid>
            <Typography variant="h6" className={classes.subTitle} role="label">
                {"Json File"}
            </Typography>
            <Grid
                container
                direction="row"
                className={classes.uploadField}
                spacing={3}
            >
                <Grid item className={classes.fileName} xs={9}>
                    <Typography noWrap={true}>{state.jsonFileName}</Typography>
                </Grid>
                <Grid container item xs={3}>
                    <MyButton
                        text={"Upload File"}
                        variant="outlined"
                        onClick={jsonHandleClick}
                        buttonClass={classes.uploadButton}
                    />
                    <input
                        type="file"
                        accept="file/*"
                        data-cy="uploadFile"
                        onChange={uploadJsonFileHandler}
                        ref={displayJsonFileUpload}
                        style={{ height: 0, width: 0 }}
                    />
                </Grid>
            </Grid>
            <Grid item container className={classes.MainInformation}>
                <Typography
                    variant="h2"
                    role="label"
                    style={{ fontWeight: 400 }}
                >
                    {"Preview"}
                </Typography>
                <Grid container className={classes.PluginForm}>
                    {Object.keys(state.jsonObject).length !== 0 && (
                        <Box
                            component="span"
                            sx={{ border: "1px solid grey", width: 1000 }}
                        >
                            <Grid style={{ margin: 30 }}>
                                <Grid container className={classes.PluginTitle}>
                                    <Typography variant="h3">
                                        {"Activate Plugin"}
                                    </Typography>
                                    <CloseIcon />
                                </Grid>
                                <Line />

                                <PluginForm
                                    ref={pluginFormRef}
                                    jsonForm={state.jsonObject}
                                    credentialsState={state.credentialsState}
                                />
                            </Grid>
                        </Box>
                    )}
                </Grid>
            </Grid>
            <Grid item container direction="row" justify="flex-end">
                <MyButton
                    text={"Back"}
                    variant="outlined"
                    buttonClass={classes.button}
                    onClick={() => history.goBack()}
                />
                <MyButton
                    text={"Sumbit"}
                    disabled={submitDisable()}
                    variant="contained"
                    onClick={() => {
                        state.isEdit
                            ? updatePluginSubmit()
                            : createPluginSubmit();
                    }}
                />
            </Grid>
        </Grid>
    );
    return (
        <PageHeader
            config={{
                title: state.isEdit ? "Edit Plugin" : "New Plugin",
                margin: 60,
                back: true,
            }}
        >
            {CreatePluginContent}
        </PageHeader>
    );
};
export default CreatePlugin;
