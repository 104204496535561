export interface spinnerState {
  readonly isOpen: boolean;
  readonly loadingMessage?: string;
}

export const SPINNER_TOGGLE_ON = 'SPINNER_TOGGLE_ON';
export const SPINNER_TOGGLE_OFF = 'SPINNER_TOGGLE_OFF';

interface ShowSpinnerAction {
  type: typeof SPINNER_TOGGLE_ON;
  payload?: {
    message?: string;
  };
}

interface HideSpinnerAction {
  type: typeof SPINNER_TOGGLE_OFF;
}

export type SpinnerAction = ShowSpinnerAction | HideSpinnerAction;