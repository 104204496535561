import { spinnerState, SPINNER_TOGGLE_ON, SPINNER_TOGGLE_OFF, SpinnerAction } from "./types";

const initialState: spinnerState = {
  isOpen: false,
  loadingMessage: undefined
}

export default function spinnerReducer(
  state = initialState,
  action: SpinnerAction
) {
  switch(action.type) {
    case SPINNER_TOGGLE_ON:
      return {isOpen: true, loadingMessage: action.payload?.message};
    case SPINNER_TOGGLE_OFF:
      return {isOpen: false};
    default:
      return state;
  }
}