import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { callAPI, getAPI } from '../network';
import { TokenModel } from '@aglive/data-model';

import { WebErrorType } from '../error';

export type BusinessItem = {
  businessId: string;
  companyName: string;
  plugins: TokenModel.Business['plugins'];
  industryType: TokenModel.Business['details']['industryType'];
  companyNumber: TokenModel.Business['details']['companyNumber'];
};

type UseBusinessListProps = {
  includeItem?: (item: BusinessItem) => boolean;
};

type BusinessData = {
  list: Array<BusinessItem>;
  isLoading: boolean;
  error:
    | {
        title: string;
        message: string;
      }
    | undefined;
  selectedItem: BusinessItem | undefined;
};

const INITIAL_BUSINESS_DATA: BusinessData = {
  list: [],
  isLoading: false,
  error: undefined,
  selectedItem: undefined,
};

export const useBusinessList = ({
  includeItem = () => true,
}: UseBusinessListProps = {}) => {
  const [businessData, setBusinessData] = useReducer(
    (current: BusinessData, update: Partial<BusinessData>) => ({
      ...current,
      ...update,
    }),
    INITIAL_BUSINESS_DATA
  );

  const filteredList = useMemo(() => {
    return businessData.list.filter(includeItem);
  }, [includeItem, businessData.list]);

  const handleSearchItem = useCallback((businessName: string = '') => {
    const fetchData = async () => {
      try {
        setBusinessData({
          isLoading: true,
        });
        const result = await getBusinesses(businessName);
        setBusinessData({
          list: result,
        });
      } catch (e) {
        const error = e as WebErrorType;
        setBusinessData({
          error: {
            title: error.title,
            message: error.message,
          },
        });
      } finally {
        setBusinessData({
          isLoading: false,
        });
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    handleSearchItem();
  }, [handleSearchItem]);

  const getBusinessId = useCallback(
    (businessName: string) => {
      return (
        filteredList.find((b) => b.companyName === businessName)?.businessId ??
        ''
      );
    },
    [filteredList]
  );

  const handleSelectItem = useCallback(
    (businessId: string) => {
      const result = filteredList.find((b) => b.businessId === businessId);
      setBusinessData({ selectedItem: result });
    },
    [filteredList]
  );

  return {
    ...businessData,
    list: filteredList,
    handleSelectItem,
    handleSearchItem,
    getBusinessId,
  };
};

async function getBusinesses(businessName = '') {
  const response = await callAPI({
    url: getAPI().POST.getActiveBusiness,
    method: 'POST',
    data: { name: businessName, dateFrom: null, dateTo: null },
  });
  const result: Array<BusinessItem> =
    response?.businesses?.map((item: TokenModel.Business) => ({
      companyName: item.details.companyName,
      plugins: item.plugins,
      businessId: item.externalIds[0].agliveToken,
      industryType: item.details.industryType,
      companyNumber: item.details.companyNumber,
    })) ?? [];
  return result;
}
