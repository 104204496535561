import axios, { AxiosRequestConfig } from 'axios';
import { NetworkError } from './error';
import {getAPIList} from '../config/api';
import { GlobalAccessToken } from '@aglive/frontend-core';
import { refresh} from './auth';
import {store} from '../store/index';

export async function callAPI(config: AxiosRequestConfig, apiKey?: string | undefined) {
  try {
    config.headers = {
      ...config.headers,
      'Content-Type': 'application/json',
    };

    const env = store.getState().system.environment;
    if (config.url.includes(env.webhookService)) {
      Object.assign(config, { headers: { Authorization: `Bearer ${apiKey}` } });
    }
    if (authUrls().filter(url => url !== getAPI().POST.createUserWithAuth).includes(config.url)) {
      Object.assign(config, { withCredentials: true });
    }
    
    const response = await axios.request(config);

    return response.data;
  } catch (e) {
    if (e.response?.status == 401 && e.response.data) {
      e.response.data.title = "Session expired";
      e.response.data.details = "Please login again";
    }
    console.error('callAPI -> error', e);
    throw new NetworkError(e);
  }
}
function base64ToBlob(base64, mimetype, slicesize = 512) {
  if (!window.atob || !window.Uint8Array) {
      // The current browser doesn't have the atob function. Cannot continue
      return null;
  }
  mimetype = mimetype || '';
  slicesize = slicesize || 512;
  var bytechars = atob(base64);
  var bytearrays = [];
  for (var offset = 0; offset < bytechars.length; offset += slicesize) {
      var slice = bytechars.slice(offset, offset + slicesize);
      var bytenums = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
          bytenums[i] = slice.charCodeAt(i);
      }
      var bytearray = new Uint8Array(bytenums);
      bytearrays[bytearrays.length] = bytearray;
  }
  return new Blob(bytearrays, {type: mimetype});
};
export async function downloadFile(config: AxiosRequestConfig, apiKey?: string | undefined) {
  try {
    config.headers = {
      ...config.headers,
      'Content-Type': 'application/json',
    };

    const env = store.getState().system.environment;
    if (config.url.includes(env.webhookService)) {
      Object.assign(config, { headers: { Authorization: `Bearer ${apiKey}` } });
    }
    if (authUrls().filter(url => url !== getAPI().POST.createUserWithAuth).includes(config.url)) {
      Object.assign(config, { withCredentials: true });
    }
    
    const response = await axios.request({...config, responseType: 'arraybuffer'});
    const type = response.headers['Content-Type']
    const url = window.URL.createObjectURL(new Blob([response.data], { type: type })) 
    const link = document.createElement('a')
    link.href = url
    const [, filename] = response.headers['content-disposition'].split('filename=');
    link.download = filename.replaceAll('"', '')
    document.body.appendChild(link)
    link.click()
    link.remove()

    return response.data;
  } catch (e) {
    if (e.response?.status == 401 && e.response.data) {
      e.response.data.title = "Session expired";
      e.response.data.details = "Please login again";
    }
    console.error('callAPI -> error', e);
    throw new NetworkError(e);
  }
}

export const getAPI=()=>{
  const env = store.getState().system.environment;
  const currentEnv=store.getState().system.currentEnv;
  return getAPIList(env.tokenService, env.offChainTokenService, env.webhookService, currentEnv);
}
export const authUrls = () => {
  const urls = getAPI().POST;
  return [urls.createUserWithAuth, urls.authUser, urls.refreshToken, urls.revokeToken, urls.googleLogin]
}
export const initiateAxiosInterceptor = () => {
  axios.interceptors.request.use((config) => {
    return new Promise((resolve, reject) => {
      if (!authUrls().includes(config.url)) {
        const userAuthToken = GlobalAccessToken.authHeader();
        const env = store.getState().system.environment;
        if (userAuthToken && !config.url.includes(env.webhookService)) {
          Object.assign(config, { headers: { Authorization: userAuthToken } });
        }
      }
      return resolve(config);
    });
  });

  // refresh token if AccessToken expired
  // first time 401 then call refresh()
  // - if success -> continue axios with new header
  // - if fail -> cannot refresh since refresh token expired -> logout
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const {
        config,
        response: {status},
      } = error;
      let data = {};
      if (config.data) {
        try {
          data = JSON.parse(config.data)
        } catch (e) {
          data = config.data
        }
      }
      let newConfig = {...config, data };

      // refresh when first time 401
      const needRefresh =
        status === 401 && config && !config.__isRetryRequest && !authUrls().includes(config.url);

      if (!needRefresh) {
        return Promise.reject(error);
      }

      newConfig = {...newConfig, _retry: true};
      try {
        if (!GlobalAccessToken.getRefreshFlag()) {
          GlobalAccessToken.setRefreshFlag(true);
          const res: any = await refresh();
          if (!GlobalAccessToken.setTokenFromRes({ data: { ...res } })) {
            return Promise.reject(error);
          }
          GlobalAccessToken.setRefreshFlag(false);
        } else {
          while(GlobalAccessToken.getRefreshFlag()) {
            await new Promise(resolve => setTimeout(() => resolve(console.log("Awaiting new access token...")), 300))
          }
        }
        if (GlobalAccessToken.authHeader() !== '') {
          const header = {Authorization: GlobalAccessToken.authHeader()};
          newConfig = {...newConfig, headers: header};
          return axios(newConfig);
        } else {
          return Promise.reject(error);
        }
      } catch (err) {
          GlobalAccessToken.setTokenFromRes({ data: { accessToken: '' } })
          GlobalAccessToken.setRefreshFlag(false);
          return Promise.reject(err);
      }
    },
  );
}