import moment from 'moment';
import { callAPI, getAPI } from '../../utils/network';
import { WebErrorType } from '../../utils/error';
import { AppThunk } from '../types';
import { SPINNER_TOGGLE_ON, SPINNER_TOGGLE_OFF } from '../spinner/types';
import { toggleModal, toggleModalOff } from '../modal/actions';

export function deleteTokenV2(
  type: string,
  tokenList: Record<string, string>[],
  externalId: string,
  successCB?: () => void
): AppThunk {
  return async (dispatch) => {
    try {
      dispatch({ type: SPINNER_TOGGLE_ON });
      console.log({
        type,
        tokenList,
        externalId
      })
      await callAPI({
        url: getAPI().DELETE.deleteTokenV2,
        method: 'DELETE',
        data: {
          tokenType: type,
          tokenList,
          externalId
        },
      });
      dispatch(
        toggleModal({
          status: 'success',
          title: 'Deleted',
          CTAHandler: successCB,
        })
      );
    } catch (e) {
      const error = e as WebErrorType;
      console.log(e)
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        })
      );
    } finally {
      dispatch({ type: SPINNER_TOGGLE_OFF });
    }
  };
}

export function deleteBusinessUser(businessUserList: Array<{ businessId?: string }>, successCB?: () => void): AppThunk {
  return async (dispatch) => {
    try {
      dispatch({ type: SPINNER_TOGGLE_ON });
      await callAPI({
        url: getAPI().POST.deleteBusiness,
        method: 'POST',
        data: { businessId: businessUserList },
      });
      dispatch(
        toggleModal({
          status: 'success',
          title: 'Deleted',
          CTAHandler: successCB,
        })
      );
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        })
      );
    } finally {
      dispatch({ type: SPINNER_TOGGLE_OFF });
    }
  };
}

export function changeUserStatus(userList: Array<{ businessId?: string }>, status: boolean, successCB?: () => void): AppThunk {
  return async (dispatch) => {
    try {
      dispatch({ type: SPINNER_TOGGLE_ON });
      await callAPI({
        url: getAPI().POST.setUserStatus,
        method: 'POST',
        data: { userId: userList, disable: status },
      });
      dispatch(
        toggleModal({
          status: 'success',
          title: status ? 'Deactivate User Success' : 'Activate User Success',
          CTAHandler: successCB,
        })
      );
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        })
      );
    } finally {
      dispatch({ type: SPINNER_TOGGLE_OFF });
    }
  };
}

export function changeTokenStatus(
  tokenType: string,
  tokenList: Record<string, string>[],
  externalId: string,
  status: string,
  successCB?: () => void
): AppThunk {
  return async (dispatch) => {
    try {
      dispatch({ type: SPINNER_TOGGLE_ON });
      await callAPI({
        url: getAPI().POST.setTokenStatus,
        method: 'POST',
        data: {
          tokenType,
          tokenList,
          externalId,
          status
        }
      });
      console.log({
        tokenList,
        externalId,
        status
      })
      dispatch(
        toggleModal({
          title: status,
          status: 'success',
          CTAHandler: successCB,
        })
      );
    } catch (e) {
      const error = e as WebErrorType;
      dispatch (
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        })
      );
    } finally {
      dispatch({ type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function deleteUsers(userList: Array<{ userId?: string }>, successCB?: () => void): AppThunk {
  return async (dispatch) => {
    try {
      dispatch({ type: SPINNER_TOGGLE_ON });
      let res = await callAPI({
        url: getAPI().POST.deleteUser,
        method: 'POST',
        data: { userId: userList },
      });

      // Check number of user account successfully deleted. 
      // (User that create the business account cannot be deleted)
      let deleteLogs = JSON.parse(res.deleteStatus) 

      let noItemsDeleted = 0
      for(const items of deleteLogs){
        if(items.isDeleted){
          noItemsDeleted++
        }
      }

      dispatch(
        toggleModal({
          status: 'success',
          title: `Account(s) Deleted: ${noItemsDeleted}`,
          CTAHandler: successCB,
        })
      );
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        })
      );
    } finally {
      dispatch({ type: SPINNER_TOGGLE_OFF });
    }
  };
}

export function deleteAngusToken(angusAccount: Array<{ userId?: string }>, successCB?: () => void): AppThunk {
  return async (dispatch) => {
    try {
      dispatch({ type: SPINNER_TOGGLE_ON });
      await callAPI({
        url: getAPI().POST.deleteAssetToken,
        method: 'POST',
        data: { userIds: angusAccount },
      });
      dispatch(
        toggleModal({
          status: 'success',
          title: 'Deleted',
          CTAHandler: successCB,
        })
      );
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        })
      );
    } finally {
      dispatch({ type: SPINNER_TOGGLE_OFF });
    }
  };
}
