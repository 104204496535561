import produce from 'immer';
import {SystemTypes, systemState,CHANGE_ENV,GET_ENV_LIST} from './type';
import {defaultEnv, defaultEnvOption} from '../../env';

const initialState:systemState={
    envList: {},
    environment: defaultEnv,
    currentEnv: defaultEnvOption,
}

function systemReducer(state=initialState, action:SystemTypes): systemState{
    switch(action.type){
        case CHANGE_ENV:
            return produce(state,(draft)=>{
                draft.environment = action.payload.environment;
                draft.currentEnv = action.payload.currentEnv;
            });
            case GET_ENV_LIST:
                return produce(state, draft => {
                  draft.envList = action.payload;
                });
        default:
            return state;
    }
}

export default systemReducer;