import axios from "axios";
import produce from 'immer';
import { GET_TESTING_ACCOUNT } from './type';

type InitialState = {
    accounts: { id: string, name: string }[]
}

const initialState: InitialState = {
    accounts: []

}
function qaReducer(
    state = initialState,
    action: any,
): InitialState {
    switch (action.type) {
        case GET_TESTING_ACCOUNT:
            return produce(state, (draft) => {
                draft.accounts = action.payload
            })
        default:
            return state;
    }
}

export default qaReducer;