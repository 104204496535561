import React, { useState, useEffect, useReducer, useMemo } from "react";
// import { useDispatch } from "react-redux";
import {
    VirtualTableState,
    createRowCache
} from '@devexpress/dx-react-grid';
import {
    Grid as TableGrid,
    TableHeaderRow,
    VirtualTable,
} from "@devexpress/dx-react-grid-material-ui";
import { Grid, Typography, Box } from "@material-ui/core";
import moment from 'moment';
import DatePicker from "../../presentation/DatePicker";
import MyButton from '../../presentation/button';
import { PageHeader } from "../../presentation/withHeader";
import { callAPI, getAPI } from '../../utils/network';


const VIRTUAL_PAGE_SIZE = 10;
const MAX_ROWS = 1000;
const getRowId = row => row.id;

const initialState = {
  rows: [],
  skip: 0,
  requestedSkip: 0,
  take: VIRTUAL_PAGE_SIZE * 2,
  totalCount: MAX_ROWS,
  loading: false,
  lastQuery: '',
};

function reducer(state, { type, payload }) {
    switch (type) {
        case 'UPDATE_ROWS':
            return {
                ...state,
                ...payload,
                loading: false,
            };
        case 'START_LOADING':
            return {
                ...state,
                requestedSkip: payload.requestedSkip,
                take: payload.take,
            };
        case 'STOP_LOADING':
            return {
                ...state,
                totalCount: state.totalCount === MAX_ROWS ? state.requestedSkip : state.totalCount,
                loading: false,
            };
        case 'REQUEST_ERROR':
            return {
                ...state,
                loading: false,
            };
        case 'FETCH_INIT':
            return {
                ...state,
                loading: true,
            };
        case 'UPDATE_QUERY':
            return {
                ...state,
                lastQuery: payload,
            };
        default:
            return state;
    }
}

const buildQueryString = (dateFrom:Date, dateTo:Date, skip: number, take: number) => {
    if (!dateFrom) {
        alert("Please set the date from")
        return
    }

    if (!dateTo) {
        alert("Please set the date to")
        return
    }

    if (new Date(dateFrom) > new Date(dateTo)) {
        alert("Invalid date range")
        return
    }

    const dtfrm = moment(dateFrom)
    const dtFrom = dtfrm.format("YYYY-MM-DD")

    const dtto = moment(dateTo)
    let dtTo = dtto.format("YYYY-MM-DD")

    const filterParams: Record<string, string> = { }
    filterParams["start"] = `${dtFrom}T00:00:00Z`
    filterParams["end"] = `${dtTo}T23:59:59Z`

    if (skip) {
        filterParams["skip"] = skip.toString()
    }

    if (take) {
        filterParams["take"] = take.toString()
    }

    const searchParams = new URLSearchParams(filterParams);

    return searchParams.toString()
}

const KillSheet: React.FC<{}> = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    // const dispatch = useDispatch();
    const [dateFrom, setDateFrom] = useState<Date>(moment().subtract(1, 'years').toDate());
    const [dateTo, setDateTo] = useState<Date>(moment().toDate());
    
    const [columns] = useState([
        { name: 'id', title: 'id', getCellValue: row => row.id },
        { name: 'killDate', title: 'Kill Date', getCellValue: row => row.killDate },
        { name: 'establishmentId', title: 'Establishment Id', getCellValue: row => row.establishmentId },
        { name: 'pic', title: 'PIC', getCellValue: row => row.pic },
        { name: 'rfid', title: 'RFID', getCellValue: row => row.rfid },
    ]);
    const [tableColumnExtensions] = useState([
        { columnName: 'id', width: 80 },
        { columnName: 'killDate', width: 220 },
        { columnName: 'establishmentId', width: 220 },
        { columnName: 'pic', width: 120 },
    ]);
    
    const cache = useMemo(() => createRowCache(VIRTUAL_PAGE_SIZE), []);
    const updateRows = (skip: number, count: number, newTotalCount?: number) => {
        let payload = {
            skip,
            rows: cache.getRows(skip, count)
        }
        if (newTotalCount) {
            payload['totalCount'] = newTotalCount < MAX_ROWS ? newTotalCount : MAX_ROWS
        }
        dispatch({
            type: 'UPDATE_ROWS',
            payload: payload,
        });
    };

    const getRemoteRows = (requestedSkip, take) => {
        dispatch({ type: 'START_LOADING', payload: { requestedSkip, take } });
    };

    const isSameDateRange = (queryString: string, lastQueryString: string)=>{

        const currentQuery =  new URLSearchParams(queryString)
        const lastQuery =  new URLSearchParams(lastQueryString)

        return currentQuery.get("start") == lastQuery.get("start") && currentQuery.get("end") == lastQuery.get("end")
    }

    const fetchData = async (query: string, take: number, requestedSkip: number)=>{
        dispatch({ type: 'FETCH_INIT' } as any);
        await callAPI({
            url: `${getAPI().GET["getKillSheet"]}?${query} `,
            method: 'GET'
        }).then((data) => {
            if (data.length == 0) {
                dispatch({ type: 'STOP_LOADING' } as any);
                return
            }

            const killshts = data.map((ks)=>{
                let killDate
                if (ks.killDate) {
                    const killDt = moment(ks.killDate)
                    killDate = killDt.format("DD-MM-YYYY HH:mm")
                }
                return {
                    ...ks,
                    killDate
                }
            })
            
            let totalCount
            if (killshts.length < take) {
                totalCount = requestedSkip+killshts.length
            }
            
            cache.setRows(requestedSkip, killshts);
            updateRows(requestedSkip, killshts.length, totalCount)
            
        }).catch(() => dispatch({ type: 'REQUEST_ERROR' } as any));
    }

    const loadData = async () => {
        const {
            requestedSkip, take, lastQuery, loading
        } = state;

        const query = buildQueryString(dateFrom, dateTo, requestedSkip, take);
        if (query == lastQuery || loading) {
            return
        }

        const isSameDtRange = isSameDateRange(query, lastQuery)
        if (isSameDtRange) {
            const cached = cache.getRows(requestedSkip, take);
            if (cached.length) {
                updateRows(requestedSkip, take);
            } else {
                fetchData(query, take, requestedSkip)
            }
        } else {
            fetchData(query, take, requestedSkip)
        }
        
        dispatch({ type: 'UPDATE_QUERY', payload: query });
        
    };
    
    useEffect(() => {
        loadData()
    });
    
    const {
        rows, skip, totalCount, loading,
    } = state;

    const handleDate = (key: string, value: Date) => {
        switch (key) {
            case "dateFrom":
                setDateFrom(value);
                break;
            case "dateTo":
                setDateTo(value);
                break;
        }
    };

    // const onSearch = async ()=>{
    //     cache.invalidate()
    //     await loadData()
    // }

    const onReset = ()=>{
        setDateFrom(moment().subtract(1, 'years').toDate())
        setDateTo(moment().toDate())
        // cache.invalidate()
        // dispatch({ type: 'FETCH_INIT', payload: initialState } );
        // loadData()
    }

    return (
        <PageHeader
            config={{
                title: "Kill Sheet",
                margin: 1,
            }}
        >
            <Grid container alignItems="center">
                <Grid item xs={3} style={{ margin: "10px 0 20px 0" }}>
                    <Typography variant="h6" role="label">
                        Date From
                    </Typography>
                    <DatePicker
                        dateValue={dateFrom ? dateFrom.toISOString() : null}
                        handleChange={(date) => {
                            handleDate("dateFrom", date);
                        }}
                        errorStatus={
                            // !dateTo || (new Date(dateFrom) <= new Date(dateTo))
                            true
                        }
                        errorMessage={"Invalid date range."}
                    />
                </Grid>
                <Grid item xs={3} style={{ margin: "10px 0 20px 20px" }}>
                    <Typography variant="h6" role="label">
                        Date To
                    </Typography>
                    <DatePicker
                        dateValue={dateTo ? dateTo.toISOString() : null}
                        handleChange={(date) => {
                            handleDate("dateTo", date);
                        }}
                        errorStatus={
                            // !dateFrom && (new Date(dateFrom) <= new Date(dateTo))
                            true
                        }
                        errorMessage={"Invalid date range."}
                    />
                </Grid>
                <Grid item xs={1} style={{ margin: "10px 0 20px 20px" }}>
                    {/* <Box m={1}>
                        <MyButton
                            text={'Search'}
                            disabled={!dateFrom && !dateTo}
                            variant='contained'
                            onClick={onSearch}
                        />
                    </Box> */}
                    <Box m={1}>
                        <MyButton
                            text={'Clear'}
                            variant='contained'
                            onClick={() => {
                                onReset();
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
            <TableGrid
                rows={rows}
                columns={columns}
                getRowId={getRowId}
            >
                <VirtualTableState
                    infiniteScrolling={true}
                    loading={loading}
                    totalRowCount={totalCount}
                    pageSize={VIRTUAL_PAGE_SIZE}
                    skip={skip}
                    getRows={getRemoteRows}
                />
                <VirtualTable columnExtensions={tableColumnExtensions} />
                <TableHeaderRow />
            </TableGrid>
        </PageHeader>
    );
};

export default KillSheet;
