import React, { useEffect, useState, useRef } from "react";
import {
  FormControl,
  Grid,
  TextField,
  Typography,
  makeStyles,
  Box,
  Button,
} from "@material-ui/core";
import {
  PagingState,
  CustomPaging,
  SearchState,
} from '@devexpress/dx-react-grid';
import {
  Grid as TableGrid,
  Table,
  TableHeaderRow,
  PagingPanel,
  Toolbar,
  SearchPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { useHistory, useRouteMatch } from "react-router-dom";
import withHeader from "../../presentation/withHeader";
import MyButton from "../../presentation/button";
import COLOR from "../../styled/colors";
import { useAppSelector, useAppDispatch } from "../../utils/hooks";
import { fetchSessions } from "../../store/session/actions";
import { useDispatch } from "react-redux";
import { PluginRefObj, PluginForm } from "@aglive/web-core";
import { Line } from "../auth/styles";
import DialogMessage from "../../presentation/DialogMessage";
import { PluginModel, TokenModel, TokenService } from "@aglive/data-model";
import { toggleModal, toggleModalOff } from "../../store/modal/actions";
import { Buttons } from "../../presentation/ButtonsGroup";
import { HyperLink } from "../../presentation/word";
import MyTable from "../../presentation/Table"

const useStyle = makeStyles((theme) => ({
  button: {
    color: COLOR.GREEN_BUTTON,
    borderColor: COLOR.GREEN_BUTTON,
    marginRight: 50,
  },
}));

const headers = [{name:'id', title: "Session ID"}, {name:'name', title: "Session Name"},{name:'plugin', title: "Plugin"}, {name:'emailAddress', title: "Email Address"}, {name: 'action', title: "Action"}]
const Sessions: React.FC<{}> = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const classes = useStyle();
  const dispatch = useDispatch();
  const sessionData = useAppSelector((state) => state.session);
  const pluginFormRef = useRef<PluginRefObj>(null);
  const [selectedPlugin, setSelectedPlugin] = useState(
    {} as PluginModel.Plugin
  );
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('');

  const [openDialog, setOpenDialog] = useState(false);
  
  useEffect(() => {
    dispatch(fetchSessions(pageSize, currentPage+1, filter));
  }, [currentPage, pageSize, filter]);

  return (
    <>
     <MyButton
          text={"Create Session"}
          variant="contained"
          onClick={() => {
            history.push(`${path}/create`);
          }}
      />
       <TableGrid rows={
        sessionData.sessions.map(o => ({
          id: o.sessionId,
          name: o.details.name,
          plugin: o.details.plugin,
          emailAddress: o.details.emailAddress,
          action: <Button
            variant='outlined'
            style={{
              backgroundColor: COLOR.GREEN_BUTTON,
              color: COLOR.WHITE,
              border: 'none',
            }}
            onClick={() => {
              history.push(`${path}/view/${o.sessionId}`);
            }}
        >View</Button>
    }))} columns={headers}>
       <PagingState
          currentPage={currentPage}
          onCurrentPageChange={setCurrentPage}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
        />
        <SearchState
          onValueChange={setFilter}
        />
        <CustomPaging
          totalCount={sessionData.count}
        />
        <Table />
        <TableHeaderRow />
        <Toolbar />
        <SearchPanel />
        <PagingPanel pageSizes={[5, 10, 15, 20]}/>
      </TableGrid>
    </>
  );
};

export default withHeader(
  {
    title: "CSV Session",
    margin: 60,
  },
  Sessions
);
