import React, { useMemo } from "react";
import {
    Typography,
    Box,
    Checkbox,
    Grid,
    TextField,
    FormControl,
} from "@material-ui/core";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useLocation } from "react-router-dom";
import COLOR from "../../styled/colors";
import withHeader from "../../presentation/withHeader";
import { AlertModel } from "@aglive/data-model";

const AlertInfo: React.FC = () => {
    const loc = useLocation<{alert: AlertModel.Alert}>();

    const alert = useMemo(() => {
        return {
            name: '',
            find: '',
            pluginId: [],
            format: '',
            rowFormatType: 'list',
            sms: false,
            email: true,
            rowFormat: [],
            lastRun: new Date(),
            ...(loc.state?.alert ?? {})
        }
    },[loc.state])

    const alertDisplay = useMemo(() => [
        {name: 'name', label: 'Alert Name'},
        {name: 'find', label: 'Find'},
        {name: 'format', label: 'Format'},
        {name: 'rowFormat', label: 'Row Format'},
        {name: 'pluginName', label: 'Plugin'},
        {name: 'lastRun', label: 'Last Run'},
    ], []);

    return (
        <Box>
            <Box display={'flex'} flexDirection={'column'} gridRowGap={20}>
                {alertDisplay.map((fi) =>
                    <Box>
                        <Typography variant="h4">{fi.label}</Typography>
                        {fi.name === 'rowFormat'
                        ?
                            <TableContainer component={Box}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                {alert.rowFormat && (
                                    <TableHead>
                                        <TableRow sx={{ backgroundColor: COLOR.GRAY_SOLID }}>
                                            <TableCell sx={{ fontWeight: 'bold' }}>No</TableCell>
                                            {alert.rowFormatType === 'table' && <TableCell sx={{ fontWeight: 'bold' }} align="left">Field Name</TableCell>}
                                            <TableCell sx={{ fontWeight: 'bold' }} align="left">Field Value</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                )}
                                <TableBody>
                                    {alert.rowFormat?.map((item, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{
                                                "&:last-child td, &:last-child th": {
                                                    border: 0,
                                                },
                                            }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {index + 1}
                                            </TableCell>
                                            {alert.rowFormatType === 'table' && 
                                                <TableCell align="center">
                                                    <FormControl variant="filled" fullWidth>
                                                        <TextField
                                                            variant="outlined"
                                                            type="text"
                                                            value={item.name}
                                                            data-index={index}
                                                            disabled={true}
                                                            fullWidth
                                                        />
                                                    </FormControl>
                                                </TableCell>
                                            }
                                            <TableCell align="center">
                                                <FormControl variant="filled" fullWidth>
                                                    <TextField
                                                        variant="outlined"
                                                        type="text"
                                                        value={item.value}
                                                        data-index={index}
                                                        disabled={true}
                                                        fullWidth
                                                    />
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            </TableContainer>
                        :
                            <Typography>{alert[fi.name]}</Typography>
                        }
                    </Box>
                )}
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="h4">
                            Email
                            <Checkbox 
                                color="primary" 
                                checked={alert.email ? true : false } 
                                size={'medium'}
                                disabled={true}
                            />   
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h4">
                            Sms
                            <Checkbox 
                                color="primary" 
                                checked={alert.sms ? true : false} 
                                size={'medium'}
                                disabled={true}
                            />   
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default withHeader(
    {
        title: "Alert Details",
        margin: 60,
        back: true,
    },
    AlertInfo
);
