import React, { useReducer } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import COLOR from '../../../styled/colors';
import MyButton from '../../../presentation/button';
import { PageHeader } from '../../../presentation/withHeader';
import { useAppDispatch } from '../../../utils/hooks';
import DatePicker from '../../../presentation/DatePicker';
import {
  SPINNER_TOGGLE_OFF,
  SPINNER_TOGGLE_ON,
} from '../../../store/spinner/types';
import { callAPI, getAPI } from '../../../utils/network';
import { WebErrorType } from '../../../utils/error';
import { toggleModal } from '../../../store/modal/actions';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyle = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  button: {
    color: COLOR.GREEN_BUTTON,
    borderColor: COLOR.GREEN_BUTTON,
    marginRight: 50,
  },
  textBox: {
    marginTop: 10,
    marginBottom: 20,
  },
  subTitle: {
    marginTop: theme.spacing(3),
    fontWeight: 600,
  },
  tableHead: {
    '& .MuiTableCell-head': {
      fontWeight: 600,
    },
  },
}));

const initialState = {
  dateTo: '',
  dateFrom: '',
  angusCalveCount: 0,
  angusSireCount: 0,
};

type Action =
  | { type: 'change/dateTo'; dateTo: string }
  | { type: 'change/dateFrom'; dateFrom: string }
  | { type: 'change/angusCalveCount'; angusCalveCount: number }
  | { type: 'change/angusSireCount'; angusSireCount: number };

const reducer = (
  prevState: typeof initialState,
  action: Action
): typeof initialState => {
  const { type, ...actionData } = action;
  switch (action.type) {
    default:
      return { ...prevState, ...actionData };
  }
};

const Angus: React.FC<{}> = () => {
  const classes = useStyle();
  const dispatch = useAppDispatch();
  const [state, localDispatch] = useReducer(reducer, initialState);

  const handleDate = (key: string, value: Date) => {
    switch (key) {
      case 'dateFrom':
        localDispatch({
          type: 'change/dateFrom',
          dateFrom: moment(value).format('YYYY-MM-DD'),
        });
        return;
      case 'dateTo':
        localDispatch({
          type: 'change/dateTo',
          dateTo: moment(value).format('YYYY-MM-DD'),
        });
    }
  };

  const onSubmit = async () => {
    try {
      dispatch({ type: SPINNER_TOGGLE_ON });

      const response = await callAPI({
        url: getAPI().POST.getAngusTotal,
        method: 'POST',
        data: { startDate: state.dateFrom, endDate: state.dateTo },
      });

      localDispatch({
        type: 'change/angusCalveCount',
        angusCalveCount: response.angusCalve,
      });

      localDispatch({
        type: 'change/angusSireCount',
        angusSireCount: response.angusSire,
      });
    } catch (e) {
      const error = e as WebErrorType;

      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Close',
        })
      );
    } finally {
      dispatch({ type: SPINNER_TOGGLE_OFF });
    }
  };

  return (
    <PageHeader
      config={{
        title: 'Animals Count',
        margin: 1,
      }}
    >
      <Grid container>
        <Grid item xs={3} style={{ margin: '10px 0 20px 0' }}>
          <Typography variant='h6' role='label'>
            Date From
          </Typography>
          <DatePicker
            dateValue={state.dateFrom}
            handleChange={(date) => {
              handleDate('dateFrom', date);
            }}
            errorStatus={
              new Date(state.dateFrom) <= new Date(state.dateTo) ||
              !state.dateFrom
            }
            errorMessage={'Invalid date range.'}
          />
        </Grid>
        <Grid item xs={3} style={{ margin: '10px 0 20px 20px' }}>
          <Typography variant='h6' role='label'>
            Date To
          </Typography>
          <DatePicker
            dateValue={state.dateTo}
            handleChange={(date) => {
              handleDate('dateTo', date);
            }}
            errorStatus={
              new Date(state.dateFrom) <= new Date(state.dateTo) ||
              !state.dateTo
            }
            errorMessage={'Invalid date range.'}
          />
        </Grid>
      </Grid>

      <MyButton
        text={'Submit'}
        disabled={!state.dateFrom && !state.dateTo}
        variant='contained'
        onClick={() => {
          onSubmit();
        }}
      />

      <Grid style={{ marginTop: 20 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow className={classes.tableHead}>
                <TableCell variant='head'>{'Animals'}</TableCell>
                <TableCell>{'Animals Count'}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{'Angus Calve'}</TableCell>
                <TableCell>{state.angusCalveCount}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{'Angus Sire'}</TableCell>
                <TableCell>{state.angusSireCount}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </PageHeader>
  );
};

export default Angus;
