import {
  Typography,
  Grid,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useState } from 'react';
import MyButton from '../../../presentation/button';
import COLOR from '../../../styled/colors';

const useStyle = makeStyles((theme) => ({
  button: {
    color: COLOR.GREEN_BUTTON,
    borderColor: COLOR.GREEN_BUTTON,
    marginRight: 50,
  },
  textBox: {
    marginTop: 10,
    marginBottom: 20,
  },
  subTitle: {
    marginTop: theme.spacing(3),
    fontWeight: 600,
  },
}));

const searchSelectionsObject = {
  'Token ID': 1,
  'External ID': 2,
  'Blockchain Hash': 3,
};

type SearchByType = keyof typeof searchSelectionsObject;

const searchSelections: SearchByType[] = Object.keys(
  searchSelectionsObject
) as SearchByType[];

export interface BlockchainSearchFormData {
  searchText: string;
  searchBy: SearchByType;
  externalId?: string;
}

interface BlockchainSearchFormProps {
  onSubmit: (data: BlockchainSearchFormData) => void;
}

const EXTERNAL_IDS = ['bloodId', 'agliveToken'];

const BlockchainSearchForm = ({ onSubmit }: BlockchainSearchFormProps) => {
  const classes = useStyle();
  const [searchBy, setSearchBy] = useState<SearchByType>(
    searchSelections[1] as SearchByType
  );
  const [searchText, setSearchText] = useState('');
  const [externalId, setExternalId] = useState('');
  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSearchBy(event?.target?.value as SearchByType);
  };

  const handleSearchClick = () => {
    if (searchText.trim().length > 0) {
      onSubmit({
        searchText: searchText.trim(),
        searchBy: searchBy,
        externalId: externalId,
      });
    }
  };
  return (
    <>
      <Typography variant='h6' className={classes.subTitle} role='label'>
        {'Search'}
      </Typography>
      <Grid container direction='row' className={classes.textBox}>
        <Grid item xs={6}>
          <FormControl
            variant='filled'
            style={{
              width: '95%',
            }}
          >
            <TextField
              variant='outlined'
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <FormControl
                variant='filled'
                style={{
                  width: '100%',
                }}
              >
                <InputLabel id='blockchain-search-label'>ID</InputLabel>
                <Select
                  labelId='blockchain-search-label'
                  id='blockchain-search-select'
                  value={searchBy}
                  onChange={handleSelectChange}
                >
                  {searchSelections.map((selection) => (
                    <MenuItem key={selection} id={selection} value={selection}>
                      {selection}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              {searchBy === 'External ID' && (
                <FormControl
                  variant='filled'
                  style={{
                    width: '95%',
                  }}
                >
                  <Autocomplete
                    freeSolo={true}
                    id='combo-box-demo'
                    options={EXTERNAL_IDS}
                    getOptionLabel={(option) => option}
                    onInputChange={(event, value) => setExternalId(value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder='External Id'
                        label='External Id'
                        variant='outlined'
                        value={externalId}
                      />
                    )}
                  />
                </FormControl>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <MyButton
        text={'Search'}
        disabled={false}
        variant='contained'
        onClick={handleSearchClick}
      />
    </>
  );
};

export default BlockchainSearchForm;
