import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MyButton from "./button";

const useStyles = makeStyles((theme) => ({
  cancelButton: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
}));

export const Buttons = (props) => {
  const classes = useStyles();

  return (
    <Grid container direction="row" justify="space-around">
      <MyButton
        text={props.leftButtonTitle}
        width={200}
        variant="outlined"
        buttonClass={classes.cancelButton}
        onClick={props.leftButtonOnClick}
      />
      <MyButton
        text={props.rightButtonTitle}
        width={200}
        variant="contained"
        onClick={props.rightButtonOnClick}
      />
    </Grid>
  );
};
