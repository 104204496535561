import { callAPI } from "../../../utils/network";
import { getAPI } from "../../../utils/network";

export async function uploadTokenByCSV(file: File, businessId: string, template: string, additionalOptions?: Record<string, any>) {
  const form = new FormData();
  
  form.append('file', file);
  form.append('businessId', businessId);
  form.append('template', template);
  Object.entries(additionalOptions).forEach(([k, v]) => {
    form.append(k, v);
  });
  const response = await callAPI({
    url: getAPI().POST.uploadTokenCSV,
    method: 'POST',
    data: form,
  });
  return response;
}
