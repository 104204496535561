import produce from 'immer';
import {pluginStateType,PluginTypes,GET_PLUGIN_DATA} from './type';

const initialState:pluginStateType ={
    plugins:[]

}
function pluginReducer(
    state= initialState,
    action: PluginTypes,
):pluginStateType{
    switch(action.type){
        case GET_PLUGIN_DATA:
            return produce(state,(draft)=>{
                draft.plugins=action.payload
            })
        default:
            return state;
    }
}

export default pluginReducer;