import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import COLOR from "../../../styled/colors";
import CloseIcon from "@material-ui/icons/Close";
import MyButton from "../../../presentation/button";

type CSVUploadInputProps = {
    fileName: string;
    onUploadClick: () => void;
    onFileUploaded: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onFileRemoved: () => void;
};

const CSVUploadInput = React.forwardRef<HTMLInputElement, CSVUploadInputProps>(
    ({ fileName, onUploadClick, onFileUploaded, onFileRemoved }, ref) => {
        const classes = useStyle();
        return (
            <>
                <Typography
                    variant="h6"
                    className={classes.subTitle}
                    role="label"
                >
                    {"CSV File"}
                </Typography>
                <Grid
                    container
                    direction="row"
                    className={classes.uploadField}
                    spacing={3}
                >
                    <Grid item className={classes.fileName} xs={4}>
                        <Box className={classes.currentFile}>
                            {fileName ? (
                                <Box className={classes.fileNameLabel}>
                                    <Typography>{fileName}</Typography>
                                    <Box
                                        className={classes.cancelIcon}
                                        onClick={onFileRemoved}
                                    >
                                        <CloseIcon />
                                    </Box>
                                </Box>
                            ) : null}
                        </Box>
                    </Grid>
                    <Grid container item xs={2}>
                        <MyButton
                            text={"Upload File"}
                            variant="outlined"
                            onClick={onUploadClick}
                            buttonClass={classes.uploadButton}
                        />
                        <input
                            type="file"
                            accept=".csv"
                            data-cy="uploadFile"
                            onChange={onFileUploaded}
                            ref={ref}
                            style={{ height: 0, width: 0 }}
                        />
                    </Grid>
                </Grid>
            </>
        );
    }
);

const useStyle = makeStyles((theme) => ({
    uploadField: {
        marginTop: 10,
        marginBottom: 10,
    },
    uploadButton: {
        height: "2.7em",
    },
    fileName: {
        height: 48,
        width: "100%",
        maxWidth: window.innerWidth / 2,
    },
    subTitle: {
        marginTop: theme.spacing(3),
        fontWeight: 600,
    },

    currentFile: {
        height: "2.7em",
        display: "flex",
        flexDirection: "row",
        padding: "0.5em",
        alignItems: "center",
        border: `1px solid ${COLOR.GRAY_BORDER}`,
        borderRadius: "0.2em",
    },
    fileNameLabel: {
        display: "flex",
        flexDirection: "row",
        gap: "0.2em",
        border: `1px solid ${COLOR.GRAY_BORDER}`,
        borderRadius: "0.2em",
        padding: "0.45em",
        cursor: "pointer",
        userSelect: "none",
        "&:hover": {
            border: `1px solid ${COLOR.GREEN}`,
        },
    },
    cancelIcon: {
        color: COLOR.GRAY_ICON,
        "&:hover": {
            color: COLOR.GREEN,
        },
    },
}));

export default CSVUploadInput;
