import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    loader: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 1,
    },
    loadingMessage: {
      fontSize: '1.5rem',
      color: '#eee',
      fontWeight: 'normal'
    },
  }),
);

export default function Spinner({isOpen, loadingMessage}: {isOpen: boolean; loadingMessage?: string}) {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={isOpen}>
      <div className={classes.loader}>
        <CircularProgress />
        {
          !!loadingMessage && <p className={` ${classes.loadingMessage}`}>{loadingMessage}</p>
        }
      </div>
    </Backdrop>
  );
}