import { changeEnv } from "./actions";

export type environmentType = {
    webURL: string;
    tokenService: string;
    offChainTokenService: string;
    blockchainService: string;
    blockchainContractAddr: string;
    onboardingS3URL: string;
    onboardingSVGS3URL: string;
    industryTypeS3URL: string;
    authToken: string;
    webhookService: string;
  };

export interface systemState {
    readonly envList: {[key: string]: environmentType};
    readonly environment: environmentType;
    readonly currentEnv: string;
  }

export const CHANGE_ENV= 'CHANGE_ENV';
export const GET_ENV_LIST='GET_ENV_LIST'

interface ChangeEnvType{
    type: typeof CHANGE_ENV,
    payload: {
        environment: environmentType;
        currentEnv: string;
    };
}
interface GetEnvList {
    type: typeof GET_ENV_LIST;
    payload: {[key: string]: environmentType};
  }

export type SystemTypes=ChangeEnvType|GetEnvList