import React, { useReducer, useEffect } from "react";
import { useHistory, useParams, useRouteMatch, Link } from "react-router-dom";
import { makeStyles, Grid, Box } from "@material-ui/core";
import {
    Table,
    TableHeaderRow,
    Grid as TableGrid,
    PagingPanel,
} from "@devexpress/dx-react-grid-material-ui";
import { PagingState, IntegratedPaging } from "@devexpress/dx-react-grid";
import COLOR from "../../../styled/colors";
import MyButton from "../../../presentation/button";
import { PageHeader } from "../../../presentation/withHeader";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import { store } from "../../../store/index";
import { updateUserListAndBusinessInfo } from "../../../store/add/actions";
import {
    ManageUserType,
    ManageBusinessInfoType,
} from "../../../store/add/types";
import EditIcon from "@material-ui/icons/Edit";
import BusinessIcon from "@material-ui/icons/Business";
import CONSTANT from "../../../config/constant";
import { styled } from "@mui/material/styles";

const columns = [
    //{ name: 'id', title: 'No', width: 20 },
    { name: "name", title: "User Name", width: 200 },
    { name: "phone", title: "Phone", width: 150 },
    { name: "email", title: "Email", width: 300 },
    //{ name: 'userId', title: 'User ID', width: 400 },
    { name: "role", title: "Role", width: 150 },
    { name: "status", title: "Status", width: 150 },
    { name: "editButton", title: "Action", width: 150 },
];

const initialState = {
    searchVal: "",
    d_userList: [],
};

type Action =
    | { type: "change/searchVal"; searchVal: string }
    | { type: "change/d_userList"; d_userList: Array<any> };

const reducer = (
    prevState: typeof initialState,
    action: Action
): typeof initialState => {
    const { type, ...actionData } = action;
    switch (action.type) {
        default:
            return { ...prevState, ...actionData };
    }
};

const columnWidths = (
    cols: Array<{ name: string; title: string; width: number }>
) =>
    cols.map((dt) => ({
        columnName: dt.name,
        width: dt.width,
    }));

const StyledTable = styled(Table.Table)(({ theme }) => ({
    backgroundColor: COLOR.WHITE,
}));
const StyledPaging = styled(PagingPanel.Container)(({ theme }) => ({
    marginTop: 20,
}));
const StyledHeader = styled(TableHeaderRow.Content)(({ theme }) => ({
    fontWeight: 700,
}));

const HeaderComponentBase = ({ classes, ...props }) => (
    <Table.TableHead {...props} style={{ backgroundColor: COLOR.GRAY_SOLID }} />
);

const TableComponent = (props) => <StyledTable {...props} />;
const PagingComponent = (props) => <StyledPaging {...props} />;
const HeaderComponent = (props) => <StyledHeader {...props} />;

const BusinessUserManageList: React.FC<{}> = () => {
    const { businessId } = useParams<{ businessId: string }>();
    const [state, localDispatch] = useReducer(reducer, initialState);
    const dispatch = useAppDispatch();

    const userList: Array<ManageUserType> = useAppSelector(
        (state) => state.add.users
    );

    const businessInfo: ManageBusinessInfoType = useAppSelector(
        (state) => state.add.businessInfo
    );

    const userLibraryLabels = CONSTANT.EN_LABELS;

    const history = useHistory();
    const { path, url } = useRouteMatch();

    const env = store.getState().system.environment;
    const currentEnv = store.getState().system.currentEnv;

    const generateUserList = async () => {
        const temp = [];
        const x = 0;
        let v_redirect: string;
        let status = "";
        for (let x = 0; x < userList.length; x++) {
            v_redirect = userList[x].userId || userList[x].invitationCode || "";
            status = "";

            if (userList[x].deactivatedDate) {
                // Added user but is inactive
                status = userLibraryLabels.inactive;
            } else if (userList[x].addedDate) {
                // Added user and active
                status = userLibraryLabels.active;
            } else if (userList[x].invitationCode) {
                // User Pending invite
                status = userLibraryLabels.pending;
            } else {
                // New user
                status = "";
            }

            temp.push({
                //id: x+1,
                name: (
                    <Link to={`${url}/view/${v_redirect}`}>
                        {userList[x].name}
                    </Link>
                ),
                editButton: (
                    <>
                        <Link to={`${url}/edit/${v_redirect}`}>
                            <EditIcon></EditIcon>
                        </Link>
                    </>
                ),
                userId: userList[x]?.userId,
                role: userList[x].role,
                phone: userList[x].phone,
                email: userList[x].email,
                invitationCode: userList[x]?.invitationCode,
                editRedirect: v_redirect,
                status: status,
            });
        }

        localDispatch({
            type: "change/d_userList",
            d_userList: temp,
        });
    };

    useEffect(() => {
        dispatch(updateUserListAndBusinessInfo(businessId));
    }, []);

    useEffect(() => {
        generateUserList();
    }, [userList]);

    return (
        <PageHeader
            config={{
                title: "Business User List",
                margin: 1,
                back: true,
            }}
        >
            <br></br>
            {/***** INFORMATION SECTION *****/}
            <Grid container direction="row" spacing={2} alignItems="center">
                <Grid item>
                    <BusinessIcon style={{ fontSize: "2rem" }}></BusinessIcon>
                </Grid>
                <Grid item>
                    <div style={{ fontSize: "1.5rem", marginBottom: "0.3rem" }}>
                        {" "}
                        <b>{businessInfo.businessName} </b>
                    </div>
                    <div style={{ fontSize: "0.8rem" }}>
                        {" "}
                        <i>
                            <span>ID: </span>
                            {businessId}
                        </i>
                    </div>
                </Grid>
            </Grid>

            <br></br>

            <MyButton
                text="New Invite"
                disabled={false}
                variant="contained"
                onClick={() => {
                    history.push(`${url}/add`);
                }}
            />
            <br></br>
            <br></br>

            {/***** TABLE SECTION *****/}
            <Grid container>
                <Box
                    p={"40px 15px 30px 15px"}
                    style={{
                        backgroundColor: COLOR.GRAY_SOLID,
                        width: "100%",
                    }}
                >
                    <TableGrid rows={state.d_userList} columns={columns}>
                        <PagingState />
                        <IntegratedPaging />
                        <Table
                            tableComponent={TableComponent}
                            headComponent={HeaderComponentBase}
                            columnExtensions={columnWidths(columns)}
                        />
                        <TableHeaderRow contentComponent={HeaderComponent} />
                        <PagingPanel
                            pageSizes={[5, 10, 15, 20]}
                            containerComponent={PagingComponent}
                        />
                    </TableGrid>
                </Box>
            </Grid>
        </PageHeader>
    );
};

export default BusinessUserManageList;
