import { PluginModel, TokenModel, TokenService } from '@aglive/data-model';



export const GET_SESSION_DATA='GET_SESSION_DATA';
export const GET_SINGLE_SESSION='GET_SINGLE_SESSION';
export const GET_PLUGINS_AVAILABLE='GET_PLUGINS_AVAILABLE'
export const GET_TEMPLATES='GET_TEMPLATES'
export interface sessionStateType{
    readonly templates:Array<any>
    readonly session:any
    readonly  sessions:Array<any>,
    readonly pages: number,
    readonly count: number
    readonly plugins: Array<string>
}
export interface fetchSessionType{
    type: typeof GET_SESSION_DATA | typeof GET_PLUGINS_AVAILABLE;
    payload:Array<sessionStateType>
}
export type SessionType = fetchSessionType