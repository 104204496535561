import {callAPI, downloadFile, getAPI} from '../../utils/network';
import {NetworkError, WebErrorType} from '../../utils/error';
import {AppThunk} from '../types';
import {SPINNER_TOGGLE_ON, SPINNER_TOGGLE_OFF} from '../spinner/types';
import {toggleModal, toggleModalOff} from '../modal/actions';
import { GET_SESSION_DATA, GET_PLUGINS_AVAILABLE, GET_SINGLE_SESSION, GET_TEMPLATES } from './type';


export function fetchSessions(row: number, page:number, filter: string):AppThunk<Promise<void>>{
    return async(dispatch)=>{
      try{
        const sessions=await callAPI({
          url: getAPI().GET.getSessions,
          method: 'GET',
          params: {
            rows: row,
            page,
            filter
          }
        })
        dispatch({
          type:GET_SESSION_DATA,
          payload:sessions,
        });
      }
      catch(e){
        const error = e as WebErrorType;
        dispatch(
          toggleModal({
            status: 'failed',
            title: error.title,
            subtitle: error.message,
            button: 'Close',
          }),
        );
      }
    }
}

export function fetchSession(sessionId: string):AppThunk<Promise<void>>{
  return async(dispatch)=>{
    try{
      const sessions=await callAPI({
        url: getAPI().GET.getSessions + `/${sessionId}`,
        method: 'GET',
      })
      dispatch({
        type:GET_SINGLE_SESSION,
        payload:sessions,
      });
    }
    catch(e){
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Close',
        }),
      );
    }
  }
}

export function fetchSessionPlugins():AppThunk<Promise<void>>{
  return async(dispatch)=>{
    try{
      const sessions=await callAPI({
        url: getAPI().GET.getSessionGroups,
        method: 'GET',
      })
      dispatch({
        type:GET_PLUGINS_AVAILABLE,
        payload:sessions,
      });
    }
    catch(e){
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Close',
        }),
      );
    }
  }
}

export function createSession(data: {name: string, plugin: string}, successCB: () => void):AppThunk<Promise<void>>{
  return async(dispatch)=>{
    try{
      dispatch({type: SPINNER_TOGGLE_ON});

      const sessions=await callAPI({
        url: getAPI().POST.createSession,
        method: 'POST',
        data
      })
      dispatch(
        toggleModal({
          status: 'success',
          title: 'Successfully Created',
          button: 'Close',
          CTAHandler: successCB,
        }),
      );
    }
    catch(e){
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Close',
        }),
      );
    } finally{
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  }
}

export function fetchTemplates(groupName:string):AppThunk<Promise<void>>{
  return async(dispatch)=>{
    try{
      dispatch({type: SPINNER_TOGGLE_ON});

      const templates=await callAPI({
        url:getAPI().GET.socketURL + `/api/dev/group/${groupName}/template`,
        method: 'GET',
      })
      dispatch({
        type:GET_TEMPLATES,
        payload:templates,
      });
    }
    catch(e){
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Close',
        }),
      );
    } finally{
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  }
}


export function generateData(data, successCB: () => void):AppThunk<Promise<void>>{
  return async(dispatch)=>{
    try{
      dispatch({type: SPINNER_TOGGLE_ON});

      const sessions=await callAPI({
        url: getAPI().POST.generateCSV,
        method: 'POST',
        data
      })
      dispatch(
        toggleModal({
          status: 'success',
          title: 'Successfully Created',
          button: 'Close',
          CTAHandler: successCB,
        }),
      );
    }
    catch(e){
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Close',
        }),
      );
    } finally{
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  }
}

export function downloadCSV(data: {sessionId: string, csvIds?: string[]}):AppThunk<Promise<void>>{
  return async(dispatch)=>{
    try{
      dispatch({type: SPINNER_TOGGLE_ON});

      await downloadFile({
        url: getAPI().GET.getSessions + `/${data.sessionId}/csv`,
        method: 'GET',
        params: {
          ...(data.csvIds?.length ? {csvIds: data.csvIds.toString()} : {})
        }
      })
    }
    catch(e){
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Close',
        }),
      );
    } finally{
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  }
}