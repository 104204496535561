import {
  Button,
  CircularProgress,
  FormControl,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from 'react';

import COLOR from "../styled/colors";
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";

const useStyle = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(5),
  },
  subTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    fontWeight: 600,
  },
  inputContainer: {
    display: 'flex',
    width: '100%',
    height: '60px',
  },
  inputTextField: {
    '&>*': {
      height: '100%',
      borderRight: 0,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    margin: 0,
  },
  searchButton: {
    height: '100%',
    padding: 14,
    backgroundColor: COLOR.GREEN_BUTTON,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    color: COLOR.WHITE,
    '&:hover': {
      backgroundColor: COLOR.GREEN_BUTTON,
    },
  },
}));

type SearchDropdownProps = {
  options: Array<{ label: string; value: string }>;
  onValueChange: (value: string) => any;
  onSearch: (label: string) => any;
  isLoading?: boolean;
  title?: string;
  inputLabel?: string;
};
const SearchDropdown: React.FC<SearchDropdownProps> = ({
  options,
  onValueChange,
  onSearch,
  isLoading = false,
  title,
  inputLabel,
}) => {
  const classes = useStyle();
  return (
    <FormControl variant='outlined' className={classes.formControl}>
      {!!title && (
        <Typography variant='h6' className={classes.subTitle}>
          {title}
        </Typography>
      )}
      <Autocomplete
        fullWidth
        freeSolo
        options={options}
        onChange={(e, v) => {
          if (v == null) {
            onValueChange('');
            return;
          }
          if (v && typeof v === 'object' && 'value' in v) {
            onValueChange(v.value);
          }
        }}
        getOptionSelected={(option, value) => option.value === value.value}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          return option.label ?? '';
        }}
        renderInput={(params) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const value = (params.inputProps as any).value as string;
                if (!value || value?.trim().length === 0) {
                  return;
                }
                onSearch(value);
              }}
              className={classes.inputContainer}
            >
              <TextField
                {...params}
                label={inputLabel}
                margin='normal'
                variant='outlined'
                className={classes.inputTextField}
                disabled={isLoading}
              />
              <Button type='button' className={classes.searchButton}>
                {isLoading ? (
                  <CircularProgress size={24} color='inherit' />
                ) : (
                  <SearchIcon />
                )}
              </Button>
            </form>
          );
        }}
      />
    </FormControl>
  );
};

export default SearchDropdown;
