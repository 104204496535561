import styled from 'styled-components';
import agliveLogo from '../img/agliveLogo.png';

const Container = styled.div`
  background-color: white;
  height: 70px;
  width: 100%;
  margin-top: 30px;
  margin-left: 50px;
`;

const Logo = styled.img`
  width: 118px;
  height: 38.34px;
  align-self: center;
`;

const LinkContainer = styled.div`
  align-self: center;
  margin-right: 80px;
`;

const CustomLink = styled.a`
  text-decoration: none;
  border-right: 1px solid #373935;
  padding-right: 10px;
  padding-left: 10px;
  :last-child {
    border-right: none;
  }
  font-size: 16px;
  color: #373935;
`;

export default function Header() {
  return (
    <Container>
      <Logo src={agliveLogo} />
    </Container>
  );
}
