import { AmbioxeraApiType } from './schema';

type DownloadCsvFile = 'template' | 'example';
const BLOOD_CSV_FILES = [
  'blood'
];

const PROCESSING_CSV_FILES = [
  'bottle', 'canister', 'pallet', 'box'
];

const REJECT_CSV_FILES = [
  'reject'
];

function toDownloadLink(name: string, type: DownloadCsvFile) {
  return {
    name,
    value: getAmbioxeraCsvFileLink(name, type)
  };
}

function getAmbioxeraCsvFileLink(name: string, type: DownloadCsvFile) {
  return `https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/ambioxera-${name}-${type}-v1.csv`;
}

export function getAmbioxeraCsvFiles(apiType: AmbioxeraApiType, type: DownloadCsvFile) {
  if (apiType === 'insertBag') {
    return BLOOD_CSV_FILES.map(x => toDownloadLink(x, type));
  }
  if (apiType === 'processing') {
    return PROCESSING_CSV_FILES.map(x => toDownloadLink(x, type));
  }
  if (apiType === 'rejection') {
    return REJECT_CSV_FILES.map(x => toDownloadLink(x, type));
  }
  return [];
}