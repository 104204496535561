import { makeStyles } from '@material-ui/core';
import COLOR from '../../../styled/colors';

const useStyle = makeStyles((theme) => ({
  tableContainer: {
    marginTop: 15,
  },
  labelCell: {
    backgroundColor: '#f0f0f0',
    padding: 8,
    wordWrap: 'break-word',
  },
  valueCell: {
    backgroundColor: '#f0f0f0',
    padding: 8,
    wordWrap: 'break-word',
  },
  subtitle: {
    marginLeft: 10,
    marginBottom: 5,
    marginTop: 10,
  },
  titleLabel: {
    fontWeight: 600,
    fontSize: '1rem',
  },
  displayText: {
    fontSize: '1rem',
  },
}));

export const blockchainStyle = {
  useStyle,
};
