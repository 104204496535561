import React, { useCallback, useEffect, useReducer, useState } from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  TextField,
  Box,
  MuiThemeProvider,
  Tab,
} from '@material-ui/core';
import { styled } from '@mui/material/styles';
import { createTheme } from '@material-ui/core/styles';
import {
  Table,
  TableHeaderRow,
  TableSelection,
  Grid as TableGrid,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import {
  SelectionState,
  PagingState,
  IntegratedSelection,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import COLOR from '../../../styled/colors';
import MyButton from '../../../presentation/button';
import { PageHeader } from '../../../presentation/withHeader';
import { useAppDispatch } from '../../../utils/hooks';
import {
  SPINNER_TOGGLE_OFF,
  SPINNER_TOGGLE_ON,
} from '../../../store/spinner/types';
import { callAPI, getAPI } from '../../../utils/network';
import { toggleModal } from '../../../store/modal/actions';
import { WebErrorType } from '../../../utils/error';
import {
  changeUserStatus,
  deleteAngusToken,
} from '../../../store/monitor/actions';

const useStyle = makeStyles((theme) => ({
  button: {
    color: COLOR.GREEN_BUTTON,
    borderColor: COLOR.GREEN_BUTTON,
    marginRight: 50,
  },
  deleteButton: {
    width: 200,
    color: COLOR.WHITE,
    backgroundColor: COLOR.AA_RED,
    borderColor: COLOR.AA_RED,
    '&:hover': {
      backgroundColor: COLOR.AA_RED,
      borderColor: COLOR.AA_RED,
      boxShadow: 'none',
    },
  },
}));

const initialState = {
  angusAccountSelectedIndex: [] /**can only save index on current page */,
  selectedAngusAccountInfo: [] /**save all selected animal info */,
  currentPage: 0,
  pageSize: 10,
  angusAccountList: [],
};

type Action =
  | {
      type: 'change/angusAccountSelectedIndex';
      angusAccountSelectedIndex: Array<number>;
    }
  | {
      type: 'change/selectedAngusAccountInfo';
      selectedAngusAccountInfo: Array<any>;
    }
  | { type: 'change/page'; currentPage: number }
  | { type: 'change/pageSize'; pageSize: number }
  | { type: 'change/angusAccountList'; angusAccountList: Array<any> };

const reducer = (
  prevState: typeof initialState,
  action: Action
): typeof initialState => {
  const { type, ...actionData } = action;
  switch (action.type) {
    default:
      return { ...prevState, ...actionData };
  }
};

const StyledTable = styled(Table.Table)(({ theme }) => ({
  backgroundColor: COLOR.WHITE,
}));
const StyledPaging = styled(PagingPanel.Container)(({ theme }) => ({
  marginTop: 20,
}));
const StyledHeader = styled(TableHeaderRow.Content)(({ theme }) => ({
  fontWeight: 700,
}));

const StyledCell = styled(Table.Cell)(({ theme }) => ({
  whiteSpace: 'unset',
}));

const HeaderComponentBase = ({ classes, ...props }) => (
  <Table.TableHead {...props} style={{ backgroundColor: COLOR.GRAY_SOLID }} />
);

export const TableComponent = (props) => <StyledTable {...props} />;
export const PagingComponent = (props) => <StyledPaging {...props} />;
export const HeaderComponent = (props) => <StyledHeader {...props} />;
export const TableCellComponent = (props) => <StyledCell {...props} />;

const cellTheme = createTheme({
  palette: {
    secondary: {
      main: COLOR.GREEN,
    },
  },
});

const cellComponent = (props) => (
  <MuiThemeProvider theme={cellTheme}>
    <TableSelection.Cell {...props} />
  </MuiThemeProvider>
);

const Users: React.FC<{}> = () => {
  const classes = useStyle();
  const [state, localDispatch] = useReducer(reducer, initialState);
  const dispatch = useAppDispatch();

  const columnWidths = (
    cols: Array<{ name: string; title: string; width: number }>
  ) =>
    cols.map((dt) => ({
      columnName: dt.name,
      width: dt.width,
    }));

  const changePage = useCallback((pageNum: number) => {
    localDispatch({ type: 'change/page', currentPage: pageNum });
  }, []);
  const changePageSize = useCallback((size: number) => {
    localDispatch({ type: 'change/pageSize', pageSize: size });
  }, []);

  const selectAngusAccountUser = useCallback(
    (index: Array<number>) => {
      if (state.angusAccountSelectedIndex.length > index.length) {
        let diff = state.angusAccountSelectedIndex.filter(
          (item) => !index.includes(item)
        )[0];
        const newArray = state.selectedAngusAccountInfo.filter(
          (item) => item.businessId !== state.angusAccountList[diff].businessId
        );
        localDispatch({
          type: 'change/selectedAngusAccountInfo',
          selectedAngusAccountInfo: newArray,
        });
      } else {
        let diff = index.filter(
          (item) => !state.angusAccountSelectedIndex.includes(item)
        )[0];
        localDispatch({
          type: 'change/selectedAngusAccountInfo',
          selectedAngusAccountInfo: [
            ...state.selectedAngusAccountInfo,
            state.angusAccountList[diff],
          ],
        });
      }
      localDispatch({
        type: 'change/angusAccountSelectedIndex',
        angusAccountSelectedIndex: index,
      });
    },
    [
      state.angusAccountSelectedIndex,
      state.selectedAngusAccountInfo,
      state.angusAccountList,
    ]
  );

  const columns = [
    { name: 'id', title: 'No', width: 60 },
    { name: 'businessName', title: 'Business Name', width: 250 },
    { name: 'accId', title: 'Account ID', width: 150 },
    { name: 'userIds', title: 'User IDs', width: 450 },
  ];

  const fetchAngusAccount = useCallback(async () => {
    try {
      dispatch({ type: SPINNER_TOGGLE_ON });

      const response = await callAPI({
        url: getAPI().GET.getAngusAccounts,
        method: 'GET',
        data: [],
      });
      const angusAccount = [];
      response?.map((item, index) => {
        let userIds =
          item.users &&
          item.users
            .map((item) => item['userId'])
            .filter((item) => item)
            .join('\n');

        angusAccount.push({
          id: index + 1,
          businessName: item.businessName,
          accId: item.acctID,
          userIds: userIds,
        });
      });

      localDispatch({
        type: 'change/angusAccountList',
        angusAccountList: angusAccount,
      });
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Close',
        })
      );
    } finally {
      dispatch({ type: SPINNER_TOGGLE_OFF });
    }
  }, []);

  useEffect(() => {
    fetchAngusAccount();
  }, []);

  const onDelete = useCallback(() => {
    let selectedAngusAccountList = state.selectedAngusAccountInfo.map(
      (item) => {
        return item['userIds'].replace(/\n/g, ',').split(',');
      }
    );

    dispatch(
      deleteAngusToken(
        Object.assign([], ...selectedAngusAccountList.map((item) => item)),
        () => {
          localDispatch({
            type: 'change/selectedAngusAccountInfo',
            selectedAngusAccountInfo: [],
          });
          localDispatch({
            type: 'change/angusAccountSelectedIndex',
            angusAccountSelectedIndex: [],
          });
        }
      )
    );
  }, [state.selectedAngusAccountInfo]);

  return (
    <PageHeader
      config={{
        title: 'Delete Angus Token',
        margin: 1,
      }}
    >
      <Grid style={{ marginTop: 20 }}>
        <Box
          p={'40px 15px 30px 15px'}
          ml={5}
          style={{
            marginLeft: 0,
            backgroundColor: COLOR.GRAY_SOLID,
          }}
        >
          <TableGrid rows={state.angusAccountList} columns={columns}>
            <SelectionState
              selection={state.angusAccountSelectedIndex}
              onSelectionChange={selectAngusAccountUser}
            />
            <IntegratedSelection />

            <PagingState
              currentPage={state.currentPage}
              onCurrentPageChange={changePage}
              pageSize={state.pageSize}
              onPageSizeChange={changePageSize}
            />
            <IntegratedPaging />
            <Table
              tableComponent={TableComponent}
              headComponent={HeaderComponentBase}
              columnExtensions={columnWidths(columns)}
              cellComponent={TableCellComponent}
            />
            <TableHeaderRow contentComponent={HeaderComponent} />
            <TableSelection cellComponent={cellComponent} />
            <PagingPanel
              pageSizes={[5, 10, 15, 20]}
              containerComponent={PagingComponent}
            />
          </TableGrid>
        </Box>

        {state.selectedAngusAccountInfo.length > 0 && (
          <Grid container style={{ marginTop: 20 }}>
            <MyButton
              text={'Delete'}
              variant='outlined'
              disabled={!state.selectedAngusAccountInfo.length}
              buttonClass={classes.deleteButton}
              onClick={() => {
                onDelete();
              }}
            />
          </Grid>
        )}
      </Grid>
    </PageHeader>
  );
};
export default Users;
