import axios from "axios";
import { AppThunk } from '../types';
import { callAPI, getAPI } from '../../utils/network';
import { NetworkError, WebErrorType } from '../../utils/error';
import { GET_TESTING_ACCOUNT } from './type';
import { toggleModal, toggleModalOff } from '../modal/actions';
import { SPINNER_TOGGLE_ON, SPINNER_TOGGLE_OFF } from '../spinner/types';

export function fetchAccount(): AppThunk<Promise<void>> {
    return async (dispatch) => {
        try {
            dispatch({ type: SPINNER_TOGGLE_ON });

            const accounts = await callAPI({
                url: getAPI().GET.getTestingAccount,
                method: 'GET',
            })
            dispatch({
                type: GET_TESTING_ACCOUNT,
                payload: accounts,
            });
        }
        catch (e) {
            const error = e as WebErrorType;
            dispatch(
                toggleModal({
                    status: 'failed',
                    title: error.title,
                    subtitle: error.message,
                    button: 'Close',
                }),
            );
        } finally {
            dispatch({ type: SPINNER_TOGGLE_OFF });
        }
    }
}

export function createAccount(jsonObject: any, successCB: () => void): AppThunk<Promise<void>> {
    return async (dispatch) => {
        try {
            dispatch({ type: SPINNER_TOGGLE_ON });
            await callAPI({
                url: getAPI().POST.getTestingAccount,
                method: 'POST',
                data: jsonObject
            })
            // console.log("jsonObject", jsonObject)
            dispatch(
                toggleModal({
                    status: 'success',
                    title: 'Successfully Completed',
                    button: 'Close',
                    CTAHandler: successCB,
                }),
            )
        }
        catch (e) {
            const error = e as WebErrorType;
            dispatch(
                toggleModal({
                    status: 'failed',
                    title: error.title,
                    subtitle: error.message,
                    button: 'Close',
                }),
            );
        } finally {
            dispatch({ type: SPINNER_TOGGLE_OFF });
        }
    }
}

export function fetchAccountById(accountId: string): AppThunk<Promise<any>> {
    return async (dispatch) => {
        try {
            dispatch({ type: SPINNER_TOGGLE_ON });

            const account = await callAPI({
                url: `${getAPI().GET.getTestingAccountById}/${accountId}`,
                method: 'GET',
            })

            return account
        }
        catch (e) {
            const error = e as WebErrorType;
            dispatch(
                toggleModal({
                    status: 'failed',
                    title: error.title,
                    subtitle: error.message,
                    button: 'Close',
                }),
            );
        } finally {
            dispatch({ type: SPINNER_TOGGLE_OFF });
        }
    }
}

export function deleteAccount(accountId: string, successCB: () => void): AppThunk<Promise<void>> {
    return async (dispatch) => {
        try {
            dispatch({ type: SPINNER_TOGGLE_ON });
            await callAPI({
                url: getAPI().POST.getTestingAccount,
                method: 'DELETE',
                data: { id: accountId }
            })
            // console.log("jsonObject", jsonObject)
            dispatch(
                toggleModal({
                    status: 'success',
                    title: 'Successfully Completed',
                    button: 'Close',
                    CTAHandler: successCB,
                }),
            )
        }
        catch (e) {
            const error = e as WebErrorType;
            dispatch(
                toggleModal({
                    status: 'failed',
                    title: error.title,
                    subtitle: error.message,
                    button: 'Close',
                }),
            );
        } finally {
            dispatch({ type: SPINNER_TOGGLE_OFF });
        }
    }
}

export function loginAccount(accountId: string): AppThunk<Promise<{authorizationCode: string}>> {
    return async (dispatch) => {
        try {
            dispatch({ type: SPINNER_TOGGLE_ON });

            const res = await callAPI({
                url: getAPI().POST.qaLogin,
                method: 'POST',
                data: {
                    accountId
                }
            });

            return res
        }
        catch (e) {
            const error = e as WebErrorType;
            dispatch(
                toggleModal({
                    status: 'failed',
                    title: error.title,
                    subtitle: error.message,
                    button: 'Close',
                }),
            );
        } finally {
            dispatch({ type: SPINNER_TOGGLE_OFF });
        }
    }
}