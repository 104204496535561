import { Box } from '@material-ui/core';
import React from 'react';
import MyButton from './button';

type ConfirmButtonsProps = {
  onCancel: () => void;
  onConfirm: () => void;
  cancelText?: string;
  confirmText?: string;
};


const ConfirmButtons: React.FC<ConfirmButtonsProps> = ({
  onCancel,
  onConfirm,
  cancelText = 'Cancel',
  confirmText = 'OK',
}) => {
  return (
    <Box display={'flex'} flexDirection={'row'} gridGap={'2rem'} width={'90%'}>
      <MyButton variant='outlined' onClick={onCancel} text={cancelText} />
      <MyButton variant='contained' onClick={onConfirm} text={confirmText}/>
    </Box>
  );
};

export default ConfirmButtons;
