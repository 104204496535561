import React, { useEffect, useState, useRef } from "react";
import {
  FormControl,
  Grid,
  TextField,
  Typography,
  makeStyles,
  Box,
  Button,
} from "@material-ui/core";
import { useHistory, useRouteMatch } from "react-router-dom";
import withHeader from "../../presentation/withHeader";
import MyButton from "../../presentation/button";
import COLOR from "../../styled/colors";
import { useAppSelector, useAppDispatch } from "../../utils/hooks";
import { createSession, fetchSessions, fetchSessionPlugins } from "../../store/session/actions";
import { useDispatch } from "react-redux";
import { PluginRefObj, PluginForm } from "@aglive/web-core";
import { Line } from "../auth/styles";
import DialogMessage from "../../presentation/DialogMessage";
import { PluginModel, TokenModel, TokenService } from "@aglive/data-model";
import { toggleModal, toggleModalOff } from "../../store/modal/actions";
import { Buttons } from "../../presentation/ButtonsGroup";
import { HyperLink } from "../../presentation/word";
import MyTable from "../../presentation/Table"
import { FormGenerator, FormInputFunctions } from "../../presentation/FormGenerator";

const CreateSession: React.FC<{}> = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  //const [sessionDetails, setSessionDetails] = useState({name: "", plugin: ""} as {name?: string, plugin?: string})
  const sessionDetails = {name: "", plugin: ""}
  const plugins = useAppSelector(state => state.session.plugins)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSessionPlugins())
  }, []);
  //Plugin
  const [formReady, setFormReady] = useState(sessionDetails?.name ? true : false);

  const formRef = useRef<FormInputFunctions<typeof sessionDetails>>()
  //Session Name

  const submitSession = () => {
    dispatch(createSession(formRef.current?.getValues(), () => {
      history.goBack();
    }))
  }

  const formInputs = [
    {
      type: 'INPUT',
      name: 'name',
      titleKey: 'name',
      required: true,
      value: sessionDetails?.name,
      viewMode: false,
    },
    {
      type: 'SELECT',
      name: 'plugin',
      titleKey: 'plugin',
      items: plugins,
      required: true,
      value: sessionDetails?.plugin,
      viewMode: false,
    },
  ];


  return (
    <>      
      <Grid container spacing={3}>
        <FormGenerator ref={formRef} formInputs={formInputs} onReady={setFormReady}/>
      </Grid>
      <MyButton 
          text={"Submit"}
          variant="outlined"
          buttonClass={''}
          disabled={!formReady}
          onClick={() => submitSession()}
      /> 
    </>
  );
};

export default withHeader(
  {
    title: "Create Session",
    margin: 60,
    back: true,
  },
  CreateSession
);
