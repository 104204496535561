export function formatAmbioxera(data: Array<Record<string, any>>): Array<Record<string, any>> {
  return data.map(row => {
    const newRow = { ...row };
    Object.entries(newRow).forEach(([key, value]) => {
      if (typeof value === 'string') {
        if (['true', 'false', 'good', 'bad'].includes(value.toLowerCase()) || isMatching(key, ['Quarantine', 'temp'])) {
          newRow[key] = toBoolean(value.toLowerCase());
        }
        else if (isMatching(key, ['count', 'weight', 'fWeight', 'hemoglobin', 'endotoxin'])) {
          newRow[key] = toNumber(value, 1);
        }
        else if (isMatching(key, ['ID', 'RFID', 'PIC', 'previousID'])) {
          newRow[key] = stringToArray(value);
        }
        else if (value == undefined) {
          newRow[key] = '';
        }
        else {
          newRow[key] = value.trim();
        }
      } else if (Array.isArray(value)) {
        newRow[key] = value.filter(x => x.trim().length > 0);
      }
    });
    return newRow;
  });
}

export function delay(ms: number): Promise<any> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const isMatching = (str, matches) => {
  return matches.some(match => str.toLowerCase() === match.toLowerCase());
};

const toBoolean = (str) => {
  return ['true', 'good'].includes(str.toLowerCase());
};

const isFloatingNumber = (str) => {
  return str.indexOf('.') !== -1;
};

const toNumber = (str, defaultValue) => {
  let result = defaultValue;
  if (typeof str !== 'number') {
    if (isFloatingNumber(str)) {
      result = parseFloat(str);
    } else {
      result = parseInt(str);
    }
  }
  if (isNaN(result)) {
    result = defaultValue;
  }
  return result;
};

const stringToArray = (str: string) => {
  return str.split(',').map(x => x.trim()).filter(x => x.trim().length > 0);
};