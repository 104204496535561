import { compose, createStore, combineReducers, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import storageSession from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';
import authReducer from './auth/reducers';
import modalReducer from './modal/reducers';
import spinnerReducer from './spinner/reducers';
import profileReducer from './profile/reducers';
import pluginReducer from './plugin/reducers';
import systemReducer from './environment/reducers';
import addReducer from './add/reducers';
import deleteTokenReducer from './deleteToken/reducers';
import qaReducer from './qa/reducers';
import alertReducer from './alert/reducers';
import sessionReducer from './session/reducers';

let composeEnhancers = compose;

if (process.env.NODE_ENV === 'development') {
  composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

// https://stackoverflow.com/questions/56099291/how-to-persist-to-both-localstorage-and-sessionstorage-at-the-same-time-using-re
const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['system', 'user'],
  blacklist: ['modal', 'spinner'],
};

const authPersistConfig = {
  key: 'auth',
  storage: storageSession,
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  modal: modalReducer,
  spinner: spinnerReducer,
  user: profileReducer,
  plugin: pluginReducer,
  system: systemReducer,
  add: addReducer,
  deleteToken: deleteTokenReducer,
  qa: qaReducer,
  alert: alertReducer,
  session: sessionReducer
  // activity: activityReducer,
  // assetProfile: assetProfileReducer,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export type RootState = ReturnType<typeof rootReducer>;

export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk)),
);
// Middleware: Redux Persist Persister
export const persistor = persistStore(store);
