import { PluginModel, TokenModel, TokenService } from '@aglive/data-model';



export const GET_PLUGIN_DATA='GET_PLUGIN_DATA';
export interface pluginStateType{
    readonly  plugins:Array<PluginModel.Plugin>
}
export interface fetchPluginType{
    type: typeof GET_PLUGIN_DATA;
    payload:Array<PluginModel.Plugin>
}
export type PluginTypes = fetchPluginType