import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import {
    ChangeSet,
    Column,
    DataTypeProvider,
    EditingState,
} from "@devexpress/dx-react-grid";
import {
    Grid as TableGrid,
    Table,
    TableHeaderRow,
    TableEditColumn,
    TableEditRow,
    TableColumnVisibility,
} from "@devexpress/dx-react-grid-material-ui";
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    Input,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@material-ui/core";
import MyButton from "../../presentation/button";
import DeleteIcon from "@material-ui/icons/Delete";
import COLOR from "../../styled/colors";
import { styled } from "@material-ui/core";
import { DataTypeProviderProps } from "@devexpress/dx-react-grid";

// import DeleteIcon from '@mui/icons-material/Delete';
export type ValidationRule = {
    isValid: (val: any) => boolean;
    errorText: string;
};

type EditDataTableProps<T> = {
    rows: Array<T>;
    setRows: React.Dispatch<React.SetStateAction<Array<T>>>;
};

const StyledHeader = styled(TableHeaderRow.Content)(({ theme }) => ({
    fontWeight: 700,
}));

function getTypeColumnsFn(typeCheckCallback: (value: any) => boolean) {
    return function getTypeColumns(rows: Array<Record<string, any>>) {
        let columns: Array<string> = [];
        rows.forEach((row) =>
            Object.entries(row).forEach(([key, value]) => {
                if (typeCheckCallback(value) && !columns.includes(key)) {
                    columns.push(key);
                }
            })
        );
        return columns;
    };
}

function formatColumns(data: Array<Record<string, any>>) {
    const uniqueKeys = data.reduce((result, item) => {
        Object.keys(item).forEach((key) => {
            if (!result.includes(key)) {
                result.push(key);
            }
        });
        return result;
    }, []);
    const columns: Column[] = uniqueKeys.map((key) => ({
        name: key,
        title: key,
    }));
    return columns;
}

function formatColumnExtension(columns: Column[]) {
    return columns.map((col) => ({
        columnName: col.name,
        width: "220",
    }));
}

function getRowId(row: { id: any }) {
    return row.id;
}

const BooleanFormatter = ({ value }) => (
    <Chip variant="outlined" color="primary" label={value ? "True" : "False"} />
);

const BooleanEditor: React.FC<DataTypeProvider.ValueEditorProps> = ({
    value,
    onValueChange,
}) => (
    <Select
        input={<Input />}
        value={value ? "True" : "False"}
        onChange={(event) => onValueChange(event.target.value === "True")}
        style={{ width: "100%" }}
    >
        <MenuItem value="True">True</MenuItem>
        <MenuItem value="False">False</MenuItem>
    </Select>
);

const BooleanTypeProvider = (
    props: Omit<DataTypeProviderProps, "formatterComponent" | "editorComponent">
) => (
    <DataTypeProvider
        formatterComponent={BooleanFormatter}
        editorComponent={BooleanEditor}
        {...props}
    />
);

const getBooleanColumns = getTypeColumnsFn(
    (value) => typeof value === "boolean"
);

const ArrayFormatter: React.FC<DataTypeProvider.ValueEditorProps> = ({
    value,
}) => {
    return (
        <FormControl>
            <Select
                labelId="array-select-label"
                id="array-simple-select"
                value={value[0] ?? ""}
            >
                {value.map((item, i) => (
                    <MenuItem key={i} value={item}>
                        {item}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

const ArrayEditor = ({ column, value, onValueChange }) => {
    const [open, setOpen] = useState(false);
    const [items, setItems] = useState<string[]>(value);

    const handleItemChange = (index, value) => {
        setItems((prev) => {
            const newItems = [...prev];
            newItems[index] = value;
            return newItems;
        });
    };
    const handleSave = () => {
        onValueChange(items);
        setOpen(false);
    };
    const handleDelete = (deletedIndex: number) => {
        setItems((prev) => {
            return prev.filter((item, i) => i !== deletedIndex);
        });
    };

    const handleAdd = () => {
        setItems((prev) => [...prev, ""]);
    };
    return (
        <>
            <Grid container direction="row" style={{ fontSize: "1rem" }}>
                <Grid>
                    <Button
                        variant="outlined"
                        style={{
                            backgroundColor: COLOR.GREEN_BUTTON,
                            color: COLOR.WHITE,
                            border: "none",
                        }}
                        onClick={() => setOpen(true)}
                    >
                        Edit
                    </Button>
                </Grid>
            </Grid>
            <Dialog
                open={open}
                onClose={handleSave}
                aria-labelledby={"edit-dialog-title"}
                aria-describedby={"edit-dialog-description"}
            >
                <DialogTitle>
                    <Typography
                        role="label"
                        style={{
                            paddingTop: 10,
                            fontWeight: "bold",
                            fontSize: "1.25rem",
                        }}
                    >
                        Edit {column.name}
                    </Typography>
                </DialogTitle>
                <DialogContent
                    style={{
                        display: "flex",
                        paddingTop: 10,
                        paddingBottom: 20,
                        flexDirection: "column",
                        alignItems: "center",
                        width: 500,
                    }}
                >
                    <Grid container>
                        {items.map((val, i) => {
                            return (
                                <Grid
                                    container
                                    direction="row"
                                    item
                                    style={{ marginBottom: 20 }}
                                >
                                    <TextField
                                        onChange={(e) =>
                                            handleItemChange(i, e.target.value)
                                        }
                                        variant="outlined"
                                        key={i}
                                        value={val}
                                        style={{ width: 440 }}
                                    />
                                    <IconButton
                                        style={{
                                            width: 55,
                                            color: COLOR.RED_WARNING,
                                        }}
                                        onClick={() => handleDelete(i)}
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </Grid>
                            );
                        })}
                    </Grid>
                </DialogContent>
                <DialogActions
                    style={{
                        display: "flex",
                        gap: 10,
                        justifyContent: "center",
                        width: 500,
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: 20,
                    }}
                >
                    <MyButton
                        onClick={handleAdd}
                        variant="outlined"
                        text={"Add"}
                    />
                    <MyButton
                        onClick={handleSave}
                        variant="contained"
                        text={"Save"}
                    />
                </DialogActions>
            </Dialog>
        </>
    );
};

const ArrayTypeProvider = (props: DataTypeProviderProps) => (
    <DataTypeProvider
        {...props}
        formatterComponent={ArrayFormatter}
        editorComponent={ArrayEditor}
    />
);
const getArrayColumns = getTypeColumnsFn((value) => Array.isArray(value));

function EditDataTable<T>({ rows, setRows }: EditDataTableProps<T>) {
    const rowsWithId = rows.map((row, i) => ({ id: i, ...row }));
    const columns = formatColumns(rowsWithId);

    const [addedRows, setAddedRows] = useState([]);
    const [rowChanges, setRowChanges] = useState({});
    const [editingRowIds, setEditingRowIds] = useState([]);

    const changeAddedRows = (value: Array<T>) => {
        if (value.length > 0) {
            if (Object.keys(value[0]).length === 0) {
                const first = { ...rows[0] };
                setAddedRows([first]);
            } else {
                setAddedRows([value[0]]);
            }
        } else {
            setAddedRows([]);
        }
    };

    const commitChanges = ({ added, changed, deleted }: ChangeSet) => {
        const withoutRowId = ({ id, ...rest }) => ({ ...rest });
        let changedRows;
        if (added) {
            const lastId =
                rowsWithId.length > 0
                    ? rowsWithId[rowsWithId.length - 1].id + 1
                    : 0;
            changedRows = [
                ...rowsWithId,
                ...added.map((row, index) => ({
                    id: lastId + index,
                    ...row,
                })),
            ];
        }
        if (changed) {
            changedRows = rowsWithId.map((row) =>
                changed[row.id] ? { ...row, ...changed[row.id] } : row
            );
        }
        if (deleted) {
            const deletedSet = new Set(deleted);
            changedRows = rowsWithId.filter((row) => !deletedSet.has(row.id));
        }
        setRows(changedRows.map(withoutRowId));
    };

    return (
        <Paper>
            <TableGrid rows={rowsWithId} columns={columns} getRowId={getRowId}>
                <BooleanTypeProvider for={getBooleanColumns(rowsWithId)} />
                <ArrayTypeProvider for={getArrayColumns(rowsWithId)} />
                <EditingState
                    editingRowIds={editingRowIds}
                    onEditingRowIdsChange={setEditingRowIds}
                    rowChanges={rowChanges}
                    onRowChangesChange={setRowChanges}
                    addedRows={addedRows}
                    onAddedRowsChange={changeAddedRows}
                    onCommitChanges={commitChanges}
                />
                <Table columnExtensions={formatColumnExtension(columns)} />
                <TableColumnVisibility defaultHiddenColumnNames={["id"]} />
                <TableHeaderRow
                    contentComponent={(props) => <StyledHeader {...props} />}
                />
                <TableEditRow />
                <TableEditColumn
                    showAddCommand={!addedRows.length}
                    showEditCommand
                    showDeleteCommand
                />
            </TableGrid>
        </Paper>
    );
}

export default EditDataTable;
