import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import { toggleModal } from "../../store/modal/actions";
import { getProfiles } from '../../store/profile/actions';
import { useHistory, useLocation } from "react-router-dom";
import withHeader from "../../presentation/withHeader";
import Dashboard from "./Dashboard";

type HomeProps = {};

const Home: React.FC<HomeProps> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { state } = useLocation<{ fromAuth?: boolean }>();

  const userid = useAppSelector(state => state.auth.wallet);

  useEffect(() => {
    dispatch(
      toggleModal({
        status: 'success',
        title: 'Welcome',
        subtitle: [''],
        button: 'Continue',
        CTAHandler: () => {},
      }),
    );
  }, []);

  return (
    <Dashboard />
  );
};

export default withHeader({
  title: 'Dashboard',
  margin: 60,
}, Home);
