
export const VERSION = '1.6.1';
export const defaultEnvOption = 'dev';
export const defaultEnv = {
    "webURL": "https://dev.one.aglive.com/",
    "tokenService": "https://dev.core.aglive.com",
    "offChainTokenService": "https://dev.core.aglive.com",
    "webhookService": "https://dev.webhook.aglive.com",
    "blockchainService": "https://dev.node.aglive.com:8200",
    "blockchainContractAddr": "0xB83e260DB052EE820A0254DdE910b05f968e14fb",
    "onboardingS3URL": "https://mvp-release-aglive.s3-ap-southeast-2.amazonaws.com/mobile/onboarding/onBoarding.json",
    "onboardingSVGS3URL": "https://mvp-release-aglive.s3-ap-southeast-2.amazonaws.com/mobile/onboarding/onBoardingSVG.json",
    "industryTypeS3URL": "https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/mobile/industryType/IndustryType.json",
    "authToken": "wvfPDXhcnOzUJnGDGnDSk/Alne3SO3d3qO/FqWRik/UjNmGOsk30Pxx3FMaNGJorGM2led7xTQ2J0ZyQe6Shvg=="
};

export const envS3URL = 'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/devUI/devUI_env.json';
export const GOOGLE_CLIENT_ID_V2='28378848610-0c5mp606en58cal5v4s5gm36sv1rh22u.apps.googleusercontent.com'
