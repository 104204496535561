import React, { useEffect, useState, useReducer } from "react";
import {
  Grid,
  Typography,
  Box,
  makeStyles,
  TextField,
} from "@material-ui/core";
import COLOR from "../../styled/colors";
import MyButton from "../../presentation/button";
import { PageHeader } from "../../presentation/withHeader";
import { fileUploader } from "../../store/plugin/actions";
import { useAppDispatch } from "../../utils/hooks";
import { useHistory } from "react-router-dom";
import { toggleModal } from "../../store/modal/actions";

const useStyle = makeStyles((theme) => ({
  button: {
    color: COLOR.GREEN_BUTTON,
    borderColor: COLOR.GREEN_BUTTON,
    marginRight: 50,
  },
  uploadField: {
    marginTop: 30,
    marginBottom: 30,
  },
  uploadButton: {
    marginTop: 5,
  },
  fileName: {
    height: 48,
    width: "100%",
    maxWidth: window.innerWidth / 2,
  },
  subTitle: {
    marginTop: theme.spacing(3),
    fontWeight: 600, // TODO
  },
}));
const UploadForm: React.FC<{}> = () => {
  const classes = useStyle();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [jsonFileName, setJsonFileName] = useState("");
  const [jsonFile, setJsonFile] = useState<File>();
  const displayJsonFileUpload = React.useRef<HTMLInputElement>(null);
  const jsonHandleClick = () => {
    displayJsonFileUpload.current?.click();
  };
  const uploadJsonFileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10485760) {
        dispatch(
          toggleModal({
              status: 'failed',
              title: "Internal Error",
              subtitle: "Upload image size more than 10mb ！",
              button: 'Close'
          }),
      )
      } else {
        const fileName = e.target.files[0].name;
        setJsonFile(e.target.files[0]);
        setJsonFileName(fileName);
      }
    }
  };
  const changeFileName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setJsonFileName(e.target.value);
  };
  const uploadFileFunction = async () => {
    const blob = jsonFile.slice(0, jsonFile.size, "file");
    const newFile = new File([blob], jsonFileName, { type: "file" });
    const response = await fileUploader(newFile, "mvp-utils");
    if (response[0].data) {
      dispatch(
        toggleModal({
          status: "success",
          title: "Successfully Completed",
          button: "Close",
          CTAHandler: () => history.goBack(),
        })
      );
    }
  };
  return (
    <PageHeader
      config={{
        title: "Upload Form",
        margin: 60,
        back: true,
      }}
    >
      <Grid container>
        <Typography variant="h6" className={classes.subTitle} role="label">
          {"Json File"}
        </Typography>
        <Grid
          container
          direction="row"
          className={classes.uploadField}
          spacing={3}
        >
          <Grid item className={classes.fileName} xs={9}>
            <TextField
              style={{ width: "100%" }}
              variant="outlined"
              value={jsonFileName}
              onChange={changeFileName}
              disabled={!jsonFileName}
            />
          </Grid>
          <Grid container item xs={3}>
            <MyButton
              text={"Upload File"}
              variant="outlined"
              onClick={jsonHandleClick}
              buttonClass={classes.uploadButton}
            />
            <input
              type="file"
              accept="file/*"
              data-cy="uploadFile"
              onChange={uploadJsonFileHandler}
              ref={displayJsonFileUpload}
              style={{ height: 0, width: 0 }}
            />
          </Grid>
        </Grid>
      </Grid>
      <MyButton
        text={"Sumbit"}
        disabled={!jsonFile}
        variant="contained"
        onClick={uploadFileFunction}
      />
    </PageHeader>
  );
};

export default UploadForm;
