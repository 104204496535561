import React, { useEffect } from "react";
import {
    IconButton,
    makeStyles,
    Box,
    Tooltip,
    Typography,
} from "@material-ui/core";
import { useHistory, useRouteMatch } from "react-router-dom";
import COLOR from "../../styled/colors";

import {
    fetchAlert,
    deleteAlert,
} from "../../store/alert/actions";
import { toggleModal, toggleModalOff } from '../../store/modal/actions';
import { fetchPlugin } from "../../store/plugin/actions";


import withHeader from "../../presentation/withHeader";
import MyButton from "../../presentation/button";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../utils/hooks";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from '@material-ui/icons/Edit';
import ConfirmButtons from "../../presentation/ConfirmButtons";

const useStyle = makeStyles((theme) => ({
    button: {
        color: COLOR.GREEN_BUTTON,
        borderColor: COLOR.GREEN_BUTTON,
        marginRight: 50,
    },
    tooltip: {
        color: COLOR.WHITE,
        fontSize: theme.typography.fontSize * 0.9
    }
}));

const Dashboard: React.FC = () => {

    const classes = useStyle();
    const history = useHistory();
    const dispatch = useDispatch();
    const { path } = useRouteMatch();
    const alerts = useAppSelector((state) => state.alert.alerts);
    const currentEnv = useAppSelector((state) => state.system.currentEnv);

    const onDelete = (e: any) => {
        const alertUuid = e.currentTarget.value;
        dispatch(toggleModal({
            status: 'warning',
            title: 'Delete Alert',
            subtitle: 'Confirm delete?',
            renderButton: <ConfirmButtons 
                confirmText='Delete'
                onCancel={() => {
                    dispatch(toggleModalOff())
                }}
                onConfirm={() => {
                    dispatch(
                        deleteAlert(alertUuid, () => {
                            dispatch(fetchAlert());
                        })
                    );
                }}
            />
        }))
    };

    useEffect(() => {
        dispatch(fetchAlert());
        dispatch(fetchPlugin());
    }, []);

    return (
        <Box>
            <Box>
                <MyButton
                    text={"Add"}
                    variant="outlined"
                    buttonClass={classes.button}
                    onClick={() => {
                        history.push(`${path}/create`);
                    }}
                />
            </Box>
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                {alerts.map((alert) => (
                    <ListItem
                        key={alert.uuid}
                        disableGutters
                        secondaryAction={
                            <Box>
                                <Tooltip title={<Typography className={classes.tooltip}>Delete</Typography>}
                                    placement="top"
                                    arrow
                                >
                                    <IconButton
                                        aria-label="delete"
                                        onClick={onDelete}
                                        value={alert.uuid}
                                    >
                                        <DeleteForeverIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={<Typography className={classes.tooltip}>Edit</Typography>}
                                    placement="top"
                                    arrow
                                >
                                    <IconButton
                                            aria-label="edit"
                                            onClick={() =>
                                                history.push({
                                                    pathname: `${path}/edit/${alert.uuid}`,
                                                    state: {alert: alert}
                                                })
                                            }
                                            color="primary"
                                            style={{
                                                color: COLOR.GREEN_HIGHLIGHT,
                                            }}
                                        >
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        }
                    >
                        <ListItemButton
                            onClick={() =>
                                history.push({
                                    pathname: `${path}/view/${alert.uuid}`,
                                    state: {alert: alert}
                                })
                            }
                        >
                            <ListItemText 
                                primary={alert.name}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

export default withHeader(
    {
        title: "Alert List",
        margin: 60,
    },
    Dashboard
);
