import { AppThunk } from '../../store/types';
import { SPINNER_TOGGLE_ON,SPINNER_TOGGLE_OFF } from '../spinner/types';
import { WebErrorType } from '../../utils/error';
import { callAPI, getAPI } from '../../utils/network';
import CONSTANT from '../../config/constant';
import { toggleModal } from '../modal/actions';


export function getBusinessInfo(businessId: string): AppThunk<Promise<void | any> > {
    return async (dispatch) => {
        let businessInfo = null;
        try{
            dispatch({type: SPINNER_TOGGLE_ON});

            const response = await callAPI({
                url: getAPI().POST.getActiveBusiness,
                method: 'POST',
                data: {
                  businessId: businessId,
                },
            });

            if(response.businesses && response.businesses.length > 0){
                businessInfo = response.businesses
            }
        } catch(e){
            const error = e as WebErrorType;
            dispatch(
                toggleModal({
                    status: 'failed',
                    title: error.title,
                    subtitle: error.message,
                }),
            );
        } finally {
            dispatch({type: SPINNER_TOGGLE_OFF});
        }
        return businessInfo;
       
    }
}

export function deleteBusinessPlugin(businessId: string, pluginId: string): AppThunk<Promise<void | any >> {
    return async (dispatch) => {
        
        if(!businessId) {
            return null
        }

        try{
            dispatch({type: SPINNER_TOGGLE_ON});

            const response = await callAPI({
                url: getAPI().DELETE.devRemoveBusinessPlugin,
                method: 'DELETE',
                data: {
                  businessId: businessId,
                  pluginId: pluginId,
                },
            });
          
            if(response.errorStatus === 0 ){
                dispatch(
                    toggleModal({
                    status: 'success',
                    //title: CONSTANT.EN_LABELS.invited,
                    title: 'Removed Business Plugin',
                    button: CONSTANT.EN_LABELS.close,
                    subtitle: `${response.message}\n`,
                    }),
                );
            } else {
                dispatch(
                    toggleModal({
                    status: 'failed',
                    title: 'Fail to remove business plugin',
                    button: CONSTANT.EN_LABELS.close,
                    subtitle: `Error Occured`,
                    }),
                );
            }

        } catch(e){
            const error = e as WebErrorType;
            dispatch(
                toggleModal({
                    status: 'failed',
                    title: error.title,
                    subtitle: error.message,
                }),
            );
        } finally {
            dispatch({type: SPINNER_TOGGLE_OFF});
        }
        return {errorStatus:-1};
       
    }
}

export function getAllBusinessPluginsInfo(): AppThunk<Promise<void | any> > {
    return async (dispatch) => {
        let businessPluginInfo = null;
        try{
            dispatch({type: SPINNER_TOGGLE_ON});

            const response = await callAPI({
                url: getAPI().GET.getAllBusinessPluginsInfo,
                method: 'GET',
            });

            if(response.data && response.data.length > 0){
                businessPluginInfo = response.data
            }
        } catch(e){
            const error = e as WebErrorType;
            dispatch(
                toggleModal({
                    status: 'failed',
                    title: error.title,
                    subtitle: error.message,
                }),
            );
        } finally {
            dispatch({type: SPINNER_TOGGLE_OFF});
        }
        return businessPluginInfo;
       
    }
}
