import produce from 'immer';
import {
  POST_USER_PROFILE_DATA,
  ProfileTypes,
  profileState,
  POST_BUSINESS_PROFILE_DATA,
  DROP_PROFILE_DATA,
} from './types';

const initialState: profileState = {
  userProfileData: {
    externalIds: {userId: null},
    id: null,
    image: null,
    name: null,
    role: null,
    mobileNumber: null,
    emailAddress: null,
    signature: null,
    address: null,
    businessId: null,
    country: null,
  },
  businessProfileData: {
    businessScope: null,
    businessType: null,
    companyName: null,
    companyNumber: null,
    brandDescription: null,
    industryType: null,
    imageUri: null,
    location: [
      {
        locationNickname: null,
        locationType: null,
        PICAddress: null,
        address: null,
        postcode: null,
        town: null,
        state: null,
        LPAUserID: null,
        LPAPassword: null,
        NLISUserID: null,
        NLISPassword: null,
        geofence: null,
      },
    ],
  },
};

function profileReducer(
  state = initialState,
  action: ProfileTypes,
): profileState {
  switch (action.type) {
    case POST_USER_PROFILE_DATA:
      return produce(state, (draft) => {
        draft.userProfileData = action.payload;
      });
    case POST_BUSINESS_PROFILE_DATA:
      return produce(state, (draft) => {
        draft.businessProfileData = action.payload;
      });
    case DROP_PROFILE_DATA:
      return initialState;
    default:
      return state;
  }
}

export default profileReducer;
