import React from 'react';
import { PageHeader } from '../../../presentation/withHeader';
import DataTable from './NestedDataTable';
import { toColumnExtensions } from './tableHeader';
import BlockchainSearchForm, {
  BlockchainSearchFormData,
} from './blockchainSearchForm';
import { callAPI, getAPI } from '../../../utils/network';
import { WebErrorType } from '../../../utils/error';
import { toggleModal } from '../../../store/modal/actions';
import { useAppDispatch } from '../../../utils/hooks';
import {
  SPINNER_TOGGLE_OFF,
  SPINNER_TOGGLE_ON,
} from '../../../store/spinner/types';
import { web3Service } from '../../../service/web3/web3Service';
import { WebError } from '../../../utils/error';
import { Box, Grid, Typography } from '@material-ui/core';

type BlockchainResult = {
  assetHash: string;
  activityHash: Array<{ [hash: string]: string }>;
};

const BlockchainResultColumns = [{ name: 'hash', title: 'Activity Hash' }];
const BlockchainResultColumnExtensions = [{ columnName: 'hash', width: 800 }];

const DbDataColumns = [
  { name: 'tokenId', title: 'Token ID', width: 300 },
  { name: 'type', title: 'Type', width: 150 },
  { name: 'status', title: 'Status', width: 150 },
];
const DbDataColumnExtensions = toColumnExtensions(DbDataColumns);

const searchByTokenId = async (
  tokenId: string
): Promise<{ blockchainResult: BlockchainResult; dbResult: Array<any> }> => {
  const assetResponse = await web3Service.getAssetByToken(tokenId);
  const blockchainResult = {
    assetHash: assetResponse[0],
    activityHash: assetResponse[1]?.map((hash) => ({ hash })),
  };
  try {
    const response = await callAPI({
      url: getAPI().POST.getTokenByTokenIdsSkipUser,
      method: 'POST',
      data: {
        latestDetails: true,
        status: [],
        tokenIds: [tokenId],
        activityType: [],
      },
    });
    return {
      blockchainResult,
      dbResult: response,
    };
  } catch (e) {
    return {
      blockchainResult,
      dbResult: [],
    };
  }
};

const searchByBlockchainHash = async (
  blockchainHash: string
): Promise<{ blockchainResult: BlockchainResult; dbResult: Array<any> }> => {
  const response = await callAPI({
    url: getAPI().POST.getTokenbyBlockchainHash,
    method: 'POST',
    data: {
      latestDetails: true,
      status: [],
      type: [],
      activityType: [],
      blockchainHash: [blockchainHash],
    },
  });
  console.log('searchByBlockchainHash', response);
  if (response.length === 0) {
    throw new WebError('REQUEST_ERROR', 'Hash is not in database.');
  }
  if (!response[0].tokenId || response[0].tokenId?.length === 0) {
    throw new WebError('REQUEST_ERROR', 'Blockchain token id does not exist');
  }
  const assetResponse = await web3Service.getAssetByToken(response[0].tokenId);
  return {
    blockchainResult: {
      assetHash: assetResponse[0],
      activityHash: assetResponse[1]?.map((hash) => ({ hash })),
    },
    dbResult: response,
  };
};

const searchByExternalId = async (
  externalId: string,
  value: string
): Promise<{ blockchainResult: BlockchainResult; dbResult: Array<any> }> => {
  const response = await callAPI({
    url: getAPI().POST.getTokenbyExternalId,
    method: 'POST',
    data: {
      latestDetails: true,
      status: [],
      activityType: [],
      externalIds: [{ [externalId]: value }],
    },
  });
  console.log('searchByExteralId', response);
  if (response.length === 0) {
    throw new WebError('REQUEST_ERROR', 'External id is not in database.');
  }
  if (!response[0].tokenId || response[0].tokenId?.length === 0) {
    throw new WebError('REQUEST_ERROR', 'Blockchain token id does not exist');
  }
  const assetResponse = await web3Service.getAssetByToken(response[0].tokenId);
  return {
    blockchainResult: {
      assetHash: assetResponse[0],
      activityHash: assetResponse[1]?.map((hash) => ({ hash })),
    },
    dbResult: response,
  };
};

const BlockchainSearch = () => {
  const dispatch = useAppDispatch();
  const [dbTokens, setDbTokens] = React.useState([]);
  const [blockchainResult, setBlockchainResult] =
    React.useState<BlockchainResult | null>(null);
  const handleSearchToken = async ({
    searchText,
    searchBy,
    externalId,
  }: BlockchainSearchFormData) => {
    try {
      dispatch({ type: SPINNER_TOGGLE_ON });
      setDbTokens([]);
      setBlockchainResult(null);

      let params: string[] = [];
      let searchFunc: (...args: string[]) => Promise<{
        blockchainResult: BlockchainResult;
        dbResult: Array<any>;
      }> = null;

      if (searchBy === 'Token ID') {
        params = [searchText];
        searchFunc = searchByTokenId;
      } else if (searchBy === 'External ID') {
        if (externalId.trim().length === 0) {
          throw new WebError('INVALID_INPUT', 'external id field is empty');
        }
        params = [externalId, searchText];
        searchFunc = searchByExternalId;
      } else if (searchBy === 'Blockchain Hash') {
        if (searchText.trim().length === 0) {
          throw new WebError('INVALID_INPUT', 'blockchain hash field is empty');
        }
        params = [searchText];
        searchFunc = searchByBlockchainHash;
      }

      if (searchFunc) {
        const { blockchainResult, dbResult } = await searchFunc(...params);
        setBlockchainResult(blockchainResult);
        setDbTokens(dbResult);
      } else {
        throw new WebError('INVALID_INPUT', 'No search function selected');
      }
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Close',
        })
      );
      setDbTokens([]);
      setBlockchainResult(null);
    } finally {
      dispatch({ type: SPINNER_TOGGLE_OFF });
    }
  };

  return (
    <PageHeader
      config={{
        title: 'Search in Blockchain',
        margin: 1,
      }}
    >
      <BlockchainSearchForm onSubmit={handleSearchToken} />
      <Grid
        container
        direction='column'
        component={Box}
        style={{
          marginTop: 50,
        }}
      >
        <Typography variant='h3' style={{ marginRight: 5, marginBottom: 20 }}>
          Database
        </Typography>
        <DataTable
          rows={dbTokens}
          columns={DbDataColumns}
          columnExtensions={DbDataColumnExtensions}
        />
      </Grid>

      {blockchainResult && (
        <Grid
          container
          direction='column'
          component={Box}
          style={{
            marginTop: 50,
          }}
        >
          <Grid style={{ marginBottom: 20 }}>
            <Typography variant='h3'>Blockchain</Typography>
          </Grid>
          <Grid
            container
            item
            direction='row'
            component={Box}
            style={{
              marginBottom: 10,
            }}
          >
            <Typography
              variant='body1'
              style={{ marginRight: 20, fontWeight: 'bold' }}
            >
              Asset Hash
            </Typography>
            <Typography variant='body1'>
              {blockchainResult.assetHash}
            </Typography>
          </Grid>
          <DataTable
            rows={blockchainResult.activityHash}
            columns={BlockchainResultColumns}
            columnExtensions={BlockchainResultColumnExtensions}
            showDetail={false}
            virtual={blockchainResult.activityHash.length > 10}
          />
        </Grid>
      )}
    </PageHeader>
  );
};

export default BlockchainSearch;
