import React, { useState, } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
    FormControl,
    TextField,
    Typography,
    makeStyles,
    Box,
    Checkbox,
    Grid,
} from "@material-ui/core";
import COLOR from "../../styled/colors";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import MuiIconButton, { IconButtonProps } from "@mui/material/IconButton";
import AddIcon from "@material-ui/icons/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormHelperText from '@mui/material/FormHelperText';
import { useAppSelector } from "../../utils/hooks";
import MyButton from "../../presentation/button";
import ClearIcon from "@material-ui/icons/Clear";

import withHeader from "../../presentation/withHeader";

import { createAlert, updateAlert } from "../../store/alert/actions";
import { withStyles } from "@material-ui/core/styles";
import { AlertModel } from "@aglive/data-model";

const useStyle = makeStyles((theme) => ({
    button: {
        color: COLOR.GREEN_BUTTON,
        borderColor: COLOR.GREEN_BUTTON,
        marginRight: 50,
    },
}));

const CreateAlert: React.FC = () => {
    const classes = useStyle();
    const dispatch = useDispatch();
    const history = useHistory();
    const loc = useLocation<{alert: AlertModel.Alert}>();

    const pluginsArray = useAppSelector((state) => state.plugin.plugins);
    const [alert, setAlert] = useState<Pick<AlertModel.Alert, 'name' | 'find' | 'pluginId' | 'rowFormat' | 'rowFormatType' | 'sms' | 'email' | 'format'>>({
        name: '',
        find: '',
        pluginId: [],
        format: '',
        rowFormatType: 'list',
        sms: false,
        email: true,
        rowFormat: [],
        ...(loc.state?.alert ?? {})
    })

    const [formInputs, setFormInputs] = useState([
        {name: 'name', label: 'Alert Name', err:''},
        {name: 'find', label: 'Find', err:''},
        {name: 'format', label: 'Format', err:''},
        {name: 'rowFormatType', label: 'Row Format Type', type: 'radio', list: [{label: 'List', value: 'list'}, {label: 'Table', value: 'table'}], err:''},
        {name: 'rowFormat', label: 'Row Format', err:''},
        {name: 'pluginId', label: 'Plugin', type: 'select', list: pluginsArray.map((plg) => ({label: plg.name, value: plg.uuid})), err:''},
    ])

    const { uuid } = useParams<{ uuid?: string }>();

    //general
    const formValidation = () => {
        let validate = true;
        let newFormInputs = [...formInputs]
        formInputs?.map((item, index) => {
            if ( item.name != 'rowFormat' && (alert[item.name] == '' || alert[item.name].length == 0)) {
                validate = false;
                newFormInputs[index] = {...newFormInputs[index], err: item.label+' cannot be empty'}
            }else {
                newFormInputs[index] = {...newFormInputs[index], err: ''}
            }
        })
        setFormInputs(() => {
            return newFormInputs
        })
        return validate;
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAlert((prevState) => {
            let newAlert = {...prevState}
            if (e.target.type !== 'radio' || e.target.checked) {
                newAlert[e.target.name] = e.target.value
            }
            return newAlert
        })
    }
    const handleSelectChange = (e: SelectChangeEvent) => {
        setAlert((prevState) => {
            let newAlert = {...prevState}
            if (Array.isArray(newAlert[e.target.name])) {
                newAlert[e.target.name] = [e.target.value]
            } else {
                newAlert[e.target.name] = e.target.value
            }
            return newAlert
        })
    }

    const handleAddRowFormat = () => {
        setAlert((prevState) => {
            return {...prevState, rowFormat: [...prevState.rowFormat, {
                name: '',
                value: '',
            }]}
        });
    }

    const handleRemoveRowFormat = (index: number) => () => {
        setAlert((prevState) => {
            const newRowFormat = prevState.rowFormat
            newRowFormat.splice(index, 1)
            return {...prevState, rowFormat: newRowFormat}
        });
    }

    const handleRowFormatChange = (type: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const index = parseInt(
            e.target.parentElement.parentElement.getAttribute("data-index")
        );

        setAlert((prevState) => {
            const newRowFormat = [...prevState.rowFormat];
            newRowFormat[index][type] = e.target.value;
            return {...prevState, rowFormat: newRowFormat}
        })
    };

    const onSubmit = () => {
        if(!formValidation()) return;
        const payload = {
            ...alert,
            uuid,
        };

        dispatch(
            !!loc.state?.alert
            ?
                updateAlert(payload, () => {
                    history.goBack();
                })
            :
                createAlert(payload, () => {
                    history.goBack();
                })
        );

        console.log(payload);
    };

    return (
        <Box display={'flex'} flexDirection={'column'} gridRowGap={'1em'}>
            {formInputs.map((fi) => (
                <Box sx={{ marginTop: 10, marginBottom: 10 }}>
                    <Typography variant="h2" style={{fontSize: '1.5rem'}} gutterBottom>
                        {fi.label}
                        {fi.name === 'rowFormat' &&
                            <MuiIconButton
                                aria-label="add"
                                onClick={handleAddRowFormat}
                                sx={{
                                    backgroundColor: "gray",
                                    marginLeft: 3,
                                    marginRight: 3,
                                }}
                            >
                                <AddIcon />
                            </MuiIconButton>
                        }
                    </Typography>
                    {fi.name === 'rowFormat' ? 
                        <Box sx={{ marginTop: 10, marginBottom: 10 }}>
                            <TableContainer component={Box}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    {alert.rowFormat?.length !== 0 && (
                                        <TableHead>
                                            <TableRow sx={{ backgroundColor: COLOR.GRAY_SOLID }}>
                                                <TableCell sx={{ fontWeight: 'bold' }}>No</TableCell>
                                                {alert.rowFormatType === 'table' && <TableCell sx={{ fontWeight: 'bold' }} align="left">Field Name</TableCell>}
                                                <TableCell sx={{ fontWeight: 'bold' }} align="left">Field Value</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                    )}
                                    <TableBody>
                                        {alert.rowFormat?.map((rowformat, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{
                                                    "&:last-child td, &:last-child th": {
                                                        border: 0,
                                                    },
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {index + 1}
                                                </TableCell>
                                                {alert.rowFormatType === 'table' && 
                                                    <TableCell align="center">
                                                        <FormControl variant="filled" fullWidth>
                                                            <TextField
                                                                variant="outlined"
                                                                type="text"
                                                                value={rowformat.name}
                                                                data-index={index}
                                                                onChange={handleRowFormatChange('name')}
                                                                fullWidth
                                                            />
                                                        </FormControl>
                                                    </TableCell>
                                                }
                                                <TableCell align="center">
                                                    <FormControl variant="filled" fullWidth>
                                                        <TextField
                                                            variant="outlined"
                                                            type="text"
                                                            value={rowformat.value}
                                                            data-index={index}
                                                            onChange={handleRowFormatChange('value')}
                                                            fullWidth
                                                        />
                                                    </FormControl>
                                                </TableCell>
                                                <TableCell>
                                                    <MuiIconButton
                                                        role='button'
                                                        type='button'
                                                        aria-labelledby='deleteToken'
                                                        onClick={handleRemoveRowFormat(index)}
                                                        color='error'
                                                        sx={{
                                                            marginLeft: 3,
                                                            marginRight: 3,
                                                        }}
                                                    >
                                                        <ClearIcon />
                                                    </MuiIconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    : fi.type === 'select' ?
                        <FormControl fullWidth error={!!fi.err}>
                            <Select
                                defaultValue={Array.isArray(alert[fi.name]) ? alert[fi.name][0] : alert[fi.name]}
                                label={fi.label}
                                name={fi.name}
                                onChange={handleSelectChange}
                                error={!!fi.err}
                            >
                                {fi.list.map((ls) => (
                                    <MenuItem key={ls.value} value={ls.value}>
                                        {ls.label}
                                    </MenuItem>
                                ))}
                            </Select>
                            {fi.err != '' &&<FormHelperText sx={{ color: 'red'}}>{fi.err}</FormHelperText>}
                        </FormControl>
                    : fi.type === 'radio' ? 
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            value={alert.rowFormatType}
                            onChange={handleInputChange}
                        >
                            <Grid container>
                                {fi.list.map(ls => 
                                    <Grid item xs={3}>
                                        <FormControlLabel name={fi.name} value={ls.value} control={<Radio />} label={ls.label} />
                                    </Grid>
                                )}
                            </Grid>
                        </RadioGroup>
                    : <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        name={fi.name}
                        value={alert[fi.name]}
                        onChange={handleInputChange}
                        error={!!fi.err}
                        helperText={fi.err != '' ? fi.err : ''}
                    />}
                </Box>
            ))}
            <Grid container>
                <Grid item xs={6}>
                    <FormControl>
                        <Typography variant="h2" style={{fontSize: '1.5rem'}}>
                            Email
                            <Checkbox
                                color="primary"
                                checked={alert.email}
                                name={'email'}
                                value={true}
                                onChange={handleInputChange}
                                disabled={true}
                            />  
                        </Typography>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl>
                        <Typography variant="h2" style={{fontSize: '1.5rem'}}>
                            Sms
                            <Checkbox
                                color="primary"
                                checked={alert.sms}
                                name={'sms'}
                                value={true}
                                onChange={handleInputChange}
                                disabled={true}
                            />  
                        </Typography>
                    </FormControl>
                </Grid>
            </Grid>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <MyButton
                    text={!!loc.state?.alert ? "Save" : "Create"}
                    variant="outlined"
                    buttonClass={classes.button}
                    onClick={onSubmit}
                />
            </Box>
        </Box>
    );
};

export default withHeader(
    {
        title: "Alert",
        margin: 60,
        back: true,
    },
    CreateAlert
);
