import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../utils/hooks";
import { useHistory } from "react-router-dom";
import { googleLogin, googleLoginV2 } from "../../store/auth/actions";
import {
  Container,
  LoginBox,
  LeftSection,
  LoginSteps,
  RightSection,
  ChangeDev,
} from "./styles";
import { Header2, Paragraph } from "../../presentation/word";
import "./styles.css";
import Header from "../../presentation/header";
import Footer from "../../presentation/footer";
import CONSTANT from "../../config/constant";
import COLOR from "../../styled/colors";

import { toggleModal, toggleModalOff } from "../../store/modal/actions";
import { WEB_ERROR } from "../../utils/error";
import GoogleLogin from "react-google-login";
import MyButton from "../../presentation/button";
import { makeStyles } from "@material-ui/core";
import agliveGif from "../../img/AgliveGif.gif";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { getEnvList, changeEnv } from "../../store/environment/actions";
import InputLabel from "@material-ui/core/InputLabel";
import { useGoogleLogin } from '@react-oauth/google';

type Props = {};
const useStyle = makeStyles((theme) => ({
  button: {
    width: 267,
    height: 56,
    marginLeft: 5,
  },
  formControl: {
    width: "50%",
    maxWidth: 480,
  },
  select: {
    height: 48,
    borderColor: COLOR.GRAY_BORDER,
  },
}));
const Auth: React.FC<Props> = (_) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const classes = useStyle();
  const walletLoginId = useAppSelector((state) => state.auth.walletLoginId);
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  const envList = useAppSelector((state) => state.system.envList);
  const envOptions = Object.keys(envList);
  const [selectedEnv, setSelectedEnv] = useState("dev");
  const handleLogin = async (googleData) => {
    if (googleData.tokenId) {
      dispatch(googleLogin(googleData.tokenId));
    } else {
      console.log("google data raw", googleData);
    }
  };

  const googleIdentityLoginSSO = useGoogleLogin({
    onSuccess: codeResponse => {
      //console.log(codeResponse);
      dispatch(googleLoginV2(codeResponse.code));
    },
    flow: 'auth-code',
  });

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    setSelectedEnv(String(event.target.value));
    dispatch(changeEnv(String(event.target.value)));
  };
  useEffect(() => {
    if (isLoggedIn) {
      // fromAuth is used to determine whether to pop up the greeting modal upon login
      // Please refer to home/index.tsx for more information
      history.push({
        pathname: "/private",
        state: { fromAuth: true },
      });
    }
  }, [history, isLoggedIn]);
  useEffect(() => {
    dispatch(getEnvList());
    dispatch(changeEnv(selectedEnv));
  }, []);

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Header />
        <Container>
          <LoginBox>
            <LeftSection>
              <div style={{ marginTop: 150 }}>
                <Header2 pt={"72px"}>Dev UI Login</Header2>
              </div>
              <LoginSteps>
                
                <GoogleLogin
                  clientId={CONSTANT.GOOGLE_CLIENT_ID}
                  buttonText="Log in with Google"
                  onSuccess={handleLogin}
                  onFailure={handleLogin}
                  cookiePolicy={"single_host_origin"}
                  uxMode="popup"
                  render={(renderProps) => (
                    <MyButton
                      text={"Log in with Google"}
                      variant="contained"
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                      buttonClass={classes.button}
                    />
                  )}
                />
               
                {/* <MyButton
                      text={"Log in with Google"}
                      variant="contained"
                      onClick={() => googleIdentityLoginSSO()}
                      buttonClass={classes.button}
                /> */}
                <ChangeDev>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    size="small"
                  >
                    <InputLabel htmlFor="my-input">Select ENV</InputLabel>
                    <Select
                      labelId="asset-induction-select-location"
                      className={classes.select}
                      value={selectedEnv}
                      onChange={handleChange}
                      label="Please select"
                    >
                      {envOptions.map((env) => (
                        <MenuItem value={env} key={env}>
                          {env}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </ChangeDev>
              </LoginSteps>
            </LeftSection>
            <RightSection>
              <div
                style={{
                  textAlign: "center",
                  height: "75%",
                  width: "100%",
                  marginTop: 50,
                }}
              >
                <img
                  src={agliveGif}
                  style={{ width: "80%", height: "100%" }}
                  alt="Null"
                />
              </div>
              <Paragraph
                color={COLOR.BLACK_BG}
                fontWeight={700}
                style={{ textAlign: "center" }}
              >
                Have better ideas of what to put here?
              </Paragraph>
              <Paragraph
                color={COLOR.BLACK_BG}
                fontWeight={700}
                style={{ textAlign: "center" }}
              >
                Send in your request to dev channel
              </Paragraph>
            </RightSection>
          </LoginBox>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default Auth;
