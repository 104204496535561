import React, { useState, useReducer, useEffect } from "react";
import { PageHeader } from "../../../presentation/withHeader";
import { useAppDispatch } from "../../../utils/hooks";
import {
  Grid as TableGrid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  PagingPanel,
} from "@devexpress/dx-react-grid-material-ui";
import {
  FilteringState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
} from "@devexpress/dx-react-grid";
import MyButton from "../../../presentation/button";
import { getAllBusinessPluginsInfo } from "../../../store/businessAccountPlugin/actions";

const tableFilterOperations = {
  contains: 'contains', 
  notContains: 'notContains', 
  startsWith: 'startsWith', 
  endsWith: 'endsWith', 
  equal: 'equal', 
  notEqual: 'notEqual', 
  greaterThan: 'greaterThan', 
  greaterThanOrEqual: 'greaterThanOrEqual',
  lessThan: 'lessThan', 
  lessThanOrEqual: 'lessThanOrEqual'
}

const tableColumnsBusinessPlugin = [
  { name: "businessName", title: "Business Name", width: 200 },
  { name: "businessId", title: "Business Id", width: 350 },
  { name: "pluginName", title: "Plugin Name", width: 200 },
  { name: "pluginId", title: "Plugin Id", width: 350 },
  { name: "pluginStatus", title: "Plugin Status", width: 350 },
]

const BusinessPluginsInfo: React.FC<{}> = () => {
  const [s_filterState, s_setFilterState] = useState([]);

  const [s_dataListTable, s_setDataListTable] = useState([]);
  const [s_tableColumn, s_setTableColumn] = useState(
    tableColumnsBusinessPlugin
  );

  const dispatch = useAppDispatch();

  const columnWidths = (
    cols: Array<{ name: string; title: string; width: number }>
  ) =>
    cols?.map((dt) => ({
      columnName: dt.name,
      width: dt.width,
    }));

  async function generateTable() {
    await dispatch(getAllBusinessPluginsInfo()).then(
      (data: any) => {
        s_setDataListTable(data)
      }
    )
  }

  return (
    <PageHeader
      config={{
        title: "Business Plugins Info",
        margin: 1,
        back: true,
      }}
    >
      <br></br>

      <MyButton
          text={"Generate Table"}
          variant="contained"
          onClick={() => {
            generateTable();
          }}
      />

      {/** TABLE SECTION **/}
      {(s_dataListTable?.length > 0) && (
        <TableGrid columns={s_tableColumn} rows={s_dataListTable}>
          <PagingState defaultCurrentPage={0} pageSize={20} />
          <FilteringState
            defaultFilters={[]}
            filters={s_filterState}
            onFiltersChange={(filter) => {
              for(let x = 0 ; x < filter.length; x++){
                if(filter[x]?.columnName == 'pluginStatus'){
                  filter[x].operation = tableFilterOperations.equal
                }
              }
              s_setFilterState(filter);
            }}
          />
          <IntegratedFiltering />
          <IntegratedPaging />
          <Table columnExtensions={columnWidths(s_tableColumn)} />
          <TableHeaderRow />
          <TableFilterRow />
          <PagingPanel />
        </TableGrid>
      )}
    </PageHeader>
  );
};

export default BusinessPluginsInfo;
