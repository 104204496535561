import produce from 'immer';
import {
    GET_TOKEN_DETAIL_LIST,
    typeDeleteState,
    UPDATE_TOKEN_LIST,
} from './types';

const initialState: typeDeleteState = {
    tokenList: [],
    tokenDataDetails: [],
};


export default function deleteTokenReducer(state = initialState, action: any): typeDeleteState {
    switch (action.type) {
        case UPDATE_TOKEN_LIST:
            return produce(state, (draft) => {
                draft.tokenList = action.payload;
            });
        case GET_TOKEN_DETAIL_LIST:
            return produce(state, (draft) => {
                draft.tokenDataDetails = action.payload;
            });
        default:
            return state;
    }
}
