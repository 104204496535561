import produce from 'immer';
import {sessionStateType,SessionType,GET_SESSION_DATA, GET_PLUGINS_AVAILABLE, GET_SINGLE_SESSION, GET_TEMPLATES} from './type';

const initialState:sessionStateType ={
    templates: [],
    session: {}, 
    sessions:[],
    pages: 0,
    count: 0,
    plugins: []
}
function sessionReducer(
    state= initialState,
    action: any,
):sessionStateType{
    switch(action.type){
        case GET_SESSION_DATA:
            return produce(state,(draft)=>{
                draft.sessions=action.payload.sessions
                draft.count=action.payload.count
                draft.pages=action.payload.pages
            })
        case GET_PLUGINS_AVAILABLE:
            return produce(state,(draft)=>{
                draft.plugins=action.payload
            })
        case GET_SINGLE_SESSION:
            return produce(state,(draft)=>{
                draft.session=action.payload
            })
        case GET_TEMPLATES:
            return produce(state,(draft)=>{
                draft.templates=action.payload
            })
        default:
            return state;
    }
}


export default sessionReducer;