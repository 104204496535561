import React, { useCallback, useReducer, useState } from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  TextField,
  Box,
  MuiThemeProvider,
} from '@material-ui/core';
import { styled } from '@mui/material/styles';
import { createTheme } from '@material-ui/core/styles';
import {
  Table,
  TableHeaderRow,
  TableSelection,
  Grid as TableGrid,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import {
  SelectionState,
  PagingState,
  IntegratedSelection,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import COLOR from '../../../styled/colors';
import MyButton from '../../../presentation/button';
import { PageHeader } from '../../../presentation/withHeader';
import { useAppDispatch } from '../../../utils/hooks';
import {
  SPINNER_TOGGLE_OFF,
  SPINNER_TOGGLE_ON,
} from '../../../store/spinner/types';
import { callAPI, getAPI } from '../../../utils/network';
import { toggleModal } from '../../../store/modal/actions';
import { WebErrorType } from '../../../utils/error';
import { changeUserStatus } from '../../../store/monitor/actions';
import moment from 'moment';

const useStyle = makeStyles((theme) => ({
  button: {
    color: COLOR.GREEN_BUTTON,
    borderColor: COLOR.GREEN_BUTTON,
    marginRight: 50,
  },
  textBox: {
    marginTop: 10,
    marginBottom: 20,
  },
  subTitle: {
    marginTop: theme.spacing(3),
    fontWeight: 600,
  },
  activate: {
    width: 200,
    color: COLOR.WHITE,
    backgroundColor: COLOR.GREEN_BUTTON,
    borderColor: COLOR.GREEN_BUTTON,
    '&:hover': {
      backgroundColor: COLOR.GREEN_BUTTON,
      borderColor: COLOR.GREEN_BUTTON,
      boxShadow: 'none',
    },
  },
  deactivate: {
    width: 200,
    color: COLOR.WHITE,
    backgroundColor: COLOR.AA_RED,
    borderColor: COLOR.AA_RED,
    marginLeft: 20,
    '&:hover': {
      backgroundColor: COLOR.AA_RED,
      borderColor: COLOR.AA_RED,
      boxShadow: 'none',
    },
  },
}));

const initialState = {
  businessId: '',
  currentPage: 0,
  pageSize: 10,
  pluginList: [],
};

type Action =
  | { type: 'change/businessId'; businessId: string }
  | { type: 'change/page'; currentPage: number }
  | { type: 'change/pageSize'; pageSize: number }
  | { type: 'change/pluginList'; pluginList: Array<any> };

const reducer = (
  prevState: typeof initialState,
  action: Action
): typeof initialState => {
  const { type, ...actionData } = action;
  switch (action.type) {
    default:
      return { ...prevState, ...actionData };
  }
};

const StyledTable = styled(Table.Table)(({ theme }) => ({
  backgroundColor: COLOR.WHITE,
}));
const StyledPaging = styled(PagingPanel.Container)(({ theme }) => ({
  marginTop: 20,
}));
const StyledHeader = styled(TableHeaderRow.Content)(({ theme }) => ({
  fontWeight: 700,
}));

const HeaderComponentBase = ({ classes, ...props }) => (
  <Table.TableHead {...props} style={{ backgroundColor: COLOR.GRAY_SOLID }} />
);

export const TableComponent = (props) => <StyledTable {...props} />;
export const PagingComponent = (props) => <StyledPaging {...props} />;
export const HeaderComponent = (props) => <StyledHeader {...props} />;

const PluginActive: React.FC<{}> = () => {
  const classes = useStyle();
  const [state, localDispatch] = useReducer(reducer, initialState);
  const dispatch = useAppDispatch();

  const handleChangeName = (value: string) => {
    localDispatch({
      type: 'change/businessId',
      businessId: value,
    });
    localDispatch({
      type: 'change/pluginList',
      pluginList: [],
    });
  };

  const columnWidths = (
    cols: Array<{ name: string; title: string; width: number }>
  ) =>
    cols.map((dt) => ({
      columnName: dt.name,
      width: dt.width,
    }));

  const changePage = useCallback((pageNum: number) => {
    localDispatch({ type: 'change/page', currentPage: pageNum });
  }, []);
  const changePageSize = useCallback((size: number) => {
    localDispatch({ type: 'change/pageSize', pageSize: size });
  }, []);

  const columns = [
    { name: 'id', title: 'No', width: 60 },
    { name: 'pluginName', title: 'Plugin Name', width: 200 },
    { name: 'activationDate', title: 'Activation Date', width: 200 },
    { name: 'deactivationDate', title: 'Deactivation Date', width: 200 },
    { name: 'status', title: 'Status', width: 180 },
  ];

  const onGenerate = async () => {
    try {
      dispatch({ type: SPINNER_TOGGLE_ON });

      const response = await callAPI({
        url: getAPI().POST.getActiveBusiness,
        method: 'POST',
        data: {
          businessId: state.businessId,
        },
      });

      const activeBusiness = [];
      response.businesses?.forEach((item) => {
        item.plugins &&
          item.plugins
            ?.sort((a, b) => (a[0] > b[0] ? 1 : -1))
            ?.forEach((item, index) => {
              activeBusiness.push({
                id: index + 1,
                pluginName: item.name,
                status: item.status.replace(/^./, item.status[0].toUpperCase()),
                activationDate:
                  item.activationDate &&
                  moment(item.activationDate).format('YYYY-MM-DD'),
                deactivationDate:
                  item.deactivationDate &&
                  moment(item.deactivationDate).format('YYYY-MM-DD'),
              });
            });
      });

      if (activeBusiness.length > 0) {
        localDispatch({
          type: 'change/pluginList',
          pluginList: activeBusiness,
        });
      } else {
        dispatch(
          toggleModal({
            status: 'warning',
            title: 'No Plugin/Business Existing',
            button: 'Close',
          })
        );
      }
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Close',
        })
      );
    } finally {
      dispatch({ type: SPINNER_TOGGLE_OFF });
    }
  };

  return (
    <PageHeader
      config={{
        title: 'Plugin Active',
        margin: 1,
      }}
    >
      <Grid container>
        <Typography variant='h6' className={classes.subTitle} role='label'>
          {'Business ID'}
        </Typography>
        <Grid container direction='row' className={classes.textBox}>
          <Grid item xs={9}>
            <TextField
              style={{ width: '56%' }}
              variant='outlined'
              value={state.businessId}
              onChange={(e) => handleChangeName(e.target.value)}
            />
          </Grid>
        </Grid>
        <MyButton
          text={'Search'}
          disabled={!state.businessId}
          variant='contained'
          onClick={() => {
            onGenerate();
          }}
        />
      </Grid>

      <Grid style={{ marginTop: 20 }}>
        {state.pluginList.length > 0 && (
          <Box
            p={'40px 15px 30px 15px'}
            ml={5}
            style={{
              marginLeft: 0,
              backgroundColor: COLOR.GRAY_SOLID,
            }}
          >
            <TableGrid rows={state.pluginList} columns={columns}>
              <PagingState
                currentPage={state.currentPage}
                onCurrentPageChange={changePage}
                pageSize={state.pageSize}
                onPageSizeChange={changePageSize}
              />
              <IntegratedPaging />
              <Table
                tableComponent={TableComponent}
                headComponent={HeaderComponentBase}
                columnExtensions={columnWidths(columns)}
              />
              <TableHeaderRow contentComponent={HeaderComponent} />
              <PagingPanel
                pageSizes={[5, 10, 15, 20]}
                containerComponent={PagingComponent}
              />
            </TableGrid>
          </Box>
        )}
      </Grid>
    </PageHeader>
  );
};
export default PluginActive;
