import React, { useEffect, useState, useRef } from "react";
import {
  FormControl,
  Grid,
  TextField,
  Typography,
  makeStyles,
  Box,
  Button,
} from "@material-ui/core";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import withHeader from "../../presentation/withHeader";
import MyButton from "../../presentation/button";
import COLOR from "../../styled/colors";
import { useAppSelector, useAppDispatch } from "../../utils/hooks";
import { downloadCSV, fetchSession } from "../../store/session/actions";
import { useDispatch } from "react-redux";
import { PluginRefObj, PluginForm } from "@aglive/web-core";
import { Line } from "../auth/styles";
import DialogMessage from "../../presentation/DialogMessage";
import { PluginModel, TokenModel, TokenService } from "@aglive/data-model";
import { toggleModal, toggleModalOff } from "../../store/modal/actions";
import { Buttons } from "../../presentation/ButtonsGroup";
import { HyperLink } from "../../presentation/word";
import MyTable from "../../presentation/Table"
import {
  PagingState,
  CustomPaging,
  IntegratedPaging
} from '@devexpress/dx-react-grid';
import {
  Grid as TableGrid,
  Table,
  TableHeaderRow,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';

const useStyle = makeStyles((theme) => ({
  button: {
    color: COLOR.GREEN_BUTTON,
    borderColor: COLOR.GREEN_BUTTON,
    marginRight: 50,
  },
}));

const headers = [{name: 'name', title: "Name"}, {name:'template', title: "Template Type"}, {name:'creationDate', title: "Generated Time"}, {name:'email', title: "Email Address"}, {name: 'rows', title: "Rows"}, {name: "action", title: "Action"}]
const ViewSession: React.FC<{}> = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const classes = useStyle();
  const dispatch = useDispatch();
  const session = useAppSelector((state) => state.session.session);
  const { sessionId } = useParams<{sessionId:string}>();
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [lastQuery, setLastQuery] = useState();

  const generatedCSV = session.generatedCSV ?? []
  const [openDialog, setOpenDialog] = useState(false);
  
  useEffect(() => {
    dispatch(fetchSession(sessionId));
  }, []);
  return (
    <>
    <MyButton
          text={"Create CSV"}
          variant="contained"
          onClick={() => {
            console.log(path)
            history.push(`/private/session/csv/view/${sessionId}/generate`);
          }}
      />
    <TableGrid rows={
        generatedCSV.map(o => ({
          name: o.name,
          template: o.templateId,
          creationDate: o.creationDate,
          email: o.email,
          rows: o.rows,
          action: <Button
            variant='outlined'
            style={{
              backgroundColor: COLOR.GREEN_BUTTON,
              color: COLOR.WHITE,
              border: 'none',
            }}
            onClick={() => {
              dispatch(downloadCSV({sessionId, csvIds: [o.csvId]}));
            }}
        >Download</Button>
    }))} columns={headers}>
       <PagingState
          currentPage={currentPage}
          onCurrentPageChange={setCurrentPage}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
        />
        <IntegratedPaging />

        <Table />
        <TableHeaderRow />
        <PagingPanel pageSizes={[5, 10, 15, 20]}/>
      </TableGrid>
      <Button
            variant='outlined'
            style={{
              backgroundColor: COLOR.GREEN_BUTTON,
              color: COLOR.WHITE,
              border: 'none',
            }}
            onClick={() => {
              dispatch(downloadCSV({sessionId}));
            }}
        >Download All</Button>
    </>
  );
};

export default withHeader(
  {
    title: "View Session",
    margin: 60,
    back: true,
  },
  ViewSession
);
