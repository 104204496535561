import React, { useState, useEffect } from "react";
import { PageHeader } from "../../../presentation/withHeader";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import {
  clearTokenDataList,
  deleteToken,
  getAllTokenList,
  getTokenDataList,
} from "../../../store/deleteToken/actions";
import {
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import {
  Grid as TableGrid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  PagingPanel,
} from "@devexpress/dx-react-grid-material-ui";
import {
  FilteringState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
} from "@devexpress/dx-react-grid";
import DeleteIcon from '@material-ui/icons/Delete';
import { toggleModal, toggleModalOff } from "../../../store/modal/actions";
import ConfirmButtons from "../../../presentation/ConfirmButtons";

import Assets from "./assets";
import AngusPanel from "./AngusPanel";

const tableColumnsBaseOnToken = {
  promotion: [
    { name: "agliveToken", title: "Promtion Token ID", width: 350 },
    { name: "businessId", title: "Business ID", width: 350 },
    { name: "name", title: "Promotion Name", width: 400 },
    { name: "actions", title: "Actions", width: 100 },
    //{ name: 'type', title:'Type', width: 200 },
  ],
  product: [
    { name: "agliveToken", title: "Product Token ID", width: 350 },
    { name: "businessId", title: "Business ID", width: 350 },
    { name: "name", title: "Product Name", width: 400 },
    { name: "actions", title: "Actions", width: 100 },
    //{ name: 'type', title:'Type', width: 200 },
  ],
  others: [{ name: "type", title: "Type", width: 200 }],
};

const DeleteTokenManage: React.FC<{}> = () => {
  const [s_tokenSel, s_setTokenSel] = useState("n/a"); // n/a default value for selection.
  const [s_dataListTable, s_setDataListTable] = useState([]);
  const [s_filterState, s_setFilterState] = useState([]);
  const [s_tableColumn, s_setTableColumn] = useState(
    tableColumnsBaseOnToken.others
  );

  const rs_tokenList = useAppSelector((state) => state.deleteToken.tokenList);
  const rs_tokenDataDetails = useAppSelector(
    (state) => state.deleteToken.tokenDataDetails
  );
  const dispatch = useAppDispatch();

  const columnWidths = (
    cols: Array<{ name: string; title: string; width: number }>
  ) =>
    cols?.map((dt) => ({
      columnName: dt.name,
      width: dt.width,
    }));

  async function updateData() {
    await dispatch(getAllTokenList());

    s_setTableColumn(
      tableColumnsBaseOnToken[s_tokenSel] || tableColumnsBaseOnToken.others
    );
    s_setFilterState([]); // Reset all filter when token type change
    dispatch(clearTokenDataList());

    //TODO: Temporary, will be refractored once more types are added
    if (s_tokenSel === "promotion" || s_tokenSel === "product") {
      await dispatch(getTokenDataList(s_tokenSel));
    }
  }

  useEffect(() => {
    updateData();
  }, [s_tokenSel]);

  useEffect(() => {
    let temp = [];
    let tempAgliveId = "";

    if (rs_tokenDataDetails) {
      for (const item of rs_tokenDataDetails) {
        tempAgliveId = "";
        let tempActions = null;
        for (const ids of item.externalIds) {
          if (ids.agliveToken) {
            tempAgliveId = ids.agliveToken;
            break;
          }
        }

        //TODO: Temporary, will be refractored once more types are added
        if (s_tokenSel === "promotion") {
          tempActions = (
            <>
              <DeleteIcon
                key={tempAgliveId}
                data-index={tempAgliveId}
                style={{
                  cursor: 'pointer',
                }}
                onClick={(e) => {
                  const dataIndex = (e.currentTarget as any).getAttribute("data-index");
                  dispatch(toggleModal({
                    title: "Are you sure to delete this token?",
                    subtitle: ["This action cannot be undone.", 'Token id:', dataIndex],
                    status: 'warning',
                    renderButton: <ConfirmButtons 
                        confirmText='Delete'
                        onCancel={() => {
                            dispatch(toggleModalOff())
                        }}
                        onConfirm={() => {
                          dispatch(
                            deleteToken(
                              item?.type,
                              dataIndex,
                              () => {
                                updateData();
                              }
                            )
                          );
                          dispatch(toggleModalOff());
                        }}
                    />
                  }))
                }}
              />
            </>
          );
        }

        temp.push({
          functionTest: () => {
            alert(`Delete ${tempAgliveId}`);
          },
          type: item?.type,
          userId: item?.userId,
          agliveToken: tempAgliveId,
          businessId: item.businessId,
          name: item.details.name,
          actions: tempActions,
        });
      }
    }

    s_setDataListTable(temp);
  }, [rs_tokenDataDetails]);

  return (
    <PageHeader
      config={{
        title: "Delete Token Data",
        margin: 1,
        back: true,
      }}
    >
      <br></br>

      <Typography
        variant="h6"
        role="label"
        style={{
          fontWeight: "bold",
          margin: "0.5rem 0rem",
          fontSize: "1.5rem",
        }}
      >
        {"Type"}
      </Typography>

      <Select
        value={s_tokenSel}
        style={{ width: "20rem" }}
        onChange={(e) => {
          s_setTokenSel(e.target.value as string);
        }}
      >
        <MenuItem value="n/a">
          <em>Please Select Token Type</em>
        </MenuItem>
        {rs_tokenList.map((item) => {
          return (
            <MenuItem key={item.type} value={item.type}>
              <span>{item.type}</span>
              <span
                style={{
                  borderColor: "black",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderRadius: "16px",
                  padding: "0.2rem 1rem",
                  fontSize: "0.9rem",
                  marginLeft: "0.5rem",
                }}
              >
                {item.totalNumber}
              </span>
            </MenuItem>
          );
        })}
      </Select>

      {/** TABLE SECTION **/}
      {(s_tokenSel === "promotion" || s_tokenSel === "product") && (
        <TableGrid columns={s_tableColumn} rows={s_dataListTable}>
          <PagingState defaultCurrentPage={0} pageSize={20} />
          <FilteringState
            defaultFilters={[]}
            filters={s_filterState}
            onFiltersChange={(filter) => {
              s_setFilterState(filter);
            }}
          />
          <IntegratedFiltering />
          <IntegratedPaging />
          <Table columnExtensions={columnWidths(s_tableColumn)} />
          <TableHeaderRow />
          <TableFilterRow />
          <PagingPanel />
        </TableGrid>
      )}

      {/** TABLE SECTION **/}
      {s_tokenSel === "asset" && <Assets></Assets>}
      {s_tokenSel === "angusCalve" && <AngusPanel label="RFID" tokenType="angusCalve" keyId="rfid" />}
      {s_tokenSel === "angusMob" && <AngusPanel label="Mob Name" tokenType="angusMob" keyId="mob_name" />}
      {s_tokenSel === "angusSire" && <AngusPanel label="Angus RFID" tokenType="angusSire" keyId="angusRfid" />}
      {s_tokenSel === "angusSireGroup" && <AngusPanel label="Angus Id" tokenType="angusSireGroup" keyId="angusId" />}
    </PageHeader>
  );
};

export default DeleteTokenManage;
