import React from "react";
import {withStyles, makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const ButtonStyle=withStyles((theme) => ({
   root: {
      boxShadow: 'none',
      textTransform: 'none',
      fontSize: theme.typography.h4.fontSize,
      padding: '6px 12px',
      border: '1px solid',
      lineHeight: 1.5,
      width: 296,
      borderRadius: '8px',
      fontFamily: theme.typography.h4.fontFamily,
   },
   contained: {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.info.main,
      borderColor: theme.palette.info.main,
      '&:hover': {
         backgroundColor: theme.palette.info.main,
         borderColor: theme.palette.info.main,
         boxShadow: 'none',
      },
   },
   outlined: {
      color: theme.palette.info.main,
      backgroundColor: theme.palette.secondary.main,
      borderColor: theme.palette.info.main,
      '&:hover': {
         backgroundColor: theme.palette.secondary.main,
         borderColor: theme.palette.info.main,
         boxShadow: 'none',
      },
   },
   disabled: {
      border: 0,
   }
}))(Button);

const useStyles=makeStyles({
   button: {
      width: (props: {width: number, fontSize: number}) => props.width,
      fontSize: (props: {width: number, fontSize: number}) => props.fontSize,
   }
});

type Props={
   text: any;
   variant: 'contained'|'outlined';
   onClick?: () => void;
   width?: number;
   fontSize?: number;
   startIcon?: object;
   disabled?: boolean;
   buttonClass?: string;
};

const MyButton: React.FC<Props>=(props) => {
   const classes=useStyles({width: props.width, fontSize: props.fontSize})
   return (
      <ButtonStyle
         disabled={props.disabled}
         variant={props.variant}
         disableElevation
         className={`${classes.button} ${props.buttonClass}`}
         startIcon={props.startIcon}
         onClick={props.onClick}
      >
         {props.text}
      </ButtonStyle>
);
};

export default MyButton;
