import React from 'react';
import {
  TableHeaderRow,
  TableRowDetail,
  Grid as TableGrid,
  Table,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import { DataTypeProvider, RowDetailState } from '@devexpress/dx-react-grid';

import { Box, Grid, Typography } from '@material-ui/core';
import COLOR from '../../../styled/colors';
import {
  BlockchainTableHeader,
  toColumnExtensions,
  toCommonColumns,
} from './tableHeader';
import { camelToSentence, flatten } from './utils';
import { blockchainStyle } from './style';

const getShallowDetails = (tokenData) => {
  const result = flatten(tokenData);
  return result;
};

const arrayToBack = (obj) => (a, b) => {
  if (Array.isArray(obj[a]) && obj[a].length === 0) {
    return -1;
  }
  if (
    Array.isArray(obj[a]) &&
    obj[a].every((item) => typeof item !== 'object')
  ) {
    return -1;
  }
  if (Array.isArray(obj[a]) && Array.isArray(obj[b])) {
    return 0;
  }
  return Array.isArray(obj[a]) && obj[a].length > 0 ? 1 : -1;
};

const RowDetail = ({ row }) => {
  const { ...extraDetails } = getShallowDetails(row);
  const classes = blockchainStyle.useStyle();
  return (
    <Grid className={classes.tableContainer}>
      {Object.keys(extraDetails)
        .sort((a, b) => {
          return b.localeCompare(a, undefined, { sensitivity: 'base' });
        })
        .sort(arrayToBack(extraDetails))
        .map((key, i) => {
          const val = extraDetails[key];
          if (
            Array.isArray(val) &&
            val.length > 0 &&
            val.some((item) => typeof item === 'object')
          ) {
            const tableRows = val.map(flatten);
            const columns = toCommonColumns(tableRows);
            const columnExtensions = toColumnExtensions(columns);

            return (
              <Grid key={`${key}-${i}`} component={Box} mt={3} ml={0}>
                <Typography
                  variant='h5'
                  style={{
                    marginBottom: 5,
                  }}
                >
                  {camelToSentence(key)}
                </Typography>
                <DataTable
                  virtual={tableRows.length > 10}
                  rows={tableRows}
                  columns={columns}
                  columnExtensions={columnExtensions}
                  backgroundColor={COLOR.WHITE}
                  showDetail={columns.length > 1}
                />
              </Grid>
            );
          }

          return (
            <Grid container key={`${key}-${i}`} direction='row'>
              <Grid item xs={2} className={classes.labelCell}>
                <Typography role='label' className={classes.titleLabel}>
                  {camelToSentence(key)}
                </Typography>
              </Grid>
              <Grid item xs={10} className={classes.valueCell}>
                <Typography className={classes.displayText}>
                  {typeof extraDetails[key] === 'string'
                    ? extraDetails[key]
                    : JSON.stringify(extraDetails[key])}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
    </Grid>
  );
};

interface DataTableColumnExtension {
  columnName: string;
  width: number;
  wordWrapEnabled?: boolean;
}

interface DataTableProps {
  rows: Array<Record<string, any>>;
  columns: any[];
  columnExtensions: Array<DataTableColumnExtension>;
  backgroundColor?: string;
  virtual?: boolean;
  showDetail?: boolean;
}

const ArrayFormatter = ({ value }) => {
  if (!Array.isArray(value)) {
    return <span>''</span>;
  }
  return <span>{value.join(', ')}</span>;
};

const ArrayTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={ArrayFormatter} {...props} />
);

const DataTable = ({
  rows,
  columns,
  columnExtensions,
  backgroundColor = COLOR.GRAY_SOLID,
  virtual = false,
  showDetail = true,
}: DataTableProps) => {
  if (columns.length < 0) return null;
  if (rows.length < 0) return null;
  const columnsWithArrayValues: string[] = columns
    .filter((col) => rows.some((row) => Array.isArray(row[col.name])))
    .map((col) => col.name);
  return (
    <Box
      style={{
        backgroundColor: backgroundColor,
      }}
    >
      <TableGrid rows={rows} columns={columns}>
        <ArrayTypeProvider for={columnsWithArrayValues} />
        {showDetail && <RowDetailState />}
        {virtual ? (
          <VirtualTable columnExtensions={columnExtensions} height={800} />
        ) : (
          <Table columnExtensions={columnExtensions} />
        )}
        <TableHeaderRow contentComponent={BlockchainTableHeader} />
        {showDetail && <TableRowDetail contentComponent={RowDetail} />}
      </TableGrid>
    </Box>
  );
};

export default DataTable;
