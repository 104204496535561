import React, { useEffect, useState, useRef } from "react";
import { Grid, Typography, makeStyles, Box } from "@material-ui/core";
import { useHistory, useRouteMatch } from "react-router-dom";
import withHeader from "../../presentation/withHeader";
import MyButton from "../../presentation/button";
import COLOR from "../../styled/colors";
import { useAppSelector, useAppDispatch } from "../../utils/hooks";
import { fetchPlugin, deletePlugin } from "../../store/plugin/actions";
import { useDispatch } from "react-redux";
import { PluginRefObj, PluginForm } from "@aglive/web-core";
import { Line } from "../auth/styles";
import DialogMessage from "../../presentation/DialogMessage";
import { PluginModel } from "@aglive/data-model";
import { toggleModal, toggleModalOff } from "../../store/modal/actions";
import { Buttons } from "../../presentation/ButtonsGroup";
import { HyperLink } from "../../presentation/word";

const useStyle = makeStyles((theme) => ({
  button: {
    color: COLOR.GREEN_BUTTON,
    borderColor: COLOR.GREEN_BUTTON,
    marginRight: 50,
  },
}));
const Plugins: React.FC<{}> = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const classes = useStyle();
  const dispatch = useDispatch();
  const pluginsArray = useAppSelector((state) => state.plugin.plugins);
  const pluginFormRef = useRef<PluginRefObj>(null);
  const [selectedPlugin, setSelectedPlugin] = useState(
    {} as PluginModel.Plugin
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [credentialsState, setCredentialsState] = useState({});
  const showPlugin = (plugin) => {
    setSelectedPlugin(plugin);
    setOpenDialog(true);
  };
  const onDelete = (pluginId: string) => {
    dispatch(
      toggleModal({
        status: "warning",
        title: "Delete Plugin?",
        subtitle: "This action cannot be undone",
        renderButton: (
          <Buttons
            leftButtonTitle="No"
            rightButtonTitle="Yes"
            leftButtonOnClick={() => {
              dispatch(toggleModalOff());
            }}
            rightButtonOnClick={() => {
              dispatch(toggleModalOff());
              dispatch(
                deletePlugin(pluginId, () => {
                  setOpenDialog(false);
                  dispatch(fetchPlugin());
                })
              );
            }}
          />
        ),
      })
    );
  };
  useEffect(() => {
    let credentialsStateTemp = {};
    setCredentialsState(credentialsStateTemp);
  }, [selectedPlugin]);
  useEffect(() => {
    dispatch(fetchPlugin());
  }, []);
  return (
    <>
      <Box mt={5}>
        <Box style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(450px, 1fr))',
          gap: '2rem'
        }}>
          {pluginsArray.map((plugin) => (
            <Grid key={`plugin-${plugin.uuid}`} item container>
              <Box
                style={{
                  border: `1px solid ${COLOR.GRAY_BORDER}`,
                  fontSize: 0,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  style={{ display: "flex", flexDirection: "column", flex: 1 }}
                >
                  <img
                    alt={plugin.name}
                    src={plugin.imageUrl}
                    height={window.innerWidth * 0.15}
                    width={window.innerWidth * 0.27}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "contain",
                    }}
                  />
                  <Line
                    mt={3}
                    style={{ width: "100%", borderBottom: 0, margin: 0 }}
                  />
                  <Box p={2} mt={1}>
                    <Typography variant="h3" gutterBottom>
                      {plugin.name}
                    </Typography>
                    <Typography variant="body1" paragraph>
                      {plugin.description}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: 10
                  }}
                >
                  <Box p={2} style={{display: 'grid', placeItems: 'center'}}>
                    <HyperLink target="_blank" href={plugin.pluginRoute}>
                      More Info
                    </HyperLink>
                  </Box>
                  <Box
                    style={{ display: "flex", justifyContent: "center", alignItems: 'center'}}
                  >
                    <MyButton
                      text="View"
                      variant="contained"
                      onClick={() => showPlugin(plugin)}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Box>
      </Box>
      <Grid container justifyContent="flex-end" style={{ marginTop: 20 }}>
        <Grid container item xs={4} justifyContent="flex-end">
          <MyButton
            text={"Add"}
            variant="outlined"
            buttonClass={classes.button}
            onClick={() => history.push(`${path}/details`)}
          />
        </Grid>
      </Grid>
      <DialogMessage
        open={openDialog}
        title="Activate Plugin"
        handleClose={() => setOpenDialog(false)}
        size="sm"
        marginBottom={50}
      >
        <Grid container style={{ marginTop: 15 }}>
          <PluginForm
            ref={pluginFormRef}
            jsonForm={selectedPlugin}
            credentialsState={credentialsState}
          />
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            style={{ marginTop: 40, marginBottom: -20 }}
          >
            <MyButton
              text="Modify"
              width={250}
              variant="outlined"
              onClick={() => {
                history.push(`${path}/details`, { uuid: selectedPlugin.uuid });
              }}
            />
            <MyButton
              text="Obsolete"
              width={250}
              variant="contained"
              onClick={() => {
                onDelete(selectedPlugin.uuid);
              }}
            />
          </Grid>
        </Grid>
      </DialogMessage>
    </>
  );
};

export default withHeader(
  {
    title: "Plugins",
    margin: 60,
  },
  Plugins
);
