import axios from "axios";
import { AppThunk } from '../types';
import { callAPI, getAPI } from '../../utils/network';
import { NetworkError, WebErrorType } from '../../utils/error';
import { GET_ALERT } from './type';
import { toggleModal, toggleModalOff } from '../modal/actions';
import { SPINNER_TOGGLE_ON, SPINNER_TOGGLE_OFF } from '../spinner/types';

export function fetchAlert(): AppThunk<Promise<void>> {
    return async (dispatch) => {
        try {
            dispatch({ type: SPINNER_TOGGLE_ON });

            const alerts = await callAPI({
                url: getAPI().GET.getAlert,
                method: 'GET',
            })
            dispatch({
                type: GET_ALERT,
                payload: alerts,
            });
        }
        catch (e) {
            const error = e as WebErrorType;
            dispatch(
                toggleModal({
                    status: 'failed',
                    title: error.title,
                    subtitle: error.message,
                    button: 'Close',
                }),
            );
        } finally {
            dispatch({ type: SPINNER_TOGGLE_OFF });
        }
    }
}

export function createAlert(jsonObject: any, successCB: () => void): AppThunk<Promise<void>> {
    return async (dispatch) => {
        try {
            dispatch({ type: SPINNER_TOGGLE_ON });
            await callAPI({
                url: getAPI().POST.createAlert,
                method: 'POST',
                data: jsonObject
            })
            // console.log("jsonObject", jsonObject)
            dispatch(
                toggleModal({
                    status: 'success',
                    title: 'Successfully Completed',
                    button: 'Close',
                    CTAHandler: successCB,
                }),
            )
        }
        catch (e) {
            const error = e as WebErrorType;
            dispatch(
                toggleModal({
                    status: 'failed',
                    title: error.title,
                    subtitle: error.message,
                    button: 'Close',
                }),
            );
        } finally {
            dispatch({ type: SPINNER_TOGGLE_OFF });
        }
    }
}

export function fetchAlertById(alertId: string): AppThunk<Promise<any>> {
    return async (dispatch) => {
        try {
            dispatch({ type: SPINNER_TOGGLE_ON });

            const alerts = await callAPI({
                url: getAPI().GET.getAlert,
                method: 'GET',
            })

            if(alerts){
               return alerts.find(x => x.uuid === alertId);
            }

            return null;
        }
        catch (e) {
            const error = e as WebErrorType;
            dispatch(
                toggleModal({
                    status: 'failed',
                    title: error.title,
                    subtitle: error.message,
                    button: 'Close',
                }),
            );
        } finally {
            dispatch({ type: SPINNER_TOGGLE_OFF });
        }
    }
}

export function deleteAlert(alertUuid: string, successCB: () => void): AppThunk<Promise<void>> {
    return async (dispatch) => {
        try {
            dispatch({ type: SPINNER_TOGGLE_ON });
            await callAPI({
                url: getAPI().DELETE.deleteAlert+alertUuid,
                method: 'DELETE',
            })
            // console.log("jsonObject", jsonObject)
            dispatch(
                toggleModal({
                    status: 'success',
                    title: 'Successfully Completed',
                    button: 'Close',
                    CTAHandler: successCB,
                }),
            )
        }
        catch (e) {
            const error = e as WebErrorType;
            dispatch(
                toggleModal({
                    status: 'failed',
                    title: error.title,
                    subtitle: error.message,
                    button: 'Close',
                }),
            );
        } finally {
            dispatch({ type: SPINNER_TOGGLE_OFF });
        }
    }
}

export function updateAlert(jsonObject: any, successCB: () => void): AppThunk<Promise<void>> {
    return async (dispatch) => {
        try {
            dispatch({ type: SPINNER_TOGGLE_ON });
            await callAPI({
                url: getAPI().PUT.updateAlert+jsonObject.uuid,
                method: 'PUT',
                data: jsonObject
            })
            // console.log("jsonObject", jsonObject)
            dispatch(
                toggleModal({
                    status: 'success',
                    title: 'Successfully Completed',
                    button: 'Close',
                    CTAHandler: successCB,
                }),
            )
        }
        catch (e) {
            const error = e as WebErrorType;
            dispatch(
                toggleModal({
                    status: 'failed',
                    title: error.title,
                    subtitle: error.message,
                    button: 'Close',
                }),
            );
        } finally {
            dispatch({ type: SPINNER_TOGGLE_OFF });
        }
    }
}