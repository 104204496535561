import React, { useState } from "react";
import {
    Grid,
    Typography,
    makeStyles,
    TextField,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import COLOR from "../../../styled/colors";
import MyButton from "../../../presentation/button";
import { PageHeader } from "../../../presentation/withHeader";
import { useAppDispatch } from "../../../utils/hooks";
import { toggleModal } from "../../../store/modal/actions";
import { deleteTokenV2, changeTokenStatus } from "../../../store/monitor/actions";
import CSV from "papaparse";

const useStyle = makeStyles((theme) => ({
    button: {
        color: COLOR.GREEN_BUTTON,
        borderColor: COLOR.GREEN_BUTTON,
        marginRight: 50,
    },
    uploadField: {
        marginTop: 10,
        marginBottom: 10,
    },
    uploadButton: {
        height: "2.7em",
    },
    fileName: {
        height: 48,
        width: "100%",
        maxWidth: window.innerWidth / 2,
    },
    textBox: {
        marginTop: 10,
        marginBottom: 20,
    },
    fileNameLabel: {
        display: "flex",
        flexDirection: "row",
        gap: "0.2em",
        border: `1px solid ${COLOR.GRAY_BORDER}`,
        borderRadius: "0.2em",
        padding: "0.45em",
        cursor: "pointer",
        userSelect: "none",
        "&:hover": {
            border: `1px solid ${COLOR.GREEN}`,
        },
    },
    currentFile: {
        height: "2.7em",
        display: "flex",
        flexDirection: "row",
        padding: "0.5em",
        alignItems: "center",
        border: `1px solid ${COLOR.GRAY_BORDER}`,
        borderRadius: "0.2em",
    },
    formControl: {
        width: "100%",
    },
    cancelIcon: {
        color: COLOR.GRAY_ICON,
        "&:hover": {
            color: COLOR.GREEN,
        },
    },
    subTitle: {
        marginTop: theme.spacing(3),
        fontWeight: 600, // TODO
    },
    selectDropdown: {
        marginTop: 15,
        width: "100%",
    },
    downloadLabel: {
        fontSize: "0.9rem",
    },
    statusLabel: {
        fontSize: "0.9rem",
    },
    downloadItem: {
        cursor: "default",
    },
    downloadLink: {
        textDecoration: "underline",
        width: "100%",
        height: "100%",
        cursor: "pointer",
    },
    select: {
        borderColor: COLOR.GRAY_BORDER,
    },
    radioButton: {
        marginRight: 20,
    },
}));

type PropType = {
    label: string,
    tokenType: string,
    keyId: string,
}

const AngusPanel: React.FC<PropType> = ({label, tokenType, keyId}) => {
    const classes = useStyle();
    const dispatch = useAppDispatch();

    const [fieldValue, setFieldValue] = useState("")
    const [modifyOption, setModifyOption] = useState("Main Status")
    const [targetStatus, setTargetStatus] = useState("")

    const [fileName, setFileName] = useState("")
    const [fieldValues, setFieldValues] = useState<Record<string, string>[]>([])

    const csvFileDialogRef = React.useRef<HTMLInputElement>(null);

    const uploadCsvFileHandler = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            
            if (e.target.files[0].size > 10485760) { //checking file size limit
                dispatch(
                    toggleModal({
                        status: "failed",
                        title: "Upload Failed",
                        subtitle: "Upload image size more than 10mb",
                    })
                );
                return
            } 
            const inputFile = e.target.files[0];
            if (!inputFile) {
                return;
            }
            const fileName = inputFile.name;

            const reader = new FileReader();
            reader.onload = async ({ target }) => {
                let csvData = target.result;
                if (typeof csvData === "string") {
                    csvData = (csvData as string).trim();
                }
                const csv = CSV.parse(csvData, {
                    header: true,
                    delimiter: "\n",
                });
                if (
                    csv.meta.fields.length != 1 ||
                    csv.meta.fields[0] != keyId
                ) {
                    dispatch(
                        toggleModal({
                            status: "failed",
                            title: "Invalid CSV",
                            subtitle: `Ensure header is "${keyId}"`,
                        })
                    );
                    handleRemoveFile();
                    return;
                }

                setFieldValues(csv.data)
            };

            const blob = inputFile.slice(0, inputFile.size, "file");
            reader.readAsText(blob);
            csvFileDialogRef.current.value = null;
            setFileName(fileName)
            
        }
    };

    const handleRemoveFile = () => {
        setFileName("")
        setFieldValues([])
    };

    const reset = ()=>{
        setFieldValue("")
    }

    return (
        <PageHeader
            config={{
                title: "",
                margin: 1,
            }}
        >
            <Grid container>
                <Typography
                    variant="h6"
                    className={classes.subTitle}
                    role="label"
                >
                    {label}
                </Typography>

                <Grid container direction="row" className={classes.textBox}>
                    <Grid item xs={9}>
                        <TextField
                            style={{ width: "50%" }}
                            variant="outlined"
                            value={fieldValue}
                            onChange={(e) => setFieldValue(e.target.value)}
                        />
                    </Grid>
                </Grid>

                <Grid container direction="row">
                    <Typography
                        variant="caption"
                        className={classes.radioButton}
                        role="label"
                    >
                        <input
                            type="radio"
                            value="Main Status"
                            name="rfidOption"
                            id="mainstatus"
                            checked={modifyOption === "Main Status"}
                            onChange={(e) => setModifyOption(e.target.value)}
                        />
                        Modify Main Status
                    </Typography>
                    <Typography
                        variant="caption"
                        className={classes.radioButton}
                        role="label"
                    >
                        <input
                            type="radio"
                            value="Delete Asset"
                            name="rfidOption"
                            id="deleteasset"
                            checked={modifyOption === "Delete Asset"}
                            onChange={(e) => setModifyOption(e.target.value)}
                        />
                        Delete Asset
                    </Typography>
                </Grid>

                {modifyOption == "Main Status" && (
                    <Grid>
                        <Grid className={classes.uploadField} spacing={3}>
                            <FormControl
                                className={classes.selectDropdown}
                                size="medium"
                                variant="filled"
                            >
                                <InputLabel
                                    id="select-status-label"
                                    className={classes.statusLabel}
                                >
                                    Select Status
                                </InputLabel>

                                <Select
                                    labelId="select-status-label"
                                    id="select-status"
                                    className={classes.select}
                                    value={targetStatus}
                                    onChange={(e) =>
                                        setTargetStatus(e.target.value as string)
                                    }
                                >
                                    <MenuItem value={"deleted"}>
                                        Delete
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <MyButton
                            text={"Apply"}
                            disabled={!fieldValue || !targetStatus}
                            variant="contained"
                            onClick={() => {
                                dispatch(
                                    changeTokenStatus(tokenType, [{ [keyId]: fieldValue }], keyId, targetStatus, reset)
                                );
                            }}
                        />
                    </Grid>
                )}

                {modifyOption == "Delete Asset" && (
                    <Grid className={classes.uploadField}>
                        <MyButton
                            text={"Delete"}
                            disabled={!fieldValue}
                            variant="contained"
                            onClick={() => {
                                dispatch(
                                    deleteTokenV2(tokenType, [{[keyId]: fieldValue}], keyId, reset)
                                );
                            }}
                        />
                    </Grid>
                )}
            </Grid>

            <Grid container>
                <Typography
                    variant="h6"
                    className={classes.subTitle}
                    role="label"
                >
                    {`${label} CSV File`}
                </Typography>

                <Grid
                    container
                    direction="row"
                    className={classes.uploadField}
                    spacing={3}
                >
                    <Grid item className={classes.fileName} xs={4}>
                        <Box className={classes.currentFile}>
                            {fileName ? (
                                <Box className={classes.fileNameLabel}>
                                    <Typography>{fileName}</Typography>
                                    <Box
                                        className={classes.cancelIcon}
                                        onClick={handleRemoveFile}
                                    >
                                        <CloseIcon />
                                    </Box>
                                </Box>
                            ) : null}
                        </Box>
                    </Grid>
                    <Grid container item xs={2}>
                        <MyButton
                            text={"Upload File"}
                            variant="outlined"
                            onClick={()=>csvFileDialogRef.current?.click()}
                            buttonClass={classes.uploadButton}
                        />
                        <input
                            type="file"
                            accept=".csv"
                            data-cy="uploadFile"
                            onChange={uploadCsvFileHandler}
                            ref={csvFileDialogRef}
                            style={{ height: 0, width: 0 }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <MyButton
                text={`Delete CSV ${label}`}
                disabled={!fileName}
                variant="contained"
                onClick={() => {
                    dispatch(
                        deleteTokenV2(tokenType, fieldValues, keyId, reset)
                    );
                    handleRemoveFile();
                }}
            />
        </PageHeader>
    );
};

export default AngusPanel;
