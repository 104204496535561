import {callAPI, getAPI} from '../../utils/network';
import {NetworkError, WebErrorType} from '../../utils/error';
import {AppThunk} from '../types';
import {SPINNER_TOGGLE_ON, SPINNER_TOGGLE_OFF} from '../spinner/types';
import {toggleModal, toggleModalOff} from '../modal/actions';
import {GET_PLUGIN_DATA} from './type';
import { PluginModel, TokenModel, TokenService } from '@aglive/data-model';


export async function fileUploader(file: File, category:string) {
    try {
      const data = new FormData();
  
      data.append('category',category);
      data.append('file', file);
      const response = await callAPI({
        url: getAPI().POST.uploadTos3,
        method: 'POST',
        data,
      });
  
      return response;
    } catch (e) {
      throw new NetworkError(e);
    }
  }

export function createPlugin(jsonObject:PluginModel.Plugin,successCB: () => void): AppThunk<Promise<void>>{
  return async(dispatch)=>{
    try{
      dispatch({type: SPINNER_TOGGLE_ON});
      await callAPI({
        url:getAPI().POST.createPlugin,
        method: 'POST',
        data:jsonObject
      })
      dispatch(
        toggleModal({
          status: 'success',
          title: 'Successfully Completed',
          button: 'Close',
          CTAHandler: successCB,
        }),
      )
    }
    catch(e){
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Close',
        }),
      );
    }finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  }
}

export function fetchPlugin():AppThunk<Promise<void>>{
  return async(dispatch)=>{
    try{

      dispatch({ type: SPINNER_TOGGLE_ON });

      const plugins=await callAPI({
        url:getAPI().GET.getPlugin,
        method: 'GET',
      })
      dispatch({
        type:GET_PLUGIN_DATA,
        payload:plugins,
      });
    }
    catch(e){
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Close',
        }),
      );
    } finally {
      dispatch({ type: SPINNER_TOGGLE_OFF });
    }
  }
}

export function editPlugin(
  id:string,
  imageUrl:string,
  jsonObject:PluginModel.Plugin,
  ifUpdateImage:boolean,
  ifUpdateFile:boolean,
  successCB: () => void):AppThunk<Promise<void>>{
  return async(dispatch)=>{
    try{
      dispatch({type: SPINNER_TOGGLE_ON});
      if(ifUpdateFile){
        const updateFileData=Object.assign(jsonObject,{type:'general', pluginId:id})
        console.log('updateFileData',updateFileData)
        await callAPI({
          url:getAPI().PUT.updatePlugin,
          method: 'PUT',
          data:updateFileData
        })
      }
      if(ifUpdateImage){
        const updateImageData={
          type:'image',
          pluginId:id,
          imageUrl
        }
        await callAPI({
          url:getAPI().PUT.updatePlugin,
          method: 'PUT',
          data:updateImageData
        })
      }
      dispatch(
        toggleModal({
          status: 'success',
          title: 'Updated',
          button: 'Close',
          CTAHandler: successCB,
        }),
      )
    }
    catch(e){
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Close',
        }),
      );
    }finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  }
}

export function deletePlugin(pluginId:string,successCB: () => void): AppThunk<Promise<void>>{
  return async(dispatch)=>{
    try{
      dispatch({type: SPINNER_TOGGLE_ON});
      await callAPI({
        url:getAPI().DELETE.deletePlugin,
        method: 'DELETE',
        data:{pluginId}
      })
      dispatch(
        toggleModal({
          status: 'success',
          title: 'Deleted',
          button: 'Close',
          CTAHandler: successCB,
        }),
      )
    }
    catch(e){
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Close',
        }),
      );
    }finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  }
}