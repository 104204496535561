import React, { useCallback } from 'react';
import {
  Grid,
} from '@material-ui/core';
import MyButton from '../../../presentation/button';
import { PageHeader } from '../../../presentation/withHeader';
import { useAppDispatch } from '../../../utils/hooks';
import { toggleModal } from '../../../store/modal/actions';
import {
  SPINNER_TOGGLE_OFF,
  SPINNER_TOGGLE_ON,
} from '../../../store/spinner/types';
import { WebErrorType } from '../../../utils/error';
import useCsvData from '../useCsvData';
import CSVUploadInput from './CSVUploadInput';
import { uploadTokenByCSV } from './utils';
import { useBusinessList } from '../../../utils/hooks/useBusinessList';
import SearchDropdown from '../../../presentation/SearchDropdown';

const UploadMackas: React.FC<{}> = () => {
  const dispatch = useAppDispatch();

  const promptError = useCallback(
    ({ title, message }: { title: string; message: string }) => {
      dispatch(
        toggleModal({
          status: 'failed',
          title: title,
          subtitle: message,
        })
      );
    },
    [dispatch]
  );

  const {
    file,
    fileName,
    handleFileUploaded,
    handleRemoveFile,
    handleUploadClick,
    fileDialogRef: csvFileDialogRef,
  } = useCsvData({
    handleError: promptError,
    useRawFile: true,
  });

  const {
    isLoading,
    list: businesses,
    selectedItem: selectedBusiness,
    handleSelectItem: handleBusinessChange,
    handleSearchItem: handleSearchBusiness,
  } = useBusinessList();
  const handleSubmit = async () => {
    const blob = file.slice(0, file.size, 'file');
    const newFile = new File([blob], fileName, { type: 'file' });
    const data = new FormData();
    data.append('category', 'MACKAS');
    data.append('file', newFile);
    try {
      dispatch({ type: SPINNER_TOGGLE_ON });
      await uploadTokenByCSV(file, selectedBusiness.businessId, 'MACKAS', {
        externalIds: 'id',
        uniqueExternalIds: 'id',
      });
      dispatch(
        toggleModal({
          status: 'success',
          title: 'Upload CSV',
          subtitle: 'Upload success',
          button: 'Close',
        })
      );
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Close',
        })
      );
    } finally {
      dispatch({ type: SPINNER_TOGGLE_OFF });
    }
  };

  return (
    <PageHeader
      config={{
        title: 'Upload Mackas Showcase Form',
        margin: 60,
        back: true,
      }}
    >
      <Grid container item>
        <CSVUploadInput
          fileName={fileName}
          onFileRemoved={handleRemoveFile}
          onFileUploaded={handleFileUploaded}
          onUploadClick={handleUploadClick}
          ref={csvFileDialogRef}
        />
      </Grid>
      <Grid container item xs={4}>
        <SearchDropdown
          title='Business Name'
          inputLabel='Search Business'
          options={businesses.map((b) => ({
            label: b.companyName,
            value: b.businessId,
          }))}
          onValueChange={(value) => handleBusinessChange(value)}
          isLoading={isLoading}
          onSearch={handleSearchBusiness}
        />
      </Grid>
      <MyButton
        text={'Sumbit'}
        disabled={!file || !selectedBusiness}
        variant='contained'
        onClick={handleSubmit}
      />
    </PageHeader>
  );
};

export default UploadMackas;
