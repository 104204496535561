export const getAPIList = (tokenService, csvService, webhookService, currentDev) => {
  if (currentDev === 'local') {
    tokenService = "http://localhost:3000/api"
    csvService = "http://localhost:3000/api"
    webhookService = "http://localhost:3000/api"
    return {
      GET: {
        //walletLogin
        getQR: `${tokenService}/auth/scanLogin`,
        socketURL: `${tokenService}`,
        // Asset related APIs
        getAsset: `${csvService}/asset/activities/uploadcsv`,
        // Document related APIs
        getDocuments: `${csvService}/document`,
        getAngus: `${csvService}/angus/`,

        // Report related API
        getPICReport: `${csvService}/reports/individual-animals`,
        getWeightHistoryReport: `${csvService}/reports/weight-history`,
        getWeightGainReport: `${csvService}/reports/weight-gain`,
        getMovementReport: `${csvService}/reports/monthlyMovementReport`,
        getTransferReport: `${csvService}/reports/monthlyTransferReport`,
        getWeightGroupReport: `${csvService}/reports/weight-group`,
        getPreSaleReport: `${csvService}/report/presale`,
        getTreatmentReport: `${csvService}/reports/treatment`,
        getCeresTagReport: `${csvService}/reports/ceresTag`,
        //Plugin
        getPlugin: `${csvService}/dev/plugin`,

        //Angus
        getAngusAccounts: `${csvService}dev/angusAccounts`,

        // transactions
        getLatestTransactions: `${csvService}/dev/token/tokens/latestTransactions`,

        //QA
        getTestingAccount: `${tokenService}/api/dev/qa/account`,
        getTestingAccountById: `${tokenService}/api/dev/qa/account/individual`,
        getAllTokenCountSummary:`${tokenService}/dev/token/getAllTokenCountSummary` ,

        //Session
        getSessions: `${tokenService}/dev/session`,
        getSessionGroups: `${tokenService}/dev/group`,

        //killsheet
        getKillSheet: `${tokenService}/dev/killsheet`,

        // Business Plugins
        getAllBusinessPluginsInfo: `${tokenService}/dev/getBusinessPlugin`,
      },
      POST: {
        // Token related APIs
        createToken: `${csvService}/token/tokens`,
        createActivity: `${csvService}/token/activities`,
        getTokenbyAddr: `${csvService}/token/tokens/userid`,
        getBusinessbyToken: `${csvService}/token/tokens/tokenid`,
        getTokenbyTokenId: `${csvService}/token/tokens/tokenid`,
        getTokenByTokenIdsSkipUser: `${csvService}/dev/token/tokens/tokenid`,
        getTokenbyExternalId: `${csvService}/token/tokens/externalid`,
        getTokenbyBlockchainHash: `${csvService}/token/tokens/blockchainhash`,
        getTokenByBusiness: `${csvService}/token/tokens/businessid`,
        getFilteredToken: `${csvService}/reports/animalReport`,
        uploadTokenCSV: `${csvService}/dev/token/tokens/tokenCSV`,
        // Asset related APIs
        postCSV: `${csvService}/token/tokens/csv`,
        updateAssetsByCSV: `${csvService}/token/activities/csv`,
        exportReport: `${csvService}/token/tokens/report`,
        getCSVbyUser: `${csvService}/token/tokens/externalid`,
        // Product related APIs
        uploadTos3: `${csvService}/uploader/s3`,
        // Validate PIC,LPA and NLIS
        validate: `${csvService}/nlis/validation`,
        // Document related APIs,
        postDocument: `${csvService}/document`,
        //Augus
        angus: `${csvService}/angus`,
        deleteAssetToken: `${csvService}/dev/angusAccounts/deleteAssets`,
        // Auth
        createUserWithAuth: `${tokenService}/auth/register`,
        authUser: `${tokenService}/auth/login`,
        refreshToken: `${tokenService}/auth/dev/refresh-token`,
        revokeToken: `${tokenService}/auth/dev/revoke-token`,
        googleLogin: `${tokenService}/auth/dev/google`,
        // Plugin
        createPlugin: `${tokenService}/dev/plugin`,
        //optiweigh
        optiweigh: `${tokenService}/job/optiweigh`,
        //Remove asset by RFID
        deleteAsset: `${tokenService}/dev/deleteAsset`,
        // Get tokens by field
        getTokensByField: `${tokenService}/dev/search`,
        // Delete Business User
        deleteBusiness: `${tokenService}/dev/business/delete`,
        // Set User Status
        setUserStatus: `${tokenService}/dev/user/status`,
        // Delete User
        deleteUser: `${tokenService}/dev/user/delete`,
        //Get Angus Animals
        getAngusTotal: `${tokenService}/dev/angusTotal`,
        //Get Active Business
        getActiveBusiness: `${tokenService}/dev/business`,
        //Get Promotion Count
        promotionCount: `${tokenService}/dev/promotion/promotionCount`,
        //Add user invitation
        addUserInvitation: `${tokenService}/dev/token/addUserInvitation`,
        userResendInvitation: `${tokenService}/dev/token/userResendInvitation`,
        updateUserInfo: `${tokenService}/dev/token/updateUserInfo`,

        fetchTokenDetails: `${tokenService}/dev/token/fetchTokenDetails`,

        // Webhook
        ambioxera: {
          insertBag: `${webhookService}/api/ambioxera/bag/upsert`,
          processing: `${webhookService}/api/ambioxera/processing`,
          rejection: `${webhookService}/api/ambioxera/rejection`,
        },
        // Qa
        getTestingAccount: `${tokenService}/api/dev/qa/account`,
        qaLogin: `${tokenService}/api/auth/qa/login`,

        //Session
        createSession: `${tokenService}/dev/session`,
        generateCSV: `${tokenService}/dev/generate/csv`
      },
      PUT: {
        // Asset related APIs
        updateAsset: `${csvService}/asset/assets/csv`,
        // Plugin
        updatePlugin: `${tokenService}/dev/plugin`,
      },
      DELETE: {
        //delete plugin
        deletePlugin: `${tokenService}/dev/plugin`,
        deleteToken: `${tokenService}/dev/token/deleteToken`,

        // BusinessPlugin
        devRemoveBusinessPlugin: `${tokenService}/dev/removeBusinessPlugin/`
      },
    };
  } else {
    return {
      GET: {
        //walletLogin
        getQR: `${tokenService}/auth/scanLogin`,
        socketURL: `${tokenService}`,
        // Asset related APIs
        getAsset: `${csvService}/api/asset/activities/uploadcsv`,
        // Document related APIs
        getDocuments: `${csvService}/api/document`,
        getAngus: `${csvService}/api/angus/`,

        // Report related API
        getPICReport: `${csvService}/api/reports/individual-animals`,
        getWeightHistoryReport: `${csvService}/api/reports/weight-history`,
        getWeightGainReport: `${csvService}/api/reports/weight-gain`,
        getMovementReport: `${csvService}/api/reports/monthlyMovementReport`,
        getTransferReport: `${csvService}/api/reports/monthlyTransferReport`,
        getWeightGroupReport: `${csvService}/api/reports/weight-group`,
        getPreSaleReport: `${csvService}/api/report/presale`,
        getTreatmentReport: `${csvService}/api/reports/treatment`,
        getCeresTagReport: `${csvService}/api/reports/ceresTag`,
        //Plugin
        getPlugin: `${csvService}/api/dev/plugin`,

        //Angus
        getAngusAccounts: `${csvService}/api/dev/angusAccounts`,

        // transactions
        getLatestTransactions: `${csvService}/api/dev/token/tokens/latestTransactions`,

        // Qa
        getTestingAccount: `${tokenService}/api/dev/qa/account`,
        getTestingAccountById: `${tokenService}/api/dev/qa/account/individual`,
        getAllTokenCountSummary:`${tokenService}/api/dev/token/getAllTokenCountSummary` ,

        //Alert
        getAlert: `${tokenService}/api/dev/alerts`,

        //Session
        getSessions: `${tokenService}/api/dev/session`,
        getSessionGroups: `${tokenService}/api/dev/group`,

        //killsheet
        getKillSheet: `${tokenService}/api/dev/killsheet`,

        // Business Plugins
        getAllBusinessPluginsInfo: `${tokenService}/api/dev/getBusinessPlugin`,
      },
      POST: {
        // Token related APIs
        createToken: `${csvService}/api/token/tokens`,
        createActivity: `${csvService}/api/token/activities`,
        getTokenbyAddr: `${csvService}/api/token/tokens/userid`,
        getBusinessbyToken: `${csvService}/api/token/tokens/tokenid`,
        getTokenbyTokenId: `${csvService}/api/token/tokens/tokenid`,
        getTokenByTokenIdsSkipUser: `${csvService}/api/dev/token/tokens/tokenid`,
        getTokenbyExternalId: `${csvService}/api/token/tokens/externalid`,
        getTokenbyBlockchainHash: `${csvService}/api/token/tokens/blockchainhash`,
        getTokenByBusiness: `${csvService}/api/token/tokens/businessid`,
        getFilteredToken: `${csvService}/api/reports/animalReport`,
        uploadTokenCSV: `${csvService}/api/dev/token/tokens/tokenCSV`,
        // Asset related APIs
        postCSV: `${csvService}/api/token/tokens/csv`,
        updateAssetsByCSV: `${csvService}/api/token/activities/csv`,
        exportReport: `${csvService}/api/token/tokens/report`,
        getCSVbyUser: `${csvService}/api/token/tokens/externalid`,
        // Product related APIs
        uploadTos3: `${csvService}/api/uploader/s3`,
        // Validate PIC,LPA and NLIS
        validate: `${csvService}/api/nlis/validation`,
        // Document related APIs,
        postDocument: `${csvService}/api/document`,
        //Augus
        angus: `${csvService}/api/angus`,
        deleteAssetToken: `${csvService}/api/dev/angusAccounts/deleteAssets`,
        // Auth
        createUserWithAuth: `${tokenService}/api/auth/register`,
        authUser: `${tokenService}/api/auth/login`,
        refreshToken: `${tokenService}/api/auth/dev/refresh-token`,
        revokeToken: `${tokenService}/api/auth/dev/revoke-token`,
        googleLogin: `${tokenService}/api/auth/dev/google`,
        // Plugin
        createPlugin: `${tokenService}/api/dev/plugin`,
        //optiweigh
        optiweigh: `${tokenService}/api/job/optiweigh`,
        //Remove asset by RFID
        deleteAsset: `${tokenService}/api/dev/deleteAsset`,
        //Set asset status by RFID
        setTokenStatus: `${tokenService}/api/dev/setTokenStatus`,
        // Get tokens by field
        getTokensByField: `${tokenService}/api/dev/search`,
        // Delete Business User
        deleteBusiness: `${tokenService}/api/dev/business/delete`,
        // Set User Status
        setUserStatus: `${tokenService}/api/dev/user/status`,
        // Delete User
        deleteUser: `${tokenService}/api/dev/user/delete`,
        //Get Angus Animals
        getAngusTotal: `${tokenService}/api/dev/angusTotal`,
        //Get Active Business
        getActiveBusiness: `${tokenService}/api/dev/business`,
        //Get Promotion Count
        promotionCount: `${tokenService}/api/dev/promotion/promotionCount`,
        //Add user invitation
        addUserInvitation: `${tokenService}/api/dev/token/addUserInvitation`,
        userResendInvitation: `${tokenService}/api/dev/token/userResendInvitation`,
        updateUserInfo: `${tokenService}/api/dev/token/updateUserInfo`,

        fetchTokenDetails: `${tokenService}/api/dev/token/fetchTokenDetails`,

        // Webhook
        ambioxera: {
          insertBag: `${webhookService}/api/ambioxera/bag/upsert`,
          processing: `${webhookService}/api/ambioxera/processing`,
          rejection: `${webhookService}/api/ambioxera/rejection`,
        },

        // Qa
        getTestingAccount: `${tokenService}/api/dev/qa/account`,
        qaLogin: `${tokenService}/api/auth/qa/login`,

        //Alert
        createAlert: `${tokenService}/api/dev/alerts`,

        //Session
        createSession: `${tokenService}/api/dev/session`,
        generateCSV: `${tokenService}/api/dev/generate/csv`
      },
      PUT: {
        // Asset related APIs
        updateAsset: `${csvService}/api/asset/assets/csv`,
        // Plugin
        updatePlugin: `${tokenService}/api/dev/plugin`,
        // Qa
        getTestingAccount: `${tokenService}/api/dev/qa/account`,
        // Alert
        updateAlert: `${tokenService}/api/dev/alerts/`,
      },
      DELETE: {
        //delete plugin
        deletePlugin: `${tokenService}/api/dev/plugin`,
        deleteToken: `${tokenService}/api/dev/token/deleteToken`,
        deleteTokenV2: `${tokenService}/api/dev/token/deleteTokenV2`,
        // Qa
        getTestingAccount: `${tokenService}/api/dev/qa/account`,

        //Alert
        deleteAlert: `${tokenService}/api/dev/alerts/`,

        // BusinessPlugin
        devRemoveBusinessPlugin: `${tokenService}/api/dev/removeBusinessPlugin/`
      },
    };
  }
};
