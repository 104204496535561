import { AppThunk } from '../../store/types';
import { FETCH_USERS,BUSINESS_INFO } from './types';
import { SPINNER_TOGGLE_ON,SPINNER_TOGGLE_OFF } from '../spinner/types';
import { WebErrorType } from '../../utils/error';
import { callAPI, getAPI } from '../../utils/network';
import CONSTANT from '../../config/constant';
import { toggleModal } from '../modal/actions';

type UserInfo = {
    userId: string;
    name: string;
    role: string;
    phone: string;
    email: string;
    status: string;
    statusChange: string;
    roleChange: string;
    invitationCode?: string;
};

async function dev_updateUserInfo(businessId:string,info:UserInfo){
    let data = { 
        businessId: businessId,
        name: info.name,
        phone: info.phone,
        email: info.email,
        invitationCode: info.invitationCode,
    }

    const resp =  await callAPI({
        url: getAPI().POST.updateUserInfo,
        method: 'POST',
        data: data,

    });
    return resp;
}

async function dev_addUser(businessId:string, info:UserInfo): Promise<any> {
    let data = { 
        businessId: businessId,
        name: info.name,
        role: info.role,
        phone: info.phone,
        email: info.email,
    }

    const resp =  await callAPI({
        url: getAPI().POST.addUserInvitation,
        method: 'POST',
        data: data,
    });
    return resp;
}


async function dev_userResendInvitation(businessId:string, invitationCode:string): Promise<any> {
    let data = { 
        businessId: businessId,
        invitationCode: invitationCode,
    }

    const resp =  await callAPI({
        url: getAPI().POST.userResendInvitation,
        method: 'POST',
        data: data,

    });
    return resp;
}

async function dev_fetchAllUser(businessId: string): Promise<any> {
    const businessReponse = await callAPI({
        url: getAPI().POST.getTokenbyExternalId,
        method: 'POST',
        data: { externalIds: [{ agliveToken: businessId }]},
    });
    return (businessReponse[0]);
}


export function updateBusinessInfo(businessId: string): AppThunk<Promise<void>>{
    return async (dispatch) => {
        try{
            dispatch({type: SPINNER_TOGGLE_ON});
        } catch (e) {
            dispatch({
                type: BUSINESS_INFO,
                payload: {},
            });
            
        } finally {

        }
    }
}

export function updateUserListAndBusinessInfo(businessId: string): AppThunk<Promise<void>> {
    return async (dispatch) => {
        try{
            dispatch({type: SPINNER_TOGGLE_ON});

            /* Clear list, incase of any error, should not show list to user */
            dispatch({
                type: FETCH_USERS,
                payload: [],
            });
            dispatch({
                type: BUSINESS_INFO,
                payload: {
                    businessName: '',
                    businessId: ''
                },
            });

            const response:any = await dev_fetchAllUser(businessId);
                
            let users = response.details.users.filter((u) => ['admin','user','read-only'].includes(u.role));
            let businessName =  response.details.companyName;

            dispatch({
                type: FETCH_USERS,
                payload: users,
            });
            dispatch({
                type: BUSINESS_INFO,
                payload: {
                    businessName: businessName,
                    businessId: businessId
                },
            });
    
        } catch(e){
            
        } finally {
            dispatch({type: SPINNER_TOGGLE_OFF});
        }
       
    }
}


export function inviteUserAgain(
    businessId: string,
    invitationCode: string,
    successCB: () => void,
): AppThunk<Promise<void>>  {
    return async (dispatch, getState) => {
        try{
            dispatch({type: SPINNER_TOGGLE_ON});
            const res = await dev_userResendInvitation(businessId,invitationCode)

            dispatch(
                toggleModal({
                status: 'success',
                title: CONSTANT.EN_LABELS.invited,
                button: CONSTANT.EN_LABELS.close,
                subtitle: `${ CONSTANT.EN_LABELS.invitedMessage} ${res.data.invitationCode}`,
                CTAHandler: successCB,
                }),
            );
        }
        catch(e){

        }
        finally{
            dispatch({type: SPINNER_TOGGLE_OFF});
        }

    }

    
}

export function submitProject(
    userInfo: UserInfo,
    businessId: string,
    successCB: () => void,
  ): AppThunk<Promise<void>> {
    return async (dispatch, getState) => {
        try {
            dispatch({type: SPINNER_TOGGLE_ON});

            if (userInfo.status && userInfo.status !== 'new user') {
                if(userInfo.status === CONSTANT.EN_LABELS.pending){
                    userInfo.invitationCode = userInfo.userId
                }
         
                let resp = await dev_updateUserInfo(businessId,userInfo)
                
                dispatch(
                    toggleModal({
                    status: 'success',
                    title: CONSTANT.EN_LABELS.updated,
                    button: CONSTANT.EN_LABELS.close,
                    CTAHandler: successCB,
                    }),
                );
            
            } else {
                const res: any = await dev_addUser(businessId,userInfo)
                
                if(res.errorStatus === 0 ){
                    dispatch(
                        toggleModal({
                        status: 'success',
                        title: CONSTANT.EN_LABELS.invited,
                        button: CONSTANT.EN_LABELS.close,
                        subtitle: `${ CONSTANT.EN_LABELS.invitedMessage} ${res.data.invitationCode}`,
                        CTAHandler: successCB,
                        }),
                    );
                } else {
                    dispatch(
                        toggleModal({
                        status: 'failed',
                        title: CONSTANT.EN_LABELS.invited,
                        button: CONSTANT.EN_LABELS.close,
                        subtitle: `${ res.message.title } ${res.message.status} - ${res.message.details}`,
                        }),
                    );
                }
            }
        } catch (e) {
            const error = e as WebErrorType;
            dispatch(
                toggleModal({
                    status: 'failed',
                    title: error.title,
                    subtitle: error.message,
                }),
            );
            
        } finally {
            dispatch({type: SPINNER_TOGGLE_OFF});
        }
    };
}


