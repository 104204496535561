import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
    FormControl,
    TextField,
    Typography,
    makeStyles,
    Box,
} from "@material-ui/core";
import COLOR from "../../styled/colors";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import MuiIconButton, { IconButtonProps } from "@mui/material/IconButton";
import AddIcon from "@material-ui/icons/Add";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ClearIcon from "@material-ui/icons/Clear";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import withHeader from "../../presentation/withHeader";
import MyButton from "../../presentation/button";

import { createAccount } from "../../store/qa/actions";
import { toggleModal } from '../../store/modal/actions';

const useStyle = makeStyles((theme) => ({
    button: {
        color: COLOR.GREEN_BUTTON,
        borderColor: COLOR.GREEN_BUTTON,
        marginRight: 50,
    },
}));

enum TokenType {
    asset = "asset",
    plant = "plant"
}

type Token = {
    type: TokenType;
    quantity: number;
    location: string;
};

const COLOR_VARIANTS: Record<IconButtonProps['color'], string> = {
    'success': COLOR.GREENT_TEXT,
    'inherit': 'inherit',
    'primary': COLOR.GREEN,
    'secondary': COLOR.BLACK,
    'error': COLOR.RED,
    'default': "black",
    'info': 'blue',
    'warning': COLOR.ORANGE
}

const IconButton = ({ children, color = 'default', size = 'small', sx, ...props}: IconButtonProps) => {
    return (
        <MuiIconButton
            sx={{
                color: COLOR_VARIANTS[color],
                border: `1px solid ${COLOR_VARIANTS[color]}`,
                '&:hover': {
                    color: 'white',
                    backgroundColor: COLOR_VARIANTS[color],
                },
                ...sx,
            }}
            size={size}
            {...props}
        >
            {children}
        </MuiIconButton>
    );
}

const CreateAccount: React.FC = () => {
    const classes = useStyle();
    const dispatch = useDispatch();
    const history = useHistory();

    const [businessName, setBusinessName] = useState<string>("")
    const [accountType, setAccountType] = useState<"animal"|"plant"|"product">("animal");
    const [locations, setLocations] = useState<string[]>([]);
    const [tokens, setTokens] = useState<Token[]>([]);
    const [tokenScope, setTokenScope] = useState<TokenType[]>([])
    const [tokenOptions, setTokenOptions] = useState<
        { display: string; value: string }[]
    >([]);

    //general
    const handleBusinessNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setBusinessName(e.currentTarget.value)
    }

    //locations
    const handleAddLocation = () => {
        const newLocations: string[] = [...locations, ""];
        setLocations(newLocations);
    };

    const handleRemoveLocation = (e: any) => {
        const index = e.currentTarget.value;
        const newLocations = locations.filter((location, idx) => {
            return idx !== parseInt(index);
        });

        setLocations(newLocations);
    };

    const handlePicChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const index = parseInt(
            e.target.parentElement.parentElement.getAttribute("data-index")
        );

        const newLocations = [...locations];
        newLocations[index] = e.target.value;
        setLocations(newLocations);
    };

    //tokens

    
    const refreshToken = () => {
        

        const newTokens: Token[] = tokens.map((token)=>{
            if (tokenScope.includes(token.type)) {
                return token
            }

            token.type = tokenScope[0]
            return token
        })

        setTokens(newTokens);
    }

    const handleAddToken = () => {
        

        const newTokens: Token[] = [
            ...tokens,
            {
                type: tokenScope[0],
                quantity: 0,
                location: "" 
            }
        ]

        setTokens(newTokens);
    };

    const handleRemoveToken = (e: any) => {
        const index = e.currentTarget.value;
        const newTokens = tokens.filter((token, idx) => {
            return idx !== parseInt(index);
        });

        setTokens(newTokens);
    };

    const handleChangeAccountType = (e) => {
        setAccountType(e.currentTarget.value);
    };

    const handleTokenTypeChange = (event: SelectChangeEvent) => {
        const component = event.target.value.split("-");
        const index = parseInt(component[0]);
        const newTokens = [...tokens];
        newTokens[index].type = component[1] as TokenType;
        setTokens(newTokens);
    };

    const handleTokenLocationChange = (event: SelectChangeEvent) => {
        const component = event.target.value.split("-");
        const index = parseInt(component[0]);
        const newTokens = [...tokens];
        newTokens[index].location = component[1];
        setTokens(newTokens);
    };

    const handleTokenQtyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const index = parseInt(
            e.target.parentElement.parentElement.getAttribute("data-index")
        );

        const newTokens = [...tokens];
        let value = newTokens[index].quantity;
        try {
            value = parseInt(e.target.value);
        } catch (error) {
            dispatch(
                toggleModal({
                    status: 'failed',
                    title: "Internal Error",
                    subtitle: "Number only",
                    button: 'Close'
                }),
            )
            return;
        }
        newTokens[index].quantity = value;
        setTokens(newTokens);
    };

    const onSubmit = () => {

        if (!businessName) {
            dispatch(
                toggleModal({
                    status: 'failed',
                    title: "Internal Error",
                    subtitle: "Please make sure you fill in your business name",
                    button: 'Close'
                }),
            )
            return;
        }

        if (tokens.length !== 0 && locations.length === 0) {
            dispatch(
                toggleModal({
                    status: 'failed',
                    title: "Internal Error",
                    subtitle: "Please fill in at least one location if you plant to insert asset",
                    button: 'Close'
                }),
            )
            return;
        }

        const isLocationEmpty =
            locations.length === 0
                ? false
                : !locations.every((location) => location);

        if (isLocationEmpty) {
            dispatch(
                toggleModal({
                    status: 'failed',
                    title: "Internal Error",
                    subtitle: "Please make sure you fill in every pic address",
                    button: 'Close'
                }),
            )
            return;
        }

        const isAnyAssetLocationEmpty =
            tokens.length === 0
                ? false
                : tokens.find((token) => !token.location);

        if (isAnyAssetLocationEmpty) {
            dispatch(
                toggleModal({
                    status: 'failed',
                    title: "Internal Error",
                    subtitle: "Please make sure you fill in every pic address for asset",
                    button: 'Close'
                }),
            )
            return;
        }

        const payload = {
            businessName,
            accountType,
            locations,
            tokens,
        };

        dispatch(
            createAccount(payload, () => {
                history.goBack();
            })
        );

        console.log(payload);
    };

    useEffect(() => {
        if (accountType === "animal") {
            setTokenOptions([
                {
                    display: "Animal",
                    value: "asset",
                },
            ]);
            setTokenScope([TokenType.asset])
        } else if (accountType === "plant") {
            setTokenOptions([
                {
                    display: "Plant",
                    value: "plant",
                },
            ]);
            setTokenScope([TokenType.plant])
        } else {
            setTokenOptions([]);
            setTokenScope([])
        }
    }, [accountType]);

    return (
        <Box display={'flex'} flexDirection={'column'} gridRowGap={'1em'}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <MyButton
                    text={"Generate"}
                    variant="outlined"
                    buttonClass={classes.button}
                    onClick={onSubmit}
                />
            </Box>
            <Box sx={{ marginTop: 10, marginBottom: 10 }}>
                <Typography variant="h2" style={{fontSize: '1.5rem'}} gutterBottom>Business Name</Typography>
                <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    value={businessName}
                    onChange={handleBusinessNameChange}
                />
            </Box>
            <Box sx={{ marginTop: 10, marginBottom: 10 }}>
                <FormControl>
                    <Typography variant="h2" style={{fontSize: '1.5rem'}}>Industrial Template</Typography>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        sx={{ marginTop: 1}}
                    >
                        <FormControlLabel
                            value="animal"
                            control={<Radio />}
                            label="Asset Business Account"
                            checked={accountType === "animal"}
                            onChange={handleChangeAccountType}
                        />
                        <FormControlLabel
                            value="plant"
                            control={<Radio />}
                            label="Cannabis Business Account"
                            checked={accountType === "plant"}
                            onChange={handleChangeAccountType}
                        />
                        <FormControlLabel
                            value="product"
                            control={<Radio />}
                            label="Product Business Account"
                            checked={accountType === "product"}
                            onChange={handleChangeAccountType}
                        />
                    </RadioGroup>
                </FormControl>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: 'row',
                    alignItems: "center",
                    gridColumnGap: '1rem',
                }}
            >
                <Typography variant="h2" style={{fontSize: '1.5rem'}}>Location</Typography>
                <IconButton
                    type='button'
                    role='button'
                    aria-labelledby="deleteLocation"
                    onClick={handleAddLocation}
                    color='success'
                >
                    <AddIcon />
                </IconButton>
            </Box>
            <Box sx={{ marginBottom: 10 }}>
                <List
                    disablePadding={true}
                    component={'ul'}
                    role='list'
                    aria-labelledby='locations'
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.5rem',
                    }}
                >
                    {locations.map((location, index) => (
                        <ListItem
                            component={'li'}
                            role='listitem'
                            key={index}
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                gap: '1rem',
                                padding: 0
                            }}
                        >
                            <TextField
                                fullWidth
                                id="picAddress"
                                label="PIC Address"
                                variant="outlined"
                                type="text"
                                value={location}
                                data-index={index}
                                onChange={handlePicChange}
                            />
                            <IconButton
                                aria-labelledby="deleteLocation"
                                onClick={handleRemoveLocation}
                                value={index}
                                color='error'
                            >
                                <ClearIcon />
                            </IconButton>
                        </ListItem>
                    ))}
                </List>
            </Box>
            {accountType != "product" && (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginBottom: 10,
                    }}
                >
                    <Typography variant="h2" style={{fontSize: '1.5rem'}}>Additional</Typography>
                    <IconButton
                        aria-label="delete"
                        onClick={handleAddToken}
                        sx={{
                            backgroundColor: "gray",
                            marginLeft: 3,
                            marginRight: 3,
                        }}
                    >
                        <AddIcon />
                    </IconButton>
                </Box>
            )}
            <Box sx={{ marginTop: 10, marginBottom: 10 }}>
                <TableContainer component={Box}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        {tokens.length !== 0 && (
                            <TableHead>
                                <TableRow sx={{ backgroundColor: COLOR.GRAY_SOLID }}>
                                    <TableCell sx={{ fontWeight: 'bold' }}>No</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }} align="left">Type</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }} align="left">Pic</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }} align="left">Quantity</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
                                </TableRow>
                            </TableHead>
                        )}
                        <TableBody>
                            {tokens.map((token, index) => (
                                <TableRow
                                    key={index}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell align="center">
                                        <FormControl variant="filled" fullWidth>
                                            <Select
                                                labelId="demo-simple-select-filled-label"
                                                value={`${index}-${token.type}`}
                                                onChange={handleTokenTypeChange}
                                                fullWidth
                                            >
                                                {tokenOptions.map(
                                                    (option, idx) => {
                                                        return (
                                                            <MenuItem
                                                                value={`${index}-${option.value}`}
                                                            >
                                                                {option.display}
                                                            </MenuItem>
                                                        );
                                                    }
                                                )}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell align="center">
                                        <FormControl variant="filled" fullWidth>
                                            <Select
                                                labelId="demo-simple-select-filled-label"
                                                value={`${index}-${token.location}`}
                                                onChange={
                                                    handleTokenLocationChange
                                                }
                                                fullWidth
                                            >
                                                {locations.map(
                                                    (location, idx) => {
                                                        return (
                                                            <MenuItem
                                                                value={`${index}-${location}`}
                                                            >
                                                                {location}
                                                            </MenuItem>
                                                        );
                                                    }
                                                )}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell align="center">
                                        <TextField
                                            variant="outlined"
                                            type="number"
                                            value={token.quantity}
                                            data-index={index}
                                            onChange={handleTokenQtyChange}
                                            fullWidth
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            role='button'
                                            type='button'
                                            aria-labelledby='deleteToken'
                                            onClick={handleRemoveToken}
                                            value={index}
                                            color='error'
                                            sx={{
                                                marginLeft: 3,
                                                marginRight: 3,
                                            }}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* <List
                    sx={{
                        width: "100%",
                        bgcolor: "background.paper",
                    }}
                >
                    {tokens.map((token, index) => (
                        <ListItem
                            key={index}
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                border: "1px solid",
                            }}
                        >
                            <Box sx={{ width: 150 }}>
                                <FormControl variant="filled" fullWidth>
                                    <InputLabel id="demo-simple-select-filled-label">
                                        Type
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-filled-label"
                                        value={`${index}-${token.type}`}
                                        onChange={handleTokenTypeChange}
                                    >
                                        <MenuItem value={`${index}-asset`}>
                                            Animal
                                        </MenuItem>
                                        <MenuItem value={`${index}-plant`}>
                                            Plant
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box>
                                <TextField
                                    id="outlined-basic"
                                    label="Quantity"
                                    variant="filled"
                                    type="number"
                                    value={token.quantity}
                                    data-index={index}
                                    onChange={handleTokenQtyChange}
                                />
                                <IconButton
                                    aria-label="delete"
                                    onClick={handleRemoveToken}
                                    value={index}
                                    sx={{
                                        backgroundColor: "gray",
                                        marginLeft: 3,
                                        marginRight: 3,
                                    }}
                                >
                                    <ClearIcon />
                                </IconButton>
                            </Box>
                        </ListItem>
                    ))}
                </List> */}
            </Box>
        </Box>
    );
};

export default withHeader(
    {
        title: "Create Account",
        margin: 60,
        back: true,
    },
    CreateAccount
);
