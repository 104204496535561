import React from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { Typography, IconButton } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Panel } from "../styled/components";
import COLOR from "../styled/colors";
import VerifiedIcon from "./renderVerifiedIcon";

type headerConfigObject = {
    title: string | React.FC;
    margin?: number;
    back?: boolean;
    isAngus?: boolean;
};

interface iPageProps {
    config: headerConfigObject;
}

// to be used when the config object requires access to internal variable of the child component
export const PageHeader: React.FC<iPageProps> = ({ children, config }) => {
    const history = useHistory();
    const currentURL = useLocation();
    config.isAngus =
        config.isAngus ?? currentURL.pathname.includes("/verified");
    const backButton = config.back ? (
        <IconButton
            aria-label="back"
            onClick={history.goBack}
            style={{ marginBottom: 5 }}
        >
            <ArrowBackIosIcon style={{ paddingLeft: 5, marginRight: "-5" }} />
        </IconButton>
    ) : undefined;

    return (
        <Panel>
            <HeaderContainer>
                <Typography variant="h2">
                    {backButton}
                    {` ${config.title}`}
                </Typography>
                {config.isAngus && (
                    <ImageContainer>
                        <VerifiedIcon style={{ width: 110, height: 110 }} />
                    </ImageContainer>
                )}
            </HeaderContainer>
            <ComponentContainer margin={config.margin}>
                {children}
            </ComponentContainer>
        </Panel>
    );
};

// simple HOC that can be used by passed a static configObj and Component
const withHeader = (config: headerConfigObject, Component) => (props) =>
    (
        <PageHeader config={config}>
            <Component {...props} />
        </PageHeader>
    );

const HeaderContainer = styled.div`
    border-width: 1px;
    border-style: none none solid none;
    border-color: ${COLOR.GRAY_BORDER};
    padding-bottom: 10px;
    position: relative;
`;

const ComponentContainer = styled.div`
    margin-top: ${(props) => props.margin || 30}px;
`;

const ImageContainer = styled.div`
    position: absolute;
    bottom: -45px;
    right: 0;
    padding-left: 20px;
    background-color: ${COLOR.WHITE};
`;

export default withHeader;
