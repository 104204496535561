import React, { useState, useEffect } from "react";
import {
    Typography,
    makeStyles,
    IconButton,
    Box,
} from "@material-ui/core";
import { useHistory, useRouteMatch, useParams } from "react-router-dom";
import COLOR from "../../styled/colors";

import { fetchAccountById } from "../../store/qa/actions";
import InfoIcon from '@material-ui/icons/Info';

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import withHeader from "../../presentation/withHeader";
import { Accordion } from "../../presentation/Accordion/Accordion";
import { useAppDispatch } from "../../utils/hooks";

const useStyle = makeStyles((theme) => ({
    button: {
        color: COLOR.GREEN_BUTTON,
        borderColor: COLOR.GREEN_BUTTON,
        marginRight: 50,
    },
}));

const AccountInfo: React.FC = () => {
    const classes = useStyle();
    const { id } = useParams<{ id?: string }>();
    // const history = useHistory();
    const dispatch = useAppDispatch();
    const [keyword, setKeyword] = useState<string>("loading...");
    const [userprofile, setUserprofile] = useState<any>({});
    const [businessProfile, setBusinessProfile] = useState<any>({});
    const [assets, setAssets] = useState<any[]>([]);

    const getData = async () => {
        const accountProfile: any = await dispatch(fetchAccountById(id));

        setKeyword(accountProfile.keySecret);
        setUserprofile(accountProfile.userDetails);
        setBusinessProfile(accountProfile.businessDetails);

        const companyAssets = accountProfile.assets.map((asst) => {
            const element = asst.externalIds.find(
                (externalId) => externalId.agliveToken
            );

            return {
                type: asst.type,
                id: element ? element.agliveToken : "",
                details: asst.details,
            };
        });

        setAssets(companyAssets);
    };

    useEffect(() => {
        if (!id) {
            return;
        }

        getData();
    }, []);

    return (
        <Box>
            <Box marginBottom={'2.5rem'} display={'flex'} flexDirection={'column'} gridRowGap={'0.5rem'} >
                <Typography variant="h4">12 Words</Typography>
                <Box gridColumnGap={'0.5rem'} border={`1px solid ${COLOR.GRAY_BORDER}`} padding={'1.25rem'}>
                    <Typography key='word' variant="inherit" style={{color: '#aa12aa'}}>{keyword}</Typography>
                </Box>
            </Box>
            <Accordion>
                <Accordion.Summary
                    aria-controls="panel1a-content"
                >
                    <Typography>User Details</Typography>
                </Accordion.Summary>
                <Accordion.Details>
                    <Box display={'flex'} flexDirection={'column'} gridRowGap={20}>
                        <Box>
                            <Typography variant="h4">Name</Typography>
                            <Typography>{userprofile.name}</Typography>
                        </Box>
                        <Box>
                            <Typography variant="h4">Mobile Number</Typography>
                            <Typography>{userprofile.mobileNumber}</Typography>
                        </Box>
                        <Box>
                            <Typography variant="h4">Email</Typography>
                            <Typography>{userprofile.emailAddress}</Typography>
                        </Box>
                        <Box>
                            <Typography variant="h4">Role</Typography>
                            <Typography>{userprofile.role}</Typography>
                        </Box>
                    </Box>
                </Accordion.Details>
            </Accordion>
            <Accordion>
                <Accordion.Summary
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography>Business Details</Typography>
                </Accordion.Summary>
                <Accordion.Details>
                    <Box display={'flex'} flexDirection={'column'} gridRowGap={20}>
                        <Box>
                            <Typography variant="h4">Industry Type</Typography>
                            <Typography>{businessProfile.industryType}</Typography>
                        </Box>
                        <Box>
                            <Typography variant="h4">Sub Industry Type</Typography>
                            <Typography>
                                {businessProfile.subIndustryType}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="h4">Business Scope</Typography>
                            <Typography>{businessProfile.businessScope}</Typography>
                        </Box>
                        <Box>
                            <Typography variant="h4">Company Name</Typography>
                            <Typography>{businessProfile.companyName}</Typography>
                        </Box>
                        <Box>
                            <Typography variant="h4">Company Number</Typography>
                            <Typography>{businessProfile.companyNumber}</Typography>
                        </Box>
                        <Box>
                            <Typography variant="h4">Business Country</Typography>
                            <Typography>
                                {businessProfile.businessCountry}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="h4">Brand Description</Typography>
                            <Typography>
                                {businessProfile.brandDescription}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="h4">Location</Typography>
                            <TableContainer component={Box} sx={{ marginTop: 1 }}>
                                <Table
                                    sx={{ minWidth: 650 }}
                                    aria-labelledby="locations"
                                    role='table'
                                >
                                    <TableHead sx={{
                                        background: COLOR.GRAY_SOLID,
                                        
                                    }}>
                                        <TableRow>
                                            <TableCell sx={{fontWeight: 'bold'}}>No</TableCell>
                                            <TableCell sx={{fontWeight: 'bold'}}>PIC Address</TableCell>
                                            <TableCell sx={{fontWeight: 'bold'}}>Name</TableCell>
                                            <TableCell sx={{fontWeight: 'bold'}}>Country</TableCell>
                                            <TableCell sx={{fontWeight: 'bold'}}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {businessProfile.location &&
                                            businessProfile.location.map(
                                                (location, index) => (
                                                    <TableRow
                                                        key={location.name}
                                                        sx={{
                                                            "&:last-child td, &:last-child th":
                                                                { border: 0 },
                                                        }}
                                                    >
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                        >
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell>
                                                            {location.PICAddress}
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                location.locationNickname
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            {location.country}
                                                        </TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                )
                                            )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Accordion.Details>
            </Accordion>
            <Box sx={{ marginTop: 40 }}>
                <Typography variant="h4">Asset</Typography>
                <TableContainer component={Box} sx={{ marginTop: 1}}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead sx={{ backgroundColor: COLOR.GRAY_SOLID}}>
                            <TableRow>
                                <TableCell sx={{fontWeight: 'bold'}}>No</TableCell>
                                <TableCell sx={{fontWeight: 'bold'}}>Id</TableCell>
                                <TableCell sx={{fontWeight: 'bold'}}>Type</TableCell>
                                <TableCell sx={{fontWeight: 'bold'}}>PIC/Location</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {assets.map((asset, index) => (
                                <TableRow
                                    key={asset.id}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell>{asset.id}</TableCell>
                                    <TableCell>{asset.type}</TableCell>
                                    <TableCell>
                                        {asset.details.pic_id}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton aria-label="comment">
                                            <InfoIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default withHeader(
    {
        title: "Account Info",
        margin: 60,
        back: true,
    },
    AccountInfo
);
