import React, { useCallback, useState } from 'react';
import CSV from 'papaparse';
export type RowType = Record<string, any>;
type ValidateResult = {
  isValid: boolean;
  message?: string;
};

type ErrorMessage = {
  title: string;
  message: string;
};

type Props = {
  formatCsvData?: (
    data: Array<Record<string, any>>
  ) => Array<Record<string, any>>;
  validateData?: (data: Array<Record<string, any>>) => ValidateResult;
  handleError: (props: ErrorMessage) => void;
  useRawFile?: boolean;
};

const useCsvData = ({
  formatCsvData,
  validateData,
  handleError,
  useRawFile,
}: Props) => {
  const [rows, setRows] = useState<Array<RowType>>(undefined);
  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const [file, setFile] = useState<File | undefined>(undefined);

  const fileDialogRef = React.useRef<HTMLInputElement>(null);
  const handleUploadClick = useCallback(() => {
    fileDialogRef.current?.click();
  }, []);

  const handleFileUploaded = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files || e.target.files?.length === 0) {
        return handleError({
          title: 'Invalid file',
          message: "File doesn't exists",
        });
      }

      if (e.target.files[0].size > 10485760) {
        return handleError({
          title: 'Upload Failed',
          message: 'Upload image size more than 10mb',
        });
      }
      const inputFile = e.target.files[0];
      if (!inputFile) {
        return handleError({
          title: 'Invalid file',
          message: "File doesn't exists",
        });
      }
      const fileName = inputFile.name;

      if (useRawFile) {
        setFile(inputFile);
      } else {
        // read csv stream
        const reader = new FileReader();
        reader.onload = async ({ target }) => {
          let csvData = target.result;
          if (typeof csvData === 'string') {
            csvData = (csvData as string).trim();
          }
          const csv = CSV.parse(csvData, { header: true });
          const formattedData = formatCsvData
            ? formatCsvData(csv.data)
            : csv.data;
          const validateResult = validateData
            ? validateData(csv.data)
            : { isValid: true };
          if (!validateResult.isValid) {
            return handleError({
              title: 'Invalid csv file',
              message: validateResult.message,
            });
          }
          setRows(formattedData);
        };
        const blob = inputFile.slice(0, inputFile.size, 'file');
        reader.readAsText(blob);
      }
      fileDialogRef.current.value = null;
      setFileName(fileName);
    },
    [formatCsvData, handleError, validateData, useRawFile]
  );

  const handleRemoveFile = useCallback(() => {
    setFileName(undefined);
    if (useRawFile) {
      setFile(undefined);
    } else {
      setRows(undefined);
    }
  }, [useRawFile]);

  return {
    rows,
    setRows,
    file,
    fileName,
    fileDialogRef,
    handleRemoveFile,
    handleUploadClick,
    handleFileUploaded,
  };
};

export default useCsvData;
