import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { styled } from '@material-ui/core';
import { camelToSentence } from './utils';
const StyledHeader = styled(TableHeaderRow.Content)(({ theme }) => ({
  fontWeight: 700,
}));

export const BlockchainTableHeader = (props) => <StyledHeader {...props} />;
export const toColumnExtensions = (cols: Array<Record<string, any>>) =>
  cols.map((col) => ({
    columnName: col.name,
    width: col.width,
    wordWrapEnabled: true,
  }));

const intersect = (array1: Array<any>, array2: Array<any>) =>
  array1.filter((value) => array2.includes(value));

const union = (array1: Array<any>, array2: Array<any>) => [
  ...new Set([...array1, ...array2]),
];

const isDate = (val) => {
  return isNaN(Date.parse(val)) === false && isNaN(val);
};

export const toCommonColumns = (rows: Array<any>) => {
  let columns = Object.keys(rows[0]);
  const concatFunc = columns.length > 3 ? intersect : union;
  rows.forEach((row) => {
    // exclude array children
    const keys = Object.keys(row).filter((key) => {
      return !Array.isArray(row[key]);
    });
    columns = concatFunc(columns, keys);
  });
  return columns
    .sort((key1, key2) => {
      if (isDate(rows[0][key1]) === false && isDate(rows[0][key2]) === false) {
        return 0;
      } else if (isDate(rows[0][key1]) && isDate(rows[0][key2])) {
        return key1.toLocaleLowerCase().localeCompare(key2.toLocaleLowerCase());
      } else if (isDate(rows[0][key1]) && isDate(rows[0][key2]) === false) {
        return -1;
      }
      return 1;
    })
    .map((key) => ({
      name: key,
      title: camelToSentence(key),
      width: Math.floor(1200 / columns.length) + Math.floor(2 * key.length),
    }));
};
