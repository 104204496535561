import React, { useCallback, useMemo, useState } from "react";
import {
    Grid,
    Typography,
    Box,
    makeStyles,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import COLOR from "../../../styled/colors";
import MyButton from "../../../presentation/button";
import { PageHeader } from "../../../presentation/withHeader";
import { useAppDispatch } from "../../../utils/hooks";
import { toggleModal } from "../../../store/modal/actions";
import { delay, formatAmbioxera } from "./util";
import {
    SPINNER_TOGGLE_OFF,
    SPINNER_TOGGLE_ON,
} from "../../../store/spinner/types";
import { callAPI, getAPI } from "../../../utils/network";
import { WebErrorType } from "../../../utils/error";
import EditDataTable from "../EditDataTable";
import { AmbioxeraApiType, validateAmbioxeraData } from "./schema";
import { HyperLink } from "../../../presentation/word";
import { getAmbioxeraCsvFiles } from "./csvFiles";
import useCsvData, { RowType } from "../useCsvData";

const useStyle = makeStyles((theme) => ({
    button: {
        color: COLOR.GREEN_BUTTON,
        borderColor: COLOR.GREEN_BUTTON,
        marginRight: 50,
    },
    uploadField: {
        marginTop: 10,
        marginBottom: 10,
    },
    uploadButton: {
        height: "2.7em",
    },
    fileName: {
        height: 48,
        width: "100%",
        maxWidth: window.innerWidth / 2,
    },
    subTitle: {
        marginTop: theme.spacing(3),
        fontWeight: 600,
    },
    currentFile: {
        height: "2.7em",
        display: "flex",
        flexDirection: "row",
        padding: "0.5em",
        alignItems: "center",
        border: `1px solid ${COLOR.GRAY_BORDER}`,
        borderRadius: "0.2em",
    },
    fileNameLabel: {
        display: "flex",
        flexDirection: "row",
        gap: "0.2em",
        border: `1px solid ${COLOR.GRAY_BORDER}`,
        borderRadius: "0.2em",
        padding: "0.45em",
        cursor: "pointer",
        userSelect: "none",
        "&:hover": {
            border: `1px solid ${COLOR.GREEN}`,
        },
    },
    cancelIcon: {
        color: COLOR.GRAY_ICON,
        "&:hover": {
            color: COLOR.GREEN,
        },
    },
    formControl: {
        width: "100%",
    },
    downloadDropdown: {
        marginTop: 15,
        width: "47%",
    },
    downloadLabel: {
        fontSize: "0.9rem",
    },
    downloadItem: {
        cursor: "default",
    },
    downloadLink: {
        textDecoration: "underline",
        width: "100%",
        height: "100%",
        cursor: "pointer",
    },
    select: {
        borderColor: COLOR.GRAY_BORDER,
    },
}));

const UploadAmbioxera: React.FC<{}> = () => {
    const classes = useStyle();
    const dispatch = useAppDispatch();

    const URL_OPTIONS: Array<AmbioxeraApiType> = Object.keys(
        getAPI().POST.ambioxera
    ) as any;
    const [api, setApi] = useState<AmbioxeraApiType>(URL_OPTIONS[0]);
    const [apiKey, setApiKey] = useState<string>("");

    const templateCsvFiles = useMemo(() => {
        return getAmbioxeraCsvFiles(api, "template");
    }, [api]);

    const exampleCsvFiles = useMemo(() => {
        return getAmbioxeraCsvFiles(api, "example");
    }, [api]);

    const validateData = useCallback(
        (data: Array<Record<string, any>>) => {
            return validateAmbioxeraData(api, formatAmbioxera(data));
        },
        [api]
    );

    const promptError = useCallback(
        ({ title, message }: { title: string; message: string }) => {
            dispatch(
                toggleModal({
                    status: "failed",
                    title: title,
                    subtitle: message,
                })
            );
        },
        [dispatch]
    );

    const {
        rows,
        setRows,
        fileName,
        handleFileUploaded,
        handleRemoveFile,
        handleUploadClick,
        fileDialogRef: csvFileDialogRef,
    } = useCsvData({
        formatCsvData: formatAmbioxera,
        handleError: promptError,
        validateData,
    });

    const handleSubmit = async () => {
        if (apiKey.trim().length === 0) {
            dispatch(
                toggleModal({
                    status: "failed",
                    title: "Error",
                    subtitle: "API key required",
                })
            );
            return;
        }

        try {
            if (!rows) {
                return;
            }
            if (api.trim() === "") {
                return;
            }
            dispatch({ type: SPINNER_TOGGLE_ON });
            for (let i = 0; i < rows.length; i++) {
                const row = rows[i];
                const response = await callAPI(
                    {
                        url: getAPI().POST.ambioxera[api],
                        method: "POST",
                        data: row,
                    },
                    apiKey
                );
                console.log(response);
                await delay(350);
            }
            dispatch(
                toggleModal({
                    status: "success",
                    title: "Successfully Completed",
                    button: "Close",
                    CTAHandler: () => {},
                })
            );
        } catch (e) {
            const error = e as WebErrorType;
            console.log(error);
            dispatch(
                toggleModal({
                    status: "failed",
                    title: error.title,
                    subtitle: error.message,
                })
            );
        } finally {
            dispatch({ type: SPINNER_TOGGLE_OFF });
        }
    };

    const handleSelectApi = (event: React.ChangeEvent<{ value: string }>) => {
        setApi(event.target.value as any);
    };

    const handleChangeApiKey = (event: React.ChangeEvent<HTMLInputElement>) => {
        setApiKey(event.target.value);
    };

    const handleRowChange: React.Dispatch<
        React.SetStateAction<Array<RowType>>
    > = (value) => {
        if (typeof value === "function") {
            setRows((prev) => formatAmbioxera(value(prev)));
        } else {
            setRows(formatAmbioxera(value));
        }
    };

    return (
        <PageHeader
            config={{
                title: "Upload Form",
                margin: 60,
                back: true,
            }}
        >
            <Grid container>
                <Typography
                    variant="h6"
                    className={classes.subTitle}
                    role="label"
                >
                    {"CSV File"}
                </Typography>
                <Grid
                    container
                    direction="row"
                    className={classes.uploadField}
                    spacing={3}
                >
                    <Grid item className={classes.fileName} xs={4}>
                        <Box className={classes.currentFile}>
                            {fileName ? (
                                <Box className={classes.fileNameLabel}>
                                    <Typography>{fileName}</Typography>
                                    <Box
                                        className={classes.cancelIcon}
                                        onClick={handleRemoveFile}
                                    >
                                        <CloseIcon />
                                    </Box>
                                </Box>
                            ) : null}
                        </Box>
                    </Grid>
                    <Grid container item xs={4}>
                        <Grid container item direction={"column"}>
                            <FormControl
                                variant="filled"
                                className={classes.formControl}
                                size="medium"
                            >
                                <InputLabel htmlFor="select-api-type-label">
                                    Select
                                </InputLabel>
                                <Select
                                    labelId="select-api-type-label"
                                    className={classes.select}
                                    value={api}
                                    onChange={handleSelectApi}
                                    label="Please select"
                                >
                                    {URL_OPTIONS.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Grid container item justifyContent="space-between">
                                <FormControl
                                    className={classes.downloadDropdown}
                                    size="small"
                                    variant="filled"
                                >
                                    <InputLabel
                                        id="select-csv-template-label"
                                        className={classes.downloadLabel}
                                    >
                                        Download CSV Template
                                    </InputLabel>
                                    <Select
                                        labelId="select-csv-template-label"
                                        className={classes.select}
                                        value=""
                                    >
                                        {templateCsvFiles.map((x) => (
                                            <MenuItem
                                                key={x.name}
                                                value={x.value}
                                                className={classes.downloadItem}
                                            >
                                                <HyperLink
                                                    className={
                                                        classes.downloadLink
                                                    }
                                                    href={x.value}
                                                >
                                                    {x.name}
                                                </HyperLink>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl
                                    className={classes.downloadDropdown}
                                    size="small"
                                    variant="filled"
                                >
                                    <InputLabel
                                        id="select-example-label"
                                        className={classes.downloadLabel}
                                    >
                                        Download Example
                                    </InputLabel>
                                    <Select
                                        labelId="select-example-label"
                                        className={classes.select}
                                        value=""
                                    >
                                        {exampleCsvFiles.map((x) => (
                                            <MenuItem
                                                key={x.name}
                                                value={x.value}
                                                className={classes.downloadItem}
                                            >
                                                <HyperLink
                                                    className={
                                                        classes.downloadLink
                                                    }
                                                    href={x.value}
                                                >
                                                    {x.name}
                                                </HyperLink>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={2}>
                        <MyButton
                            text={"Upload File"}
                            variant="outlined"
                            onClick={handleUploadClick}
                            buttonClass={classes.uploadButton}
                        />
                        <input
                            type="file"
                            accept=".csv"
                            data-cy="uploadFile"
                            onChange={handleFileUploaded}
                            ref={csvFileDialogRef}
                            style={{ height: 0, width: 0 }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction="row" style={{ marginBottom: 20 }}>
                <Grid item xs={9}>
                    <Typography role="label">API KEY</Typography>
                    <TextField
                        style={{ width: "50%" }}
                        variant="outlined"
                        value={apiKey}
                        onChange={handleChangeApiKey}
                    />
                </Grid>
            </Grid>
            <MyButton
                text={"Sumbit"}
                disabled={!rows}
                variant="contained"
                onClick={handleSubmit}
            />
            <Grid
                container
                direction="column"
                item
                xs={12}
                style={{ width: "100%", marginTop: 50, overflowX: "auto" }}
            >
                <Typography variant="h3" role="label">
                    Preview Data
                </Typography>
                {rows ? (
                    <EditDataTable rows={rows} setRows={handleRowChange} />
                ) : (
                    <Typography
                        role="label"
                        style={{ margin: "auto", paddingTop: 30 }}
                    >
                        No Data
                    </Typography>
                )}
            </Grid>
        </PageHeader>
    );
};

export default UploadAmbioxera;
