import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  makeStyles,
  Grid,
  Box,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableHeaderRow,
  Grid as TableGrid,
  PagingPanel,
  TableBandHeader,
} from "@devexpress/dx-react-grid-material-ui";
import {
  PagingState,
  IntegratedPaging,
  SortingState,
  IntegratedSorting,
} from "@devexpress/dx-react-grid";
import COLOR from "../../../styled/colors";
import { PageHeader } from "../../../presentation/withHeader";
import { useAppDispatch } from "../../../utils/hooks";
import {
  SPINNER_TOGGLE_OFF,
  SPINNER_TOGGLE_ON,
} from "../../../store/spinner/types";
import { callAPI, getAPI } from "../../../utils/network";
import { toggleModal } from "../../../store/modal/actions";
import { WebErrorType } from "../../../utils/error";
import FilterListIcon from '@material-ui/icons/FilterList';


const useStyle = makeStyles((theme) => ({
  button: {
    color: COLOR.GREEN_BUTTON,
    borderColor: COLOR.GREEN_BUTTON,
    marginRight: 50,
  },
  hyperlink: {
    color: COLOR.GREENT_TEXT,
    cursor: "pointer",
    textDecoration: "underline",
    textTransform: "capitalize",
    fontSize: 16,
  },
  filterIcon: {
    height: 28,
    width: 25,
    color: COLOR.GREENT_TEXT,
  },
  filterButton: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },
  filterColor: { backgroundColor: COLOR.WHITE },
}));

const initialState = {
  currentPage: 0,
  pageSize: 10,
  promotionList: [],
  showFilters: false,
  businessName: "",
  promotionName: "",
};

type Action =
  | { type: "change/businessName"; businessName: string }
  | { type: "change/promotionName"; promotionName: string }
  | { type: "change/page"; currentPage: number }
  | { type: "change/pageSize"; pageSize: number }
  | { type: "change/promotionList"; promotionList: Array<any> }
  | { type: "change/showFilters"; showFilters: boolean }
  | { type: "update/filters"; filterType: string; data: string };

const reducer = (
  prevState: typeof initialState,
  action: Action
): typeof initialState => {
  const { type, ...actionData } = action;
  switch (action.type) {
    case "update/filters":
      return { ...prevState, [action.filterType]: action.data };
    default:
      return { ...prevState, ...actionData };
  }
};

const StyledTable = styled(Table.Table)(({ theme }) => ({
  backgroundColor: COLOR.WHITE,
}));
const StyledPaging = styled(PagingPanel.Container)(({ theme }) => ({
  marginTop: 20,
}));
const StyledHeader = styled(Table.TableHead)(({ theme }) => ({
  backgroundColor: COLOR.GRAY_SOLID,
  fontWeight: 700,
  textAlign: "center",
}));

const StyledBrandHeader = styled(TableBandHeader.Cell)(({ theme }) => ({
  fontWeight: 700,
}));

export const TableComponent = (props) => <StyledTable {...props} />;
export const PagingComponent = (props) => <StyledPaging {...props} />;
export const HeaderComponent = (props) => <StyledHeader {...props} />;
export const BrandHeaderComponent = (props) => <StyledBrandHeader {...props} />;

const PromotionCount: React.FC<{}> = () => {
  const classes = useStyle();
  const [state, localDispatch] = useReducer(reducer, initialState);
  const dispatch = useAppDispatch();
  const timerFilter = useRef(null);

  const columnWidths = (
    cols: Array<{ name: string; title: string; width: number | string }>
  ) =>
    cols.map((dt) => ({
      columnName: dt.name,
      width: dt.width,
    }));

  const changePage = useCallback((pageNum: number) => {
    localDispatch({ type: "change/page", currentPage: pageNum });
  }, []);
  const changePageSize = useCallback((size: number) => {
    localDispatch({ type: "change/pageSize", pageSize: size });
  }, []);

  const columns = [
    { name: "id", title: "No", width: 100 },
    { name: "businessName", title: "Business Name", width: "auto" },
    { name: "promotionName", title: "Promotion Name", width: "auto" },
    { name: "status", title: "Status", width: 150 },
    { name: "createdDate", title: "Created Date", width: 250 },
    { name: "webCount", title: "Web", width: 120 },
    { name: "supplierCount", title: "Supplier", width: 120 },
    { name: "consumerCount", title: "Consumer", width: 120 },
  ];

  const [columnBands] = useState([
    {
      title: "Promotion Count",
      children: [
        { columnName: "webCount" },
        { columnName: "consumerCount" },
        { columnName: "supplierCount" },
      ],
    },
  ]);

  const [sortingStateColumnExtensions] = useState([
    { columnName: "id", sortingEnabled: false },
    { columnName: "status", sortingEnabled: false },
    { columnName: "createdDate", sortingEnabled: false },
    { columnName: "webCount", sortingEnabled: false },
    { columnName: "supplierCount", sortingEnabled: false },
    { columnName: "consumerCount", sortingEnabled: false },
  ]);

  const changeShowFilter = useCallback(() => {
    localDispatch({
      type: "change/showFilters",
      showFilters: !state.showFilters,
    });
  }, [state.showFilters]);

  const fetchPromotionList = useCallback(
    async (busName = "", promName = "") => {
      const data = {
        businessName: busName,
        promotionName: promName,
      };
      try {
        dispatch({ type: SPINNER_TOGGLE_ON });

        const response = await callAPI({
          url: getAPI().POST.promotionCount,
          method: "POST",
          data: data,
        });

        const promotionList = [];
        response?.map((item, index) => {
          promotionList.push({
            id: index + 1,
            businessName: item.businessName,
            promotionName: item.promoName,
            status:
              item.promotionCount.length > 0
                ? item.promotionStatus.replace(
                    /^./,
                    item.promotionStatus[0].toUpperCase()
                  )
                : "Archived",
            createdDate: item.createdDate.length > 0 ? item.createdDate : "-",
            webCount:
              item.promotionCount.length > 0
                ? item.promotionCount[0]["web"].count
                : "-",
            supplierCount:
              item.promotionCount.length > 0
                ? item.promotionCount[0]["supplier"].count
                : "-",
            consumerCount:
              item.promotionCount.length > 0
                ? item.promotionCount[0]["consumer"].count
                : "-",
          });
        });

        localDispatch({
          type: "change/promotionList",
          promotionList: promotionList,
        });
      } catch (e) {
        const error = e as WebErrorType;
        dispatch(
          toggleModal({
            status: "failed",
            title: error.title,
            subtitle: error.message,
            button: "Close",
          })
        );
      } finally {
        dispatch({ type: SPINNER_TOGGLE_OFF });
      }
    },
    []
  );

  const handleResetFilter = useCallback(() => {
    localDispatch({
      type: "change/businessName",
      businessName: "",
    });

    localDispatch({
      type: "change/promotionName",
      promotionName: "",
    });

    fetchPromotionList("", "");
  }, [fetchPromotionList]);

  const handleBusinessNameFilterChange = useCallback(
    (value: string) => {
      localDispatch({
        type: "change/businessName",
        businessName: value,
      });

      localDispatch({
        type: "change/promotionName",
        promotionName: "",
      });

      clearTimeout(timerFilter.current);
      timerFilter.current = setTimeout(() => {
        fetchPromotionList(value, "");
        clearTimeout(timerFilter.current);
        timerFilter.current = null;
      }, 2000);
    },
    [fetchPromotionList]
  );

  const handlePromotionNameFilterChange = useCallback(
    (value: string) => {
      localDispatch({
        type: "change/promotionName",
        promotionName: value,
      });

      localDispatch({
        type: "change/businessName",
        businessName: "",
      });

      clearTimeout(timerFilter.current);
      timerFilter.current = setTimeout(() => {
        fetchPromotionList("", value);
        clearTimeout(timerFilter.current);
        timerFilter.current = null;
      }, 2000);
    },
    [fetchPromotionList]
  );

  useEffect(() => {
    fetchPromotionList();
  }, []);

  return (
    <PageHeader
      config={{
        title: "Promotion Active",
        margin: 1,
      }}
    >
      <Grid style={{ marginTop: 20 }}>
        <div className={classes.filterButton}>
          <FilterListIcon className={classes.filterIcon} />
          <Button id={"filterButton"} onClick={changeShowFilter}>
            <span
              style={{
                color: COLOR.GREENT_TEXT,
                textTransform: "capitalize",
              }}
            >
              <u>{"Filter"}</u>
            </span>
          </Button>
        </div>

        {state.showFilters && (
          <Grid container>
            <Grid item xs={3} style={{ margin: "10px 0 20px 0" }}>
              <Typography variant="h6" role="label">
                Business Name
              </Typography>
              <TextField
                style={{ width: "100%" }}
                variant="outlined"
                value={state.businessName}
                onChange={(e) => handleBusinessNameFilterChange(e.target.value)}
              />
            </Grid>
            <Grid item xs={3} style={{ margin: "10px 20px" }}>
              <Typography variant="h6" role="label">
                Promotion Name
              </Typography>
              <TextField
                style={{ width: "100%" }}
                variant="outlined"
                value={state.promotionName}
                onChange={(e) =>
                  handlePromotionNameFilterChange(e.target.value)
                }
              />
            </Grid>
            <Grid item xs={2} alignItems="flex-end" style={{ display: "flex" }}>
              <Button
                style={{ height: 40, margin: "0 0 20px 0" }}
                onClick={handleResetFilter}
              >
                <span className={classes.hyperlink}>
                  <u>{"Reset Filter"}</u>
                </span>
              </Button>
            </Grid>
          </Grid>
        )}
        <Box
          p={"10px 15px 30px 15px"}
          ml={5}
          style={{
            marginLeft: 0,
            backgroundColor: COLOR.GRAY_SOLID,
          }}
        >
          <TableGrid rows={state.promotionList} columns={columns}>
            <SortingState columnExtensions={sortingStateColumnExtensions} />
            <IntegratedSorting />
            <PagingState
              currentPage={state.currentPage}
              onCurrentPageChange={changePage}
              pageSize={state.pageSize}
              onPageSizeChange={changePageSize}
            />
            <IntegratedPaging />
            <Table
              tableComponent={TableComponent}
              headComponent={HeaderComponent}
              columnExtensions={columnWidths(columns)}
            />
            <TableHeaderRow
              contentComponent={HeaderComponent}
              showSortingControls
            />
            <TableBandHeader
              cellComponent={BrandHeaderComponent}
              columnBands={columnBands}
            />
            <PagingPanel
              pageSizes={[5, 10, 15, 20]}
              containerComponent={PagingComponent}
            />
          </TableGrid>
        </Box>
      </Grid>
    </PageHeader>
  );
};
export default PromotionCount;
