export interface ManageUserType {
    name: string;
    role: string;
    email: string;
    phone: string;
    invitationDate?: Date;
    addedDate?: Date;
    deactivatedDate?: Date;
    userId?: string;
    invitationCode?: string;
}
 
export interface ManageBusinessInfoType {
    businessId: string,
    businessName: string,
}

export interface addState {
    readonly users: ManageUserType[];
    readonly businessInfo: ManageBusinessInfoType,
}
  
export const FETCH_USERS = 'FETCH_USERS';
export const BUSINESS_INFO = 'BUSINESS_INFO';
 
interface featchUsers {
    type: typeof FETCH_USERS;
    payload: Array<ManageUserType>;
}

export type addTypes = Array<
    | featchUsers
>;
  