import React, { forwardRef } from 'react';

import { useRouteMatch, NavLink, NavLinkProps } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { ListItemIcon } from '@material-ui/core';

import DashboardIcon from '@material-ui/icons/Dashboard';
import ExtensionIcon from '@material-ui/icons/Extension';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import COLOR from '../styled/colors';

const useStyles = makeStyles((theme) => ({
  sidebar: {
    backgroundColor: theme.palette.secondary.main,
    minWidth: '250px',
  },
  lists: {
    marginTop: theme.spacing(1),
  },
  menuIcon: {
    height: 24,
    width: 24,
    minWidth: 35,
    color: '#373935',
  },
  menuItem: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(1),
    '&.active': {
      borderRight: `8px solid ${COLOR.GREEN}`,
    },
  },
}));

type AppMenuItem = {
  name: string;
  link?: string;
  Icon?: (...args: any) => JSX.Element;
  items?: AppMenuItem[];
  level?: number;
};

const appMenuItems: AppMenuItem[] = [
  {
    name: 'Dashboard',
    link: '/',
    Icon: DashboardIcon,
  },
  {
    name: 'Plugin',
    link: '/plugin',
    Icon: ExtensionIcon,
  },
  {
    name: 'Treatment',
    link: '',
    Icon: AssignmentIcon,
  },
  {
    name: 'Monitor',
    items: [
      {
        name: 'Delete Token',
        link: '/monitor/deleteToken',
      },
      {
        name: 'Users',
        link: '/monitor/Users',
      },
      {
        name: 'KillSheet',
        link: '/monitor/killsheet',
      },
      {
        name: 'Promotion',
        items: [
          {
            name: 'Promotion Count',
            link: '/monitor/promotion/PromotionCount',
          },
        ],
      },
      {
        name: 'Angus',
        items: [
          {
            name: 'Animals Count',
            link: '/monitor/angus/AnimalsCount',
          },
          {
            name: 'Delete Angus Token',
            link: '/monitor/angus/AngusToken',
          },
        ],
      },
      {
        name: 'Business',
        items: [
          {
            name: 'Business Account',
            link: '/monitor/business/BusinessAccount',
          },
          {
            name: 'Active Business',
            link: '/monitor/business/ActiveBusiness',
          },
          {
            name: 'Plugin Active',
            link: '/monitor/business/PluginActive',
          },
          {
            name: 'All Business Plugins',
            link: '/monitor/business/BusinessPluginInfo',
          },
        ],
      },
      {
        name: 'Blockchain',
        items: [
          {
            name: 'Search',
            link: '/monitor/blockchain/search',
          },
          {
            name: 'Recent Transactions',
            link: '/monitor/blockchain/transactions',
          },
        ],
      },
    ],
  },
  {
    name: 'Form',
    items: [
      {
        name: 'Upload',
        link: '/form/upload',
      },
      {
        name: 'Upload Ambioxera',
        link: '/form/upload/ambioxera',
      },
      {
        name: 'Upload Mackas Showcase',
        link: '/form/upload/mackas',
      },
    ],
  },
  {
    name: 'QA',
    items: [
      {
        name: 'Account Generate',
        link: '/qa/account',
      },
    ],
  },
  {
    name: 'Generation',
    items: [
      {
        name: 'CSV',
        link: '/session/csv',
      },
    ],
  },
  {
    name: 'Alert',
    items: [
      {
        name: 'Alert Dashboard',
        link: '/alert',
      },
    ],
  },
];

const Sidebar: React.FC = () => {
  const classes = useStyles();

  return (
    <List component='nav' className={classes.sidebar} disablePadding>
      {appMenuItems.map((item, index) => (
        <SideBarItem {...item} key={index} />
      ))}
    </List>
  );
};

export interface MenuItemContainerProps {
  className?: string;
  link?: string | null; // because the InferProps props allows alows null value
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const MenuItemContainer: React.FC<MenuItemContainerProps> = (props) => {
  const { className, onClick, link, children } = props;
  const { path } = useRouteMatch();

  // If link is not set return the orinary ListItem
  if (!link || typeof link !== 'string') {
    return (
      <ListItem
        button
        className={className}
        children={children}
        onClick={onClick}
      />
    );
  }

  // Return a LitItem with a link component
  return (
    <ListItem
      button
      className={className}
      children={children}
      component={forwardRef((props: NavLinkProps, ref: any) => (
        <NavLink exact {...props} innerRef={ref} />
      ))}
      to={link && `${path}${link}`}
    />
  );
};

// Improve child items declaration
interface SideBarItemListProps {
  open: boolean;
  items: AppMenuItem[];
  level: number;
}
const SideBarItemList: React.FC<SideBarItemListProps> = ({
  open,
  items,
  level,
}) => {
  return (
    <Collapse in={open} timeout='auto' unmountOnExit>
      <List component='div' disablePadding>
        {items.map((item, index) => (
          <SideBarItem {...item} key={index} level={level + 1} />
        ))}
      </List>
    </Collapse>
  );
};

const SideBarItem: React.FC<AppMenuItem> = (props) => {
  const { name, items, Icon, link, level = 0 } = props;
  const classes = useStyles();
  const isExpandable = items && items.length > 0;
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <MenuItemContainer
        className={classes.menuItem}
        link={link}
        onClick={handleClick}
      >
        {/* Display an icon if any */}
        {!!Icon && (
          <ListItemIcon className={classes.menuIcon}>
            <Icon />
          </ListItemIcon>
        )}
        <ListItemText
          primary={
            <Typography
              variant='h4'
              style={{
                paddingLeft: (level + 1) * 8 + level * 20,
              }}
            >
              {name}
            </Typography>
          }
          className={classes.lists}
        />
        {/* Display the expand menu if the item has children */}
        {isExpandable && !open && <ExpandMoreIcon />}
        {isExpandable && open && <ExpandLessIcon />}
      </MenuItemContainer>
      {isExpandable && (
        <SideBarItemList open={open} items={items as any} level={level} />
      )}
    </>
  );
};

export default Sidebar;
