import React, { useEffect, useState, useRef } from "react";
import {
  FormControl,
  Grid,
  TextField,
  Typography,
  makeStyles,
  Box,
  Button,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@material-ui/core";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import withHeader from "../../presentation/withHeader";
import MyButton from "../../presentation/button";
import COLOR from "../../styled/colors";
import { useAppSelector, useAppDispatch } from "../../utils/hooks";
import { createSession, fetchSessions, fetchSessionPlugins, fetchSession, fetchTemplates, generateData } from "../../store/session/actions";
import { useDispatch } from "react-redux";
import { PluginRefObj, PluginForm } from "@aglive/web-core";
import { Line } from "../auth/styles";
import DialogMessage from "../../presentation/DialogMessage";
import { PluginModel, TokenModel, TokenService } from "@aglive/data-model";
import { toggleModal, toggleModalOff } from "../../store/modal/actions";
import { Buttons } from "../../presentation/ButtonsGroup";
import { HyperLink } from "../../presentation/word";
import MyTable from "../../presentation/Table"
import { FormGenerator, FormInputFunctions } from "../../presentation/FormGenerator";

const numberGreaterOneValidator = (val) => {
  let isNum = /^[0-9]*[1-9][0-9]*$/g.test(val)
  let error = ''
  if(!isNum) error = 'Must be number 1 or more'
  return {result: !isNum, error}  
}

const numberAllIntValidator = (val) => {
  let isNum = /^-?[0-9]*[0-9][0-9]*$/g.test(val)
  let error = ''
  if(!isNum) error = 'Must be number'
  return {result: !isNum, error}  
}
const translateToNumber = (val) => {
  return Number(val)
}

const GenerateCSV: React.FC<{}> = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const generationDetails = {name: '', rows: '', breed: '', 'birth_season': ""}
  const session = useAppSelector((state) => state.session.session);
  const inductionCSVItems = session?.generatedCSV?.filter(o => o.type == "induction").map(o => ({label: o.name, value: o.csvId}))
  const templates = useAppSelector((state) => state.session.templates);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSession(sessionId));
  }, []);

  useEffect(() => {
    if(session.details?.plugin) {
        dispatch(fetchTemplates(session.details?.plugin)) 
    }
  }, [session])


  //Plugin
  const [genFormReady, setGenFormReady] = useState(false);

  const { sessionId } = useParams<{sessionId:string}>();

  const [templateSelect, setTemplateSelect] = useState('')
  const otherFormRefs = useRef<FormInputFunctions<typeof generationDetails>>()

  const selectedSchema = templates.find(o => o.id == templateSelect) ?? {}
  const [enableMulti, setEnableMulti] = useState(false)

  const convertData = () => {
    const formData = Object.entries(otherFormRefs?.current?.getValues() ?? {})

    let data = {
      download: false,
      sessionId: Number(sessionId),
      generate: []
    }
    let generate = {
      templateId: templateSelect
    }
    let settings = {}
    let options = {}
    for (let i = 0; i < formData.length; i++) {
      let key = formData[i][0]
      let value = formData[i][1]
      let split = key.split('.')


      if(key === "csvId") {
        data['csvId'] = value
      } else if (key === "csvName") {
        generate['name'] = value
      } 
      else if(key == "rows") {
        generate['rows'] =  Number(value)
      } else if (enableMulti && key == "csvAmount") {
        settings['amount'] = value
      }else if(split.length != 2) {
      } else if(value === '' || value === undefined) {
      } else {
        let object = split[0]
        let objectField = split[1]

        if(options[object]) {
          options[object][objectField] = value
        } else {
          options[object] = {
            [objectField]: value
          }
        }
      }
    }
    if(options['timeGap'] && enableMulti) {
      settings['timeGap'] = options['timeGap']
      delete options['timeGap']
    }
    generate['options'] = options
    generate['settings'] = settings
    data.generate.push(generate)
    console.log(options, settings)
    return data
  }

  const submitCSVGeneration = () => {
    let requestData = convertData()
    dispatch(generateData(requestData, () => {
      history.goBack()
    }))
  }

  const SchemaToFormInputs = (data: any) => {
    if(!data.schema) {
      return
    }

    let schema = Object.entries(data.schema)
    const keyData = data?.keyData ?? []
    const isInduction = data.type == "induction"
    const hasLinkage = data.linkage
    const required = data?.required ?? []
    let formInputs:any = [
    ]

    if(hasLinkage && isInduction) {
      let allMatchingCSVs = session?.generatedCSV?.filter(o => hasLinkage?.templateIds.includes(o.templateId)).map(o => ({label: o.name, value: o.csvId}))

      formInputs = formInputs.concat([
        {
          type: 'SELECT',
          name: "csvId",
          title: "Linkage CSV",
          required: true,
          value: '',
          items: allMatchingCSVs,
          viewMode: false,
          colSize: 4
        },
        {
          type: "other",
          customComponent:(<></>),
          colSize: 8,
          required: false
        }]
      )
    }
    //If data is key data then placeholder as random, viewMode true
    formInputs = formInputs.concat([
      {
        type: isInduction ? 'INPUT' : 'SELECT',
        name: isInduction ? 'rows' : "csvId",
        title: isInduction ? 'Rows **' : "Induction CSV",
        required: true,
        value: isInduction? '' : '',
        ...(isInduction ? {customValidation: numberGreaterOneValidator}: {items: inductionCSVItems}),
        viewMode: false,
        colSize: 4
      },
      {
        type: 'INPUT',
        name: "csvName",
        title: "CSV Name",
        value: '',
        viewMode: false,
        required: false,
        colSize: 4,
      },
      {
        type: "other",
        customComponent:(<></>),
        colSize: 4,
        required: false
      }
    ])
  
    //,
    //If data is number
    for(let i = 0; i < schema.length; i++) {
      let key = schema[i][0]
      let value = schema[i][1] as any
      let colSize = 12
      let defaultColSize = 4
      let addInputs = []

      if(value.type == 'enum') {
        addInputs.push({
          type: 'SELECT',
          name: key + ".value",
          title: value.header,
          items: value.values.map(o => ({label: o, value: o})).concat([{label: 'random', value: ''}]),
          value: '',
          viewMode: false,
          placeholder: 'random',
          required: false,
          colSize: defaultColSize
        })
        colSize = colSize - 4
      } else if(value.type == 'number') {
        addInputs.push({
          type: 'INPUT',
          name: key + ".min",
          title: value.header + " Min",
          value: '',
          viewMode: false,
          required: false,
          colSize: defaultColSize,
          customValidation: numberAllIntValidator,
          translation: translateToNumber
        })
        addInputs.push({
          type: 'INPUT',
          name: key + ".max",
          title: value.header + " Max",
          value: '',
          viewMode: false,
          required: false,
          colSize: defaultColSize,
          customValidation: numberAllIntValidator,
          translation: translateToNumber
        })
        if(!isInduction || hasLinkage) {
          addInputs.push({
            type: 'CHECKBOX',
            name: key + ".addOn",
            title: "Add On",
            value: false,
            viewMode: false,
            required: false,
            colSize: 1
          })
        }
      } else if(value.type == 'date') {
        addInputs.push({
          type: 'DATE',
          name: key + ".start",
          title: value.header + " Start",
          value: new Date(),
          viewMode: false,
          required: false,
          colSize: defaultColSize,
        })
        addInputs.push({
          type: 'DATE',
          name: key + ".end",
          title: value.header + " End",
          value: new Date(),
          viewMode: false,
          required: false,
          colSize: defaultColSize,
        })
      } else if(value.type == "string" && keyData.includes(key)) {
        continue
      } else if(value.type == "string") {
        addInputs.push({
          type: 'INPUT',
          name: key + ".value",
          title: value.header,
          value: '',
          viewMode: false,
          placeholder: 'random',
          required: false,
          colSize: defaultColSize
        })
      }
      if(!required.includes(key)){
        addInputs.push({
          type: 'CHECKBOX',
          name: key + ".omit",
          title: "Omit",
          value: false,
          viewMode: false,
          required: false,
          colSize: 1
        })
      }
      formInputs.push({
        type: "other",
        customComponent:(<></>),
        colSize: 12,
        required: false
      })
      formInputs = formInputs.concat(addInputs)
    }

    if(!isInduction) {
      formInputs.push({
        type: "other",
        customComponent:(<>
          <FormGroup>
            <FormControlLabel control={<Checkbox color="primary" checked={enableMulti} onChange={(e, checked) => {setEnableMulti(checked)}} />} label="Generate Multiple CSVs"/>
          </FormGroup>
        </>),
        colSize: 12,
        required: false
      })
      formInputs.push({
        type: 'INPUT',
        name: "csvAmount",
        title: "Number of CSV **",
        value: '1',
        viewMode: false,
        skip: !enableMulti,
        required: true,
        customValidation: numberGreaterOneValidator,
        translation: translateToNumber
      })
      formInputs.push({
        type: 'INPUT',
        name: "timeGap.amount",
        title: "Time Gap Length **",
        value: '1',
        viewMode: false,
        required: true,
        skip: !enableMulti,
        customValidation: numberGreaterOneValidator,
        translation: translateToNumber
      })
      formInputs.push({
        type: 'SELECT',
        name: "timeGap.type",
        title: "Time Gap Type **",
        items: ['day', 'month', 'year'],
        value: 'month',
        viewMode: false,
        required: true,
        skip: !enableMulti,
      })
    }

    return (<Grid container spacing={3}>
      <FormGenerator ref={otherFormRefs} formInputs={formInputs} onReady={setGenFormReady} id={templateSelect}/>
    </Grid>)
  }

  return (
    <>      
      <Grid container spacing={3}>
        <Grid item xs={4} style={{marginBottom: 20}}>
        <FormControl fullWidth  variant="outlined">
        <Select
          value={templateSelect}
          onChange={(e: React.ChangeEvent<{name?: string; value: string}>) => {
            setTemplateSelect(e.target.value)
          }}>
          {templates.map(o => ({label: o.name, value: o.id})).map((itm) => (
            <MenuItem value={itm.value} key={itm.value}>
              {itm.label}
            </MenuItem>
          ))}
        </Select>
        </FormControl>
        </Grid>
      </Grid>
      {SchemaToFormInputs(selectedSchema)}
      <MyButton 
          text={"Submit"}
          variant="outlined"
          buttonClass={''}
          disabled={!genFormReady}
          onClick={() =>   {submitCSVGeneration()}
        }
      /> 
    </>
  );
};

export default withHeader(
  {
    title: "Generate CSV",
    margin: 60,
    back: true,
  },
  GenerateCSV
);
