import React, { useCallback, useReducer, useState } from 'react';
import { makeStyles, Grid, Typography, TextField, Box, MuiThemeProvider } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import { createTheme } from '@material-ui/core/styles';
import { Table, TableHeaderRow, TableSelection, Grid as TableGrid, PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import { SelectionState, PagingState, IntegratedSelection, IntegratedPaging } from '@devexpress/dx-react-grid';
import COLOR from '../../styled/colors';
import MyButton from '../../presentation/button';
import { PageHeader } from '../../presentation/withHeader';
import { useAppDispatch } from '../../utils/hooks';
import { SPINNER_TOGGLE_OFF, SPINNER_TOGGLE_ON } from '../../store/spinner/types';
import { callAPI, getAPI } from '../../utils/network';
import { toggleModal } from '../../store/modal/actions';
import { WebErrorType } from '../../utils/error';
import { changeUserStatus, deleteUsers } from '../../store/monitor/actions';

const useStyle = makeStyles((theme) => ({
  button: {
    color: COLOR.GREEN_BUTTON,
    borderColor: COLOR.GREEN_BUTTON,
    marginRight: 50,
  },
  textBox: {
    marginTop: 10,
    marginBottom: 20,
  },
  subTitle: {
    marginTop: theme.spacing(3),
    fontWeight: 600,
  },
  activate: {
    width: 200,
    color: COLOR.WHITE,
    backgroundColor: COLOR.GREEN_BUTTON,
    borderColor: COLOR.GREEN_BUTTON,
    '&:hover': {
      backgroundColor: COLOR.GREEN_BUTTON,
      borderColor: COLOR.GREEN_BUTTON,
      boxShadow: 'none',
    },
  },
  deactivate: {
    width: 200,
    color: COLOR.WHITE,
    backgroundColor: COLOR.AA_RED,
    borderColor: COLOR.AA_RED,
    marginLeft: 20,
    '&:hover': {
      backgroundColor: COLOR.AA_RED,
      borderColor: COLOR.AA_RED,
      boxShadow: 'none',
    },
  },
  deleteBtn: {
    width: 200,
    color: COLOR.WHITE,
    backgroundColor: COLOR.RED,
    borderColor: COLOR.RED,
    marginLeft: 20,
    '&:hover': {
      backgroundColor: COLOR.RED,
      borderColor: COLOR.RED,
      boxShadow: 'none',
    },
  },
}));

const initialState = {
  emailAddress: '',
  businessSelectedIndex: [] /**can only save index on current page */,
  selectedBusinessInfo: [] /**save all selected animal info */,
  currentPage: 0,
  pageSize: 10,
  businessList: [],
};

type Action =
  | { type: 'change/emailAddress'; emailAddress: string }
  | {
      type: 'change/businessSelectedIndex';
      businessSelectedIndex: Array<number>;
    }
  | { type: 'change/selectedBusinessInfo'; selectedBusinessInfo: Array<any> }
  | { type: 'change/page'; currentPage: number }
  | { type: 'change/pageSize'; pageSize: number }
  | { type: 'change/businessList'; businessList: Array<any> };

const reducer = (prevState: typeof initialState, action: Action): typeof initialState => {
  const { type, ...actionData } = action;
  switch (action.type) {
    default:
      return { ...prevState, ...actionData };
  }
};

const StyledTable = styled(Table.Table)(({ theme }) => ({
  backgroundColor: COLOR.WHITE,
}));
const StyledPaging = styled(PagingPanel.Container)(({ theme }) => ({
  marginTop: 20,
}));
const StyledHeader = styled(TableHeaderRow.Content)(({ theme }) => ({
  fontWeight: 700,
}));

const HeaderComponentBase = ({ classes, ...props }) => <Table.TableHead {...props} style={{ backgroundColor: COLOR.GRAY_SOLID }} />;

export const TableComponent = (props) => <StyledTable {...props} />;
export const PagingComponent = (props) => <StyledPaging {...props} />;
export const HeaderComponent = (props) => <StyledHeader {...props} />;

const cellTheme = createTheme({
  palette: {
    secondary: {
      main: COLOR.GREEN,
    },
  },
});

const cellComponent = (props) => (
  <MuiThemeProvider theme={cellTheme}>
    <TableSelection.Cell {...props} />
  </MuiThemeProvider>
);

const Users: React.FC<{}> = () => {
  const classes = useStyle();
  const [state, localDispatch] = useReducer(reducer, initialState);
  const dispatch = useAppDispatch();

  const handleChangeName = (value: string) => {
    localDispatch({
      type: 'change/emailAddress',
      emailAddress: value,
    });
    localDispatch({
      type: 'change/businessList',
      businessList: [],
    });
    localDispatch({
      type: 'change/selectedBusinessInfo',
      selectedBusinessInfo: [],
    });
    localDispatch({
      type: 'change/businessSelectedIndex',
      businessSelectedIndex: [],
    });
  };

  const columnWidths = (cols: Array<{ name: string; title: string; width: number }>) =>
    cols.map((dt) => ({
      columnName: dt.name,
      width: dt.width,
    }));

  const changePage = useCallback((pageNum: number) => {
    localDispatch({ type: 'change/page', currentPage: pageNum });
  }, []);
  const changePageSize = useCallback((size: number) => {
    localDispatch({ type: 'change/pageSize', pageSize: size });
  }, []);

  const selectBusinessUser = useCallback(
    (index: Array<number>) => {
      if (state.businessSelectedIndex.length > index.length) {
        let diff = state.businessSelectedIndex.filter((item) => !index.includes(item))[0];
        const newArray = state.selectedBusinessInfo.filter((item) => item.businessId !== state.businessList[diff].businessId);
        localDispatch({
          type: 'change/selectedBusinessInfo',
          selectedBusinessInfo: newArray,
        });
      } else {
        let diff = index.filter((item) => !state.businessSelectedIndex.includes(item))[0];
        localDispatch({
          type: 'change/selectedBusinessInfo',
          selectedBusinessInfo: [...state.selectedBusinessInfo, state.businessList[diff]],
        });
      }
      localDispatch({
        type: 'change/businessSelectedIndex',
        businessSelectedIndex: index,
      });
    },
    [state.businessSelectedIndex, state.selectedBusinessInfo, state.businessList]
  );

  const columns = [
    { name: 'id', title: 'No', width: 60 },
    { name: 'userName', title: 'User Name', width: 250 },
    { name: 'userId', title: 'User ID', width: 400 },
    { name: 'businessName', title: 'Business Name', width: 250 },
    { name: 'businessId', title: 'Business ID', width: 380 },
    { name: 'status', title: 'Status', width: 100 },
  ];

  const onGenerate = async () => {
    try {
      dispatch({ type: SPINNER_TOGGLE_ON });

      const response = await callAPI({
        url: getAPI().POST.getTokensByField,
        method: 'POST',
        data: {
          type: 'user',
          searchField: 'emailAddress',
          search: state.emailAddress,
        },
      });

      if (response.length > 0) {
        let businessUser = response.map((item, index) => {
          return {
            id: index + 1,
            userName: item.name,
            userId: item.userId,
            businessName: item.businessName,
            businessId: item.businessId,
            status: item.status.length === 0 ? 'Activate' : 'Deactivate',
          };
        });

        localDispatch({
          type: 'change/businessList',
          businessList: businessUser,
        });
      } else {
        dispatch(
          toggleModal({
            status: 'warning',
            title: 'No Data Existing',
            button: 'Close',
          })
        );
      }
    } catch (e) {
      const error = e as WebErrorType;

      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Close',
        })
      );
    } finally {
      dispatch({ type: SPINNER_TOGGLE_OFF });
    }
  };

  const deleteUser = useCallback(() => {
    let selectedBusinessUserList = state.selectedBusinessInfo.map((item) => {
      return item['userId'];
    });
    dispatch(
      deleteUsers(selectedBusinessUserList, () => {
        localDispatch({
          type: 'change/emailAddress',
          emailAddress: '',
        });
        localDispatch({
          type: 'change/businessList',
          businessList: [],
        });
        localDispatch({
          type: 'change/selectedBusinessInfo',
          selectedBusinessInfo: [],
        });
        localDispatch({
          type: 'change/businessSelectedIndex',
          businessSelectedIndex: [],
        });
      })
    );
  }, [state.selectedBusinessInfo]);

  const changeStatus = useCallback(
    (type: string) => {
      let selectedBusinessUserList = state.selectedBusinessInfo.map((item) => {
        return item['userId'];
      });

      let userState;

      switch (type) {
        case 'Activate':
          userState = false;
          break;
        case 'Deactivate':
          userState = true;
          break;
      }

      dispatch(
        changeUserStatus(selectedBusinessUserList, userState, () => {
          localDispatch({
            type: 'change/emailAddress',
            emailAddress: '',
          });
          localDispatch({
            type: 'change/businessList',
            businessList: [],
          });
          localDispatch({
            type: 'change/selectedBusinessInfo',
            selectedBusinessInfo: [],
          });
          localDispatch({
            type: 'change/businessSelectedIndex',
            businessSelectedIndex: [],
          });
        })
      );
    },
    [state.selectedBusinessInfo]
  );

  return (
    <PageHeader
      config={{
        title: 'Users',
        margin: 1,
      }}>
      <Grid container>
        <Typography variant="h6" className={classes.subTitle} role="label">
          {'Email Address'}
        </Typography>
        <Grid container direction="row" className={classes.textBox}>
          <Grid item xs={9}>
            <TextField style={{ width: '50%' }} variant="outlined" value={state.emailAddress} onChange={(e) => handleChangeName(e.target.value)} />
          </Grid>
        </Grid>
        <MyButton
          text={'Search'}
          disabled={!state.emailAddress}
          variant="contained"
          onClick={() => {
            onGenerate();
          }}
        />
      </Grid>

      <Grid style={{ marginTop: 20 }}>
        {state.businessList.length > 0 && (
          <Box
            p={'40px 15px 30px 15px'}
            ml={5}
            style={{
              marginLeft: 0,
              backgroundColor: COLOR.GRAY_SOLID,
            }}>
            <TableGrid rows={state.businessList} columns={columns}>
              <SelectionState selection={state.businessSelectedIndex} onSelectionChange={selectBusinessUser} />
              <IntegratedSelection />

              <PagingState currentPage={state.currentPage} onCurrentPageChange={changePage} pageSize={state.pageSize} onPageSizeChange={changePageSize} />
              <IntegratedPaging />
              <Table tableComponent={TableComponent} headComponent={HeaderComponentBase} columnExtensions={columnWidths(columns)} />
              <TableHeaderRow contentComponent={HeaderComponent} />
              <TableSelection cellComponent={cellComponent} />
              <PagingPanel pageSizes={[5, 10, 15, 20]} containerComponent={PagingComponent} />
            </TableGrid>
          </Box>
        )}
      </Grid>

      {state.emailAddress && state.selectedBusinessInfo.length > 0 && (
        <Grid container style={{ marginTop: 20 }}>
          <MyButton
            text={'Activate'}
            variant="contained"
            disabled={!state.selectedBusinessInfo.length}
            buttonClass={classes.activate}
            onClick={() => {
              changeStatus('Activate');
            }}
          />

          <MyButton
            text={'Deactivate'}
            variant="contained"
            disabled={!state.selectedBusinessInfo.length}
            buttonClass={classes.deactivate}
            onClick={() => {
              changeStatus('Deactivate');
            }}
          />

          <MyButton
            text={'Delete User'}
            variant="contained"
            disabled={!state.selectedBusinessInfo.length}
            buttonClass={classes.deleteBtn}
            onClick={() => {
              deleteUser();
            }}
          />
        </Grid>
      )}
    </PageHeader>
  );
};
export default Users;
