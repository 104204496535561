import {s3JsonPicker} from '../../utils/jsonReader';
import {environmentType,CHANGE_ENV,GET_ENV_LIST} from './type';
import {store} from '../index';
import {envS3URL, defaultEnvOption} from '../../env';

export function changeEnv(currentEnv: string) {
    return (dispatch) => {
      const environment: environmentType = store.getState().system.envList[
        currentEnv
      ];
      dispatch({
        type: CHANGE_ENV,
        payload: {environment, currentEnv},
      });
    };
  }
  
  export function getEnvList() {
    return async (dispatch) => {
      s3JsonPicker(envS3URL)
        .then((json) => {
          dispatch({
            type: GET_ENV_LIST,
            payload: json,
          });
          // update default env with dev
          const currentEnv = store.getState().system.currentEnv;
          if (json[defaultEnvOption] && currentEnv === defaultEnvOption) {
            dispatch({
              type: CHANGE_ENV,
              payload: {
                environment: json[defaultEnvOption],
                currentEnv: defaultEnvOption,
              },
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
  }