import React, { useRef } from "react";

import {
  makeStyles,
  Theme,
  Grid,
  Button,
  IconButton,
  Typography,
} from "@material-ui/core";
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { Buttons } from "../ButtonsGroup";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import { toggleModal, toggleModalOff } from "../../store/modal/actions";
import { appThunkLogout } from "../../store/auth/actions";
import DropdownMenu from "./DropdownMenu";
import { getMenuOptions } from "./menuOptions";
import useComponentVisible from "../../utils/componentVisibleHook";
import { store } from "../../store/index";

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    margin: "5px 65px 0px 65px",
    width: "calc(100% - 130px)",
  },
  settingContainer: {
    position: "relative",
  },
  settingIcon: {
    cursor: "pointer",
  },
  logoutButton: {
    width: 100,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    textTransform: "none",
  },
  dropdownSize: {
    width: "15vh",
    minWidth: "240px",
    maxWidth: "400px",
    right: 0,
  },
}));

const ToolBar: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const angusProfile = useAppSelector(
    (state) => state.user.businessProfileData.angusProfile
  );
  const dependentRef = useRef(null);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false, dependentRef);

  const menuOptions = getMenuOptions(!!angusProfile);

  const currentEnv = store.getState().system.currentEnv;

  const logout = () => {
    dispatch(
      toggleModal({
        status: "warning",
        title: "Logout Now?",
        renderButton: (
          <Buttons
            leftButtonTitle="Cancel"
            rightButtonTitle="Yes"
            leftButtonOnClick={() => {
              dispatch(toggleModalOff());
            }}
            rightButtonOnClick={() => {
              dispatch(appThunkLogout());
              dispatch(toggleModalOff());
            }}
          />
        ),
      })
    );
  };

  return (
    <Grid
      container
      item
      className={classes.toolbar}
      justify="flex-end"
      alignItems="center"
      spacing={1}
    >
      <Typography>
        <h3 style={{ color: "white" }}>{currentEnv.toUpperCase()}</h3>
      </Typography>
      <Grid item className={classes.settingContainer}>
        <IconButton ref={dependentRef}>
          <SettingsIcon color="secondary" className={classes.settingIcon} />
        </IconButton>
        <Grid item ref={ref}>
          {isComponentVisible && (
            <DropdownMenu
              options={menuOptions}
              className={classes.dropdownSize}
              setDropdownVisible={setIsComponentVisible}
            />
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          color="secondary"
          className={classes.logoutButton}
          startIcon={<ExitToAppIcon />}
          onClick={logout}
        >
          Logout
        </Button>
      </Grid>
    </Grid>
  );
};

export default ToolBar;
