import produce from 'immer';
import {
  addState,
  addTypes,
  ManageUserType,
  FETCH_USERS,
  BUSINESS_INFO,
} from './types';

const initialState: addState = {
    users: [],
    businessInfo: {
        businessId: '',
        businessName: ''
    },
};
export const initialUser: ManageUserType = {
    name: '',
    role: '',
    email: '',
    phone: '',
    userId: '',
};

export default function addReducer(state = initialState, action: any): addState {
    switch (action.type) {
        case FETCH_USERS:
            return produce(state, (draft) => {
                draft.users = action.payload;
            });
        case BUSINESS_INFO:
            return produce(state, (draft) => {
                draft.businessInfo = action.payload;
            });
        default:
            return state;
    }
}
