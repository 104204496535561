import { z } from 'zod';

export const BloodSchema = z.object({
  ID: z.array(z.string()),
  establishmentID: z.string(),
  shift: z.string(),
  count: z.number(),
  weight: z.number(),
  time: z.string(),
  RFID: z.array(z.string()),
  temp: z.boolean(),
  PIC: z.array(z.string()),
  fWeight: z.number(),
  bodyNumber: z.string(),
  Quarantine: z.boolean(),
  killDate: z.string(),
});

export const ProcessingSchema = z.object({
  previousID: z.array(z.string()),
  ID: z.array(z.string()),
  type: z.enum(['bottle', 'canister', 'pallet', 'box']),
  weight: z.number().optional(),
  temp: z.boolean().optional(),
  hemoglobin: z.number().optional(),
  endotoxin: z.number().optional(),
  Quarantine: z.boolean().optional(),
});

export const RejectSchema = z.object({
  ID: z.array(z.string()),
  establishmentID: z.string(),
  reason: z.string(),
  photo: z.string(),
});

export type AmbioxeraApiType = 'insertBag' | 'processing' | 'rejection';

function getSchema(type: AmbioxeraApiType) {
  if (type === 'insertBag') {
    return BloodSchema;
  }
  if (type === 'processing') {
    return ProcessingSchema;
  }
  if (type === 'rejection') {
    return RejectSchema;
  }
  return null;
}

export function validateAmbioxeraData(
  type: AmbioxeraApiType,
  data: Array<any>
): { isValid: boolean; message?: string } {
  const schema = getSchema(type);
  if (!schema) {
    return { isValid: true, message: undefined };
  }
  let validationErrors: Array<string> = [];
  for (let i = 0; i < data.length; i++) {
    const row = data[i];
    try {
      schema.parse(row);
    } catch (error) {
      const errors = error as z.ZodError;
      const errorMessage = errors?.errors
        ?.map((e) => `${e.path.join(', ')}: ${e.message}`)
        .join('. ');
      validationErrors.push(errorMessage);
    }
  }
  if (validationErrors.length > 0) {
    console.log('validation Errors')
    return { isValid: false, message: validationErrors.join('. ') };
  }
  return { isValid: true, message: undefined };
}
