import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Box,
  Button,
  TextField,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import { styled } from "@mui/material/styles";
import COLOR from "../../../styled/colors";
import {
  Grid as GridTable,
  Table,
  TableHeaderRow,
  PagingPanel,
} from "@devexpress/dx-react-grid-material-ui";
import {
  PagingState,
  CustomPaging,
  SortingState,
  IntegratedSorting,
  Sorting,
} from "@devexpress/dx-react-grid";
import FilterListIcon from '@material-ui/icons/FilterList';
import { PageHeader } from "../../../presentation/withHeader";
import { useAppDispatch } from "../../../utils/hooks";
import DatePicker from "../../../presentation/DatePicker";
import {
  SPINNER_TOGGLE_OFF,
  SPINNER_TOGGLE_ON,
} from "../../../store/spinner/types";
import { callAPI, getAPI } from "../../../utils/network";
import { WebErrorType } from "../../../utils/error";
import { toggleModal } from "../../../store/modal/actions";
import moment from "moment";

const useStyle = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  button: {
    color: COLOR.GREEN_BUTTON,
    borderColor: COLOR.GREEN_BUTTON,
    marginRight: 50,
  },
  textBox: {
    marginTop: 10,
    marginBottom: 20,
  },
  subTitle: {
    marginTop: theme.spacing(3),
    fontWeight: 600,
  },
  tableHead: {
    "& .MuiTableCell-head": {
      fontWeight: 600,
    },
  },
  hyperlink: {
    color: COLOR.GREENT_TEXT,
    cursor: "pointer",
    textDecoration: "underline",
    textTransform: "capitalize",
    fontSize: 16,
  },
  filterIcon: {
    height: 28,
    width: 25,
    color: COLOR.GREENT_TEXT,
  },
  filterButton: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },
  filterColor: { backgroundColor: COLOR.WHITE },
}));

const initialState = {
  businessName: "",
  dateTo: "",
  dateFrom: "",
  currentPage: 0,
  showFilters: false,
  pageSize: 10,
  totalData: 0,
  totalPage: 0,
  activeBusinessList: [],
  sortColumnName: "",
  sortDirection: "desc",
  showAngusAnimals: false,
};

type Action =
  | { type: "change/businessName"; businessName: string }
  | { type: "change/dateTo"; dateTo: string }
  | { type: "change/dateFrom"; dateFrom: string }
  | { type: "change/page"; currentPage: number }
  | { type: "change/pageSize"; pageSize: number }
  | { type: "change/totalPage"; totalPage: number }
  | { type: "change/sortColumnName"; sortColumnName: string }
  | { type: "change/sortDirection"; sortDirection: string }
  | { type: "change/showFilters"; showFilters: boolean }
  | { type: "change/showAngusAnimals"; showAngusAnimals: boolean }
  | {
      type: "fetch/activeBusinessList";
      activeBusinessList: Array<any>;
      totalData: number;
    };

const reducer = (
  prevState: typeof initialState,
  action: Action
): typeof initialState => {
  const { type, ...actionData } = action;
  switch (action.type) {
    default:
      return { ...prevState, ...actionData };
  }
};

const StyledTable = styled(Table.Table)(({ theme }) => ({
  backgroundColor: COLOR.WHITE,
}));
const StyledPaging = styled(PagingPanel.Container)(({ theme }) => ({
  marginTop: 20,
}));
const StyledHeader = styled(TableHeaderRow.Content)(({ theme }) => ({
  fontWeight: 700,
}));

const HeaderComponentBase = ({ classes, ...props }) => (
  <Table.TableHead {...props} style={{ backgroundColor: COLOR.GRAY_SOLID }} />
);

export const TableComponent = (props) => <StyledTable {...props} />;
export const PagingComponent = (props) => <StyledPaging {...props} />;
export const HeaderComponent = (props) => <StyledHeader {...props} />;

const ActiveBusiness: React.FC<{}> = () => {
  const classes = useStyle();
  const dispatch = useAppDispatch();
  const [state, localDispatch] = useReducer(reducer, initialState);
  const timerFilter = useRef(null);

  const columnWidths = (
    cols: Array<{ name: string; title: string; width: number }>
  ) =>
    cols.map((dt) => ({
      columnName: dt.name,
      width: dt.width,
    }));

  const columns = [
    { name: "id", title: "No", width: 80 },
    { name: "name", title: "Business Name", width: 180 },
    { name: "businessId", title: "Business ID", width: 360 },
    { name: "active", title: "Last Active", width: 250 },
    { name: "businessType", title: "Business Type", width: 150 },
    { name: "users", title: "Number Users", width: 180 },
    { name: "plugins", title: "Number Plugins", width: 180 },
    { name: "created", title: "Created Date", width: 150 },
  ];

  const [sortingStateColumnExtensions] = useState([
    { columnName: "id", sortingEnabled: false },
    { columnName: "businessId", sortingEnabled: false },
    { columnName: "businessType", sortingEnabled: false },
  ]);

  const fetchAssets = useCallback(
    async (
      businessName = "",
      dateFrom = "",
      dateTo = "",
      currentPage = state.currentPage,
      itemPerPage = state.pageSize,
      sortColumnName = "",
      sortOrderDirection = state.sortDirection,
      angusBoolean = state.showAngusAnimals
    ) => {
      const data = {
        name: businessName,
        startDate: dateFrom,
        endDate: dateTo,
        page: currentPage + 1,
        perPage: itemPerPage,
        sort: sortColumnName ? sortColumnName : "created",
        sortOrder: sortOrderDirection,
        angus: angusBoolean,
      };
      try {
        dispatch({ type: SPINNER_TOGGLE_ON });

        const response = await callAPI({
          url: getAPI().POST.getActiveBusiness,
          method: "POST",
          data: data,
        });
        const activeBusiness = [];
        response.businesses?.map((item, index) =>
          activeBusiness.push({
            id: index + 1,
            name: item.details.companyName,
            active: item.lastActive && item.lastActive,
            users: item.usersCount,
            plugins: item.pluginsCount,
            businessId: item.externalIds[0].agliveToken,
            businessType: item.details.industryType,
            created: moment(item.createdAt).format("YYYY-MM-DD"),
          })
        );

        localDispatch({
          type: "fetch/activeBusinessList",
          activeBusinessList: activeBusiness,
          totalData: response.totalRow,
        });
      } catch (e) {
        const error = e as WebErrorType;
        dispatch(
          toggleModal({
            status: "failed",
            title: error.title,
            subtitle: error.message,
            button: "Close",
          })
        );
      } finally {
        dispatch({ type: SPINNER_TOGGLE_OFF });
      }
    },
    [
      state.currentPage,
      state.pageSize,
      state.showAngusAnimals,
      state.sortDirection,
    ]
  );

  const changePage = useCallback(
    (pageNum: number) => {
      localDispatch({ type: "change/page", currentPage: pageNum });
      fetchAssets(
        state.businessName,
        state.dateFrom ? moment(state.dateFrom).format("YYYY-MM-DD") : null,
        state.dateTo ? moment(state.dateTo).format("YYYY-MM-DD") : null,
        pageNum,
        state.pageSize,
        state.sortColumnName,
        state.sortDirection,
        state.showAngusAnimals
      );
    },
    [
      fetchAssets,
      state.businessName,
      state.dateFrom,
      state.dateTo,
      state.pageSize,
      state.showAngusAnimals,
      state.sortColumnName,
      state.sortDirection,
    ]
  );

  const changePageSize = useCallback(
    (size: number) => {
      localDispatch({ type: "change/pageSize", pageSize: size });
      localDispatch({ type: "change/page", currentPage: 0 });
      fetchAssets(
        state.businessName,
        state.dateFrom ? moment(state.dateFrom).format("YYYY-MM-DD") : null,
        state.dateTo ? moment(state.dateTo).format("YYYY-MM-DD") : null,
        0,
        size,
        state.sortColumnName,
        state.sortDirection,
        state.showAngusAnimals
      );
    },
    [
      fetchAssets,
      state.businessName,
      state.dateFrom,
      state.dateTo,
      state.showAngusAnimals,
      state.sortColumnName,
      state.sortDirection,
    ]
  );

  const changeSorting = useCallback(
    (sorting: Sorting[]) => {
      localDispatch({
        type: "change/sortColumnName",
        sortColumnName: sorting[0].columnName,
      });
      localDispatch({
        type: "change/sortDirection",
        sortDirection: sorting[0].direction,
      });
      fetchAssets(
        state.businessName,
        state.dateFrom ? moment(state.dateFrom).format("YYYY-MM-DD") : null,
        state.dateTo ? moment(state.dateTo).format("YYYY-MM-DD") : null,
        0,
        state.pageSize,
        sorting[0].columnName,
        sorting[0].direction,
        state.showAngusAnimals
      );
    },
    [
      fetchAssets,
      state.businessName,
      state.dateFrom,
      state.dateTo,
      state.pageSize,
      state.showAngusAnimals,
    ]
  );

  const changeShowFilter = useCallback(() => {
    localDispatch({
      type: "change/showFilters",
      showFilters: !state.showFilters,
    });
  }, [state.showFilters]);

  const handleDate = useCallback(
    (type: string, value: Date) => {
      switch (type) {
        case "dateFrom":
          localDispatch({
            type: "change/dateFrom",
            dateFrom: moment(value).format("YYYY-MM-DD"),
          });
          break;
        case "dateTo":
          localDispatch({
            type: "change/dateTo",
            dateTo: moment(value).format("YYYY-MM-DD"),
          });
      }

      if (type === "dateFrom") {
        fetchAssets(
          state.businessName,
          moment(value).format("YYYY-MM-DD"),
          state.dateTo ? moment(state.dateTo).format("YYYY-MM-DD") : null,
          0,
          state.pageSize,
          state.sortColumnName,
          state.sortDirection,
          state.showAngusAnimals
        );
      } else {
        fetchAssets(
          state.businessName,
          state.dateFrom ? moment(state.dateFrom).format("YYYY-MM-DD") : null,
          moment(value).format("YYYY-MM-DD"),
          0,
          state.pageSize,
          state.sortColumnName,
          state.sortDirection,
          state.showAngusAnimals
        );
      }
    },
    [
      fetchAssets,
      state.businessName,
      state.dateFrom,
      state.dateTo,
      state.pageSize,
      state.showAngusAnimals,
      state.sortColumnName,
      state.sortDirection,
    ]
  );

  const handleChangeName = useCallback(
    (value: string) => {
      localDispatch({
        type: "change/businessName",
        businessName: value,
      });

      clearTimeout(timerFilter.current);
      timerFilter.current = setTimeout(() => {
        fetchAssets(
          value,
          state.dateFrom && moment(state.dateFrom).format("YYYY-MM-DD"),
          state.dateTo && moment(state.dateTo).format("YYYY-MM-DD"),
          0,
          state.pageSize,
          state.sortColumnName,
          state.sortDirection,
          state.showAngusAnimals
        );
        clearTimeout(timerFilter.current);
        timerFilter.current = null;
      }, 1000);
    },
    [
      fetchAssets,
      state.dateFrom,
      state.dateTo,
      state.pageSize,
      state.showAngusAnimals,
      state.sortColumnName,
      state.sortDirection,
    ]
  );

  const handleChangeSwitch = useCallback(
    (value: boolean) => {
      localDispatch({
        type: "change/showAngusAnimals",
        showAngusAnimals: value,
      });
      clearTimeout(timerFilter.current);
      timerFilter.current = setTimeout(() => {
        fetchAssets(
          state.businessName,
          state.dateFrom && moment(state.dateFrom).format("YYYY-MM-DD"),
          state.dateTo && moment(state.dateTo).format("YYYY-MM-DD"),
          0,
          state.pageSize,
          state.sortColumnName,
          state.sortDirection,
          value
        );
        clearTimeout(timerFilter.current);
        timerFilter.current = null;
      }, 1000);
    },
    [
      fetchAssets,
      state.businessName,
      state.dateFrom,
      state.dateTo,
      state.pageSize,
      state.sortColumnName,
      state.sortDirection,
    ]
  );

  const handleResetFilter = useCallback(() => {
    localDispatch({
      type: "change/businessName",
      businessName: "",
    });

    localDispatch({
      type: "change/dateFrom",
      dateFrom: null,
    });

    localDispatch({
      type: "change/dateTo",
      dateTo: null,
    });

    localDispatch({
      type: "change/page",
      currentPage: 0,
    });

    localDispatch({
      type: "change/showAngusAnimals",
      showAngusAnimals: false,
    });

    fetchAssets(
      "",
      null,
      null,
      0,
      state.pageSize,
      state.sortColumnName,
      state.sortDirection,
      false
    );
  }, [fetchAssets, state.pageSize, state.sortColumnName, state.sortDirection]);

  useEffect(() => {
    fetchAssets();
  }, []);

  return (
    <PageHeader
      config={{
        title: "Active Business",
        margin: 1,
      }}
    >
      <Grid style={{ marginTop: 20 }}>
        <div className={classes.filterButton}>
          <FilterListIcon className={classes.filterIcon} />
          <Button id={"filterButton"} onClick={changeShowFilter}>
            <span
              style={{
                color: COLOR.GREENT_TEXT,
                textTransform: "capitalize",
              }}
            >
              <u>{"Filter"}</u>
            </span>
          </Button>
        </div>

        {state.showFilters && (
          <Grid container>
            <Grid item xs={2} style={{ margin: "10px 0 10px 0" }}>
              <Typography variant="h6" role="label">
                Business Name
              </Typography>
              <TextField
                style={{ width: "100%" }}
                variant="outlined"
                value={state.businessName}
                onChange={(e) => handleChangeName(e.target.value)}
              />
            </Grid>
            <Grid item xs={2} style={{ margin: "10px 0 20px 20px" }}>
              <Typography variant="h6" role="label">
                Date From (Last Active)
              </Typography>
              <DatePicker
                style={{ width: "90%" }}
                dateValue={state.dateFrom}
                handleChange={(date) => {
                  handleDate("dateFrom", date);
                }}
                errorStatus={true}
              />
            </Grid>
            <Grid item xs={2} style={{ margin: "10px 0 20px 20px" }}>
              <Typography variant="h6" role="label">
                Date To (Last Active)
              </Typography>
              <DatePicker
                style={{ width: "90%" }}
                dateValue={state.dateTo}
                handleChange={(date) => {
                  handleDate("dateTo", date);
                }}
                errorStatus={true}
              />
            </Grid>
            <Grid item xs={2} style={{ margin: "auto 0px 29px" }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.showAngusAnimals}
                      onChange={(e) => handleChangeSwitch(e.target.checked)}
                      color="primary"
                      name="Angus Animals"
                    />
                  }
                  label="Angus Animals"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={2} alignItems="flex-end" style={{ display: "flex" }}>
              {/* <Grid item xs={2} style={{ margin: 'auto 10px 29px' }}> */}
              <Button
                style={{ height: 60, margin: "0 0 20px 20px" }}
                onClick={handleResetFilter}
              >
                <span className={classes.hyperlink}>
                  <u>{"Reset Filter"}</u>
                </span>
              </Button>
            </Grid>
          </Grid>
        )}

        <Box
          ml={5}
          style={{
            marginLeft: 0,
            backgroundColor: COLOR.GRAY_SOLID,
          }}
        >
          <GridTable rows={state.activeBusinessList} columns={columns}>
            <Table
              tableComponent={TableComponent}
              headComponent={HeaderComponentBase}
              columnExtensions={columnWidths(columns)}
            />

            <PagingState
              currentPage={state.currentPage}
              onCurrentPageChange={changePage}
              pageSize={state.pageSize}
              onPageSizeChange={changePageSize}
            />
            <CustomPaging totalCount={state.totalData} />

            <SortingState
              defaultSorting={[{ columnName: "created", direction: "desc" }]}
              onSortingChange={changeSorting}
              columnExtensions={sortingStateColumnExtensions}
            />

            <IntegratedSorting />
            <TableHeaderRow showSortingControls />
            <PagingPanel pageSizes={[5, 10, 15, 20]} />
          </GridTable>
        </Box>
      </Grid>
    </PageHeader>
  );
};

export default ActiveBusiness;
