import walletConnect from '../../utils/walletConnect';

import { toggleModal } from '../modal/actions';

import { IJsonRpcRequest } from '@walletconnect/types';
import { AppThunk } from '../types';
import { GET_QRCODE, LOGIN_SUCCESS, LOGIN, LOGOUT, RESET_ERROR, AuthTypes } from './types';
import { callRequestHandler } from '../asset/utils';

import CONSTANTS from '../../config/constant';
import { WebErrorType, WebError, NetworkError, WEB_ERROR } from '../../utils/error';
import { SPINNER_TOGGLE_OFF } from '../spinner/types';
import { DROP_PROFILE_DATA } from '../profile/types';
import { GlobalAccessToken } from '@aglive/frontend-core';
import { callAPI,getAPI } from '../../utils/network';
import { authLogout } from '../../utils/auth'
import { DeviceUUID } from 'device-uuid';
import WalletConnect from '@walletconnect/client';

const {
  CONNECT,
  SESSION_UPDATE,
  CALL_REQUEST,
  DISCONNECT,
  WALLET_CONNECT,
} = CONSTANTS.WALLETCONNECT;

export function googleLogin(tokenId:string):AppThunk<Promise<void>>{
  return async(dispatch)=>{
    try{
      const response = await callAPI({
        url: getAPI().POST.googleLogin,
        method: 'POST',
        data: {token:tokenId}
      });
      if (response && GlobalAccessToken.setTokenFromRes({ data: { ...response } })) {
        dispatch({
          type: LOGIN_SUCCESS,
          // payload: payload.params[0].accounts[0]
        });
      }
      else {
        console.log('GlobalAccessToken.setTokenFromRes(res) =>> fail')
      }
    }
    catch(e){
      const error = e as WebErrorType;
      dispatch(toggleModal({
        status: 'failed',
        title: error.title,
        subtitle: error.message,
        button: 'Try Again',
        CTAHandler: () => window.location.reload(),
      }));
    }
  }
}

export function googleLoginV2(tokenId:string):AppThunk<Promise<void>>{
  return async(dispatch)=>{
    try{
      const response = await callAPI({
        url: getAPI().POST.googleLogin,
        method: 'POST',
        data: {code:tokenId, tokenType:'GSI'}
      });
      if (response && GlobalAccessToken.setTokenFromRes({ data: { ...response } })) {
        dispatch({
          type: LOGIN_SUCCESS,
          // payload: payload.params[0].accounts[0]
        });
      }
      else {
        console.log('GlobalAccessToken.setTokenFromRes(res) =>> fail')
      }
    }
    catch(e){
      const error = e as WebErrorType;
      dispatch(toggleModal({
        status: 'failed',
        title: error.title,
        subtitle: error.message,
        button: 'Try Again',
        CTAHandler: () => window.location.reload(),
      }));
    }
  }
}

export function login(): AuthTypes {
  return {
    type: LOGIN,
  };
}

export function appThunkLogout(skipRevoke = false): AppThunk<Promise<boolean>> {
  return async (dispatch) => {
    await logout(dispatch, skipRevoke)
    return Promise.resolve(true);
  }
}

// This way we can call it outside a component
export const logout = async (dispatch: (arg0: AuthTypes  | any) => void, skipRevoke = false) => {
  walletConnect.killSession();
  localStorage.removeItem(WALLET_CONNECT);
  if (skipRevoke === false) {
    await authLogout();
  }
  dispatch({ type: LOGOUT });
  sessionStorage.removeItem('persist:auth'); // HACK
  dispatch({ type: DROP_PROFILE_DATA });
}

export function resetError(): AuthTypes {
  return {
    type: RESET_ERROR,
  };
}

export function initWalletConnect(history: any): AppThunk<Promise<WalletConnect>> {
  return async (dispatch) => {
    try {
      const connector = await walletConnect.initWalletConnect();

      if (!connector) throw new WebError('WALLETCONNECT_ERROR', connector);
      
      connector.on(
        SESSION_UPDATE,
        (error: Error | null, payload: IJsonRpcRequest) => {
          if (error) throw new WebError("OFFLINE_ERROR", error);
        }
      );

      connector.on(DISCONNECT, (error: any | null, payload: any) => {
        if (error) {
          dispatch({ type: SPINNER_TOGGLE_OFF });

          dispatch(toggleModal({
            status: 'failed',
            title: 'Blockchain Error',
            subtitle: error.message,
          }));
        }
      });

      connector.on(
        CALL_REQUEST,
        (error: any | null, payload: IJsonRpcRequest) => {
          // if (error) throw error; // new Error(ERROR.OFFLINE);
          console.log("subscribeToEvents call_request->payload", payload, error);
          if (error) {
            callRequestHandler(dispatch, history)(new WebError('WALLETCONNECT_ERROR', String(error)), payload as any);
          } else {
            callRequestHandler(dispatch, history)(error, payload as any);
          }
        }
      );

      connector.on('error', e => {
        new NetworkError(e).sendErrortoSentry()
        dispatch(toggleModal({
          status: 'failed',
          title: WEB_ERROR.WALLETCONNECT_ERROR.title,
          subtitle: WEB_ERROR.WALLETCONNECT_ERROR.details,
          button: 'Reload',
          CTAHandler: () => window.location.reload(),
        }));
      });

      return Promise.resolve(connector);
    } catch (e) {
      if (e instanceof WebError) {
        callRequestHandler(dispatch, history)(e, undefined);
      } else {
        throw new NetworkError(e);
      }
    }
  }
}
