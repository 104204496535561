import React, {useState, useEffect} from 'react';
import {Link, useRouteMatch} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';

import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';


const Dashboard: React.FC<{}> = () => {
  const {path} = useRouteMatch();
  const dispatch = useAppDispatch();

  const userId = useAppSelector((state) => state.auth.wallet);

  return (
    <Grid>
     
    </Grid>
  );
};

export default Dashboard;

