import React, { useCallback, useReducer, useEffect, useRef, useState } from 'react';
import {Redirect, useHistory, useLocation, useParams, useRouteMatch} from 'react-router-dom';
import {
  makeStyles,
  Grid,
  Typography,
  TextField,
  Box,
  MuiThemeProvider,
} from '@material-ui/core';

import COLOR from '../../../styled/colors';
import MyButton from '../../../presentation/button';
import { PageHeader } from '../../../presentation/withHeader';
import { useAppDispatch,useAppSelector } from '../../../utils/hooks';

import CONSTANT from '../../../config/constant';
import { FormGenerator,FormInputFunctions } from '../../../presentation/FormGenerator';
import { submitProject,inviteUserAgain } from '../../../store/add/actions';

const useStyle = makeStyles((theme) => ({
    button: {
      color: COLOR.GREEN_BUTTON,
      borderColor: COLOR.GREEN_BUTTON,
      marginRight: 50,
    },
  }));

export interface ManageUserType {
    name: string;
    role: string;
    email: string;
    phone: string;
    invitationDate?: Date;
    addedDate?: Date;
    deactivatedDate?: Date;
    userId?: string;
    invitationCode?: string;
}

const SingleUserManage: React.FC<{type: 'edit' | 'view' | 'add'}> = ({type}) => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    
    const classes = useStyle();


    const formRef = useRef<FormInputFunctions<typeof userInfo>>();


    const businessProfile = useAppSelector(
        (state) => state.user.businessProfileData,
    );
    const userLibraryLabels = CONSTANT.EN_LABELS;
   
    const userLabels = CONSTANT.EN_LABELS;

    const data = [
        userLibraryLabels.name,
        userLibraryLabels.phone,
        userLibraryLabels.email,
        userLibraryLabels.role,
        userLibraryLabels.status,
    ];

    const { userId,businessId, inviteId } = useParams<{userId: string,businessId: string, inviteId:string}>();
    const userList: Array<ManageUserType> = useAppSelector(
        (state) => state.add.users,
    );

    let user:ManageUserType = {
        name: '',
        role: '',
        email: '',
        phone: '',
        invitationDate: null,
        addedDate: null,
        deactivatedDate: null,
        userId: '',
        invitationCode: null,
    };

    function getCurrentSelUser(){
        for(const item of userList){
            if(item.userId && item.userId === inviteId){
                return item;
            } else if (item.invitationCode && item.invitationCode === inviteId) {
                return item;
            }
        }
    }

    if(type !=='add'){
        user = getCurrentSelUser()
        if (!user){
            history.push(`/private`);
        }
    } else {
        // do nothing
    }

    let userInfo = {
        userId: user?.userId ? user?.userId : user?.invitationCode,
        name: user?.name,
        role: user?.role,
        phone: user?.phone,
        email: user?.email,
        status: '',
        statusChange: '',
        roleChange: '',
        invitationCode: null,
    };

    const [formReady, setFormReady] = useState(userInfo?.name ? true : false);
 
    if (user?.deactivatedDate) { // Added user but is inactive
        userInfo.status = userLibraryLabels.inactive;
    } else if (user?.addedDate) { // Added user and active
        userInfo.status = userLibraryLabels.active;
    } else if (user?.invitationCode) { // User Pending invite
        userInfo.status = userLibraryLabels.pending;
        userInfo.invitationCode = userInfo.userId
    } else { // New user
        userInfo.status = 'new user';
    }

    const roleList = [
        {label: userLabels.admin, value: 'admin'},
        {label: userLabels.user, value: 'user'},
    ];

    const formInputs = [
        {
          type: 'INPUT',
          name: 'name',
          titleKey: 'name',
          required: true,
          value: userInfo?.name,
          viewMode: type === 'view',
        },
        {
          type: 'SELECT',
          name: 'role',
          titleKey: 'role',
          required: true,
          items: roleList,
          //placeholder: `--${userLabels.role}--`,
          viewMode: type === 'view',
          value: userInfo?.role && type === 'view' ? roleList.find(rl => rl.value === userInfo.role).label : userInfo?.role?.toLowerCase()
        },
        {
          type: 'PHONE',
          name: 'phone',
          titleKey: 'phone',
          required: true,
          viewMode: type === 'view',
          value: userInfo?.phone
        },
        {
          type: 'EMAIL',
          name: 'email',
          titleKey: 'emailAddress',
          required: true,
          viewMode: type === 'view',
          value: userInfo?.email
        },
        {
          type: 'SELECT',
          name: 'status',
          titleKey: 'status',
          skip: !userInfo.name,
          required: true,
          //placeholder: `--${userLabels.status}--`,
          items: [
            {label: userLabels.active, value: userLabels.active}, 
            {label: userLabels.inactive, value: userLabels.inactive}
          ],
          viewMode: type === 'view' || type === 'edit' ,
          value: userInfo?.status === userLibraryLabels.pending ? userLabels.pendingAcceptance : userInfo.status
        },
      ];
    

    const test = [
        {
            type: 'EMAIL',
            name: 'name',
            titleKey: 'name',
            required: true,
            value: '1234',
            viewMode: false
          },
    ]

    return (
        
        <PageHeader 
            config={{
                title: `${type} user`,
                margin: 1,
                back: true,
            }}
        >
            <Grid container spacing={3} style={{marginTop:"2rem"}}>
                <FormGenerator ref={formRef} formInputs={formInputs} onReady={setFormReady} />
            </Grid>
            <Grid container justifyContent="flex-end">                
                {
                    type === 'edit' && 
                    <MyButton 
                        text={userLabels.inviteAgain}
                        variant="outlined"
                        buttonClass={classes.button}
                        onClick={() =>
                            dispatch(
                                inviteUserAgain(
                                    businessId,
                                    inviteId,
                                    () => {
                                        history.push(`/private/monitor/add/users/${businessId}`);
                                    },
                            ),
                            )
                        }
                    /> 
                }

                {
                    type !== 'view' &&
                    <MyButton
                        text={userInfo.status === '' ? userLabels.invite : userLabels.save}
                        variant="contained"
                        disabled={!formReady}
                        
                        onClick={() => {
                        if (userInfo.status === userLibraryLabels.pending) {
                            dispatch(
                            submitProject(
                                {...userInfo, ...formRef.current.getValues()},
                                businessId,
                                () => {
                                history.push(`/private/monitor/add/users/${businessId}`);
                                },
                            ),
                            );
                        } else {
                            dispatch(
                            submitProject(
                                {...userInfo, ...formRef.current.getValues()},
                                businessId,
                                () => {
                                history.push(`/private/monitor/add/users/${businessId}`);
                                },
                            ),
                            );
                        }
                        }}
                    />
                }
            </Grid>
        </PageHeader>     
    )
}

export default SingleUserManage;

