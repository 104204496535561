import React, { useReducer, useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch, Link } from "react-router-dom";
import { makeStyles, Grid, Box } from "@material-ui/core";
import {
    Table,
    TableHeaderRow,
    Grid as TableGrid,
    PagingPanel,
} from "@devexpress/dx-react-grid-material-ui";
import { PagingState, IntegratedPaging } from "@devexpress/dx-react-grid";
import COLOR from "../../../../styled/colors";
import MyButton from "../../../../presentation/button";
import { PageHeader } from "../../../../presentation/withHeader";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks";
import { getBusinessInfo, deleteBusinessPlugin } from "../../../../store/businessAccountPlugin/actions";
import BusinessIcon from "@material-ui/icons/Business";
import CONSTANT from "../../../../config/constant";
import { styled } from "@mui/material/styles";
import { callAPI, getAPI } from '../../../../utils/network';
import DeleteIcon from '@material-ui/icons/Delete';
import { toggleModal, toggleModalOff } from "../../../../store/modal/actions";
import ConfirmButtons from "../../../../presentation/ConfirmButtons";


const columns = [
    //{ name: 'value', title: 'Item', width: 20 },
    { name: "name", title: "Name", width: 150 },
    { name: "pluginId", title: "Plugin ID", width: 350 },
    { name: "activationDate", title: "Activation Date", width: 150 },
    { name: "deactivationDate", title: "Deactivation Date", width: 150 },
    { name: "status", title: "Status", width: 150 },
    { name: "actions", title: "Action", width: 150 },
];

const columnWidths = (
    cols: Array<{ name: string; title: string; width: number }>
) =>
    cols.map((dt) => ({
        columnName: dt.name,
        width: dt.width,
    }));

const StyledTable = styled(Table.Table)(({ theme }) => ({
    backgroundColor: COLOR.WHITE,
}));
const StyledPaging = styled(PagingPanel.Container)(({ theme }) => ({
    marginTop: 20,
}));
const StyledHeader = styled(TableHeaderRow.Content)(({ theme }) => ({
    fontWeight: 700,
}));

const HeaderComponentBase = ({ classes, ...props }) => (
    <Table.TableHead {...props} style={{ backgroundColor: COLOR.GRAY_SOLID }} />
);

const TableComponent = (props) => <StyledTable {...props} />;
const PagingComponent = (props) => <StyledPaging {...props} />;
const HeaderComponent = (props) => <StyledHeader {...props} />;

const BusinessPluginManage: React.FC<{}> = () => {
    const { businessId } = useParams<{ businessId: string }>();
    const [ businessName, setBusinessName ] = useState("");
    const [ businessTokenData, setBusinessTokenData ] = useState({});
    const [ businessPluginsList, setBusinessPluginsList ] = useState([]);
    const [ tablePluginData, setTablePluginData ] = useState([])

    const dispatch = useAppDispatch();

    const generatePluginTable = () => {
        const temp = []; 
        for (let x = 0; x < businessPluginsList.length; x++) {
            temp.push({
                //value: x+1,
                name: businessPluginsList[x]?.name || "N/A",
                pluginId: businessPluginsList[x]?.pluginId || "N/A",
                activationDate: businessPluginsList[x]?.activationDate || "N/A",
                deactivationDate: businessPluginsList[x]?.deactivationDate || "N/A",
                status: businessPluginsList[x]?.status || "N/A",
                actions: (
                    <>
                        <div onClick={ () => {
                            dispatch(toggleModal({
                                title: "Are you sure to remove the plugin from the business?",
                                subtitle: [
                                    `BusinessId: ${businessId}`,
                                    `BusinessName: ${businessName}`,
                                    `PluginName: ${businessPluginsList[x]?.name}`,
                                    `PluginId: ${businessPluginsList[x]?.pluginId}`,
                                    
                                ],
                                status: 'warning',
                                renderButton: <ConfirmButtons 
                                    confirmText='Delete'
                                    onCancel={() => {
                                        dispatch(toggleModalOff())
                                    }}
                                    onConfirm={async () => {
                                        await dispatch(deleteBusinessPlugin(businessId,businessPluginsList[x]?.pluginId ))
                                        .then( () => {
                                            dispatch(getBusinessInfo(businessId)).then(
                                                (businessData: any) => {
                                                    if(businessData && businessData.length > 0){
                                                        let businessTokenData = businessData[0] // Only pick first since should only have one business for one businessId
                                                        let businessName = businessTokenData?.details?.companyName
                                                        let businessPlugin = businessTokenData?.plugins
                                        
                                                        setBusinessName(businessName)
                                                        setBusinessTokenData(businessTokenData)
                                                        setBusinessPluginsList(businessPlugin)
                                                    }  
                                                }
                                            )
                                        })
                                    }}
                                />
                              }))
                        } }>
                            <DeleteIcon></DeleteIcon>
                        </div>
                    </>
                ),
            });
        }
        setTablePluginData(temp)
    };

    useEffect(() => {
        dispatch(getBusinessInfo(businessId)).then(
            (businessData: any) => {
                if(businessData && businessData.length > 0){
                    let businessTokenData = businessData[0] // Only pick first since should only have one business for one businessId
                    let businessName = businessTokenData?.details?.companyName
                    let businessPlugin = businessTokenData?.plugins
    
                    setBusinessName(businessName)
                    setBusinessTokenData(businessTokenData)
                    setBusinessPluginsList(businessPlugin)
                }  
            }
        )
    }, []);

    useEffect(() => {
        generatePluginTable()
    }, [businessPluginsList]);

    return (
        <PageHeader
            config={{
                title: "Business Plugin Manage",
                margin: 1,
                back: true,
            }}
        >
            <br></br>
            {/***** INFORMATION SECTION *****/}
            <Grid container direction="row" spacing={2} alignItems="center">
                <Grid item>
                    <BusinessIcon style={{ fontSize: "2rem" }}></BusinessIcon>
                </Grid>
                <Grid item>
                    <div style={{ fontSize: "1.5rem", marginBottom: "0.3rem" }}>
                        {" "}
                        <b>{businessName} </b>
                    </div>
                    <div style={{ fontSize: "0.8rem" }}>
                        {" "}
                        <i>
                            <span>ID: </span>
                            {businessId}
                        </i>
                    </div>
                </Grid>
            </Grid>
            <br></br>
            {/***** TABLE SECTION *****/}
            <Grid container>
                <Box
                    p={"40px 15px 30px 15px"}
                    style={{
                        backgroundColor: COLOR.GRAY_SOLID,
                        width: "100%",
                    }}
                >
                    <TableGrid rows={tablePluginData} columns={columns}>
                        <PagingState />
                        <IntegratedPaging />
                        <Table
                            tableComponent={TableComponent}
                            headComponent={HeaderComponentBase}
                            columnExtensions={columnWidths(columns)}
                        />
                        <TableHeaderRow contentComponent={HeaderComponent} />
                        <PagingPanel
                            pageSizes={[5, 10, 15, 20]}
                            containerComponent={PagingComponent}
                        />
                    </TableGrid>
                </Box>
            </Grid>
        </PageHeader>
    );
};

export default BusinessPluginManage;
