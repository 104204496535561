import { Box, Grid, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { PageHeader } from '../../../presentation/withHeader';
import { web3Service } from '../../../service/web3/web3Service';
import { toggleModal } from '../../../store/modal/actions';
import {
  SPINNER_TOGGLE_OFF,
  SPINNER_TOGGLE_ON,
} from '../../../store/spinner/types';
import { WebErrorType } from '../../../utils/error';
import { useAppDispatch } from '../../../utils/hooks';
import { callAPI, getAPI } from '../../../utils/network';
import DataTable from './NestedDataTable';
import { toColumnExtensions } from './tableHeader';

type TransactionType = {
  tokenId: string;
  blockchainAssetHash: string;
  blockchainActivitiesHash: Array<Record<string, string>>;
  transactionType: string;
  updatedAt: string;
  database: Record<string, any>;
};

// const fecthTransactions = async (): Promise<TransactionType[]> => {
const fecthTransactions = async () => {
  const dbResponse = await callAPI({
    url: getAPI().GET.getLatestTransactions,
    method: 'GET',
  })
    .then((res) => res)
    .catch((err) => {
      console.log(err);
      return [];
    });
  console.log('dbResponse', dbResponse);
  const tokens: Array<string> = dbResponse
    .map((transaction) => transaction.tokenId)
    .filter((value, i, arr) => arr.indexOf(value) === i);
  console.log('tokens', tokens);
  const blockchainResponse = await Promise.all(
    tokens.map((token) => web3Service.getAssetByToken(token))
  );
  console.log('blockchainResponse', blockchainResponse);
  const assets = blockchainResponse.reduce(
    (result: Record<string, any>, asset, i) => {
      const tokenId = tokens[i];
      return {
        ...result,
        [tokenId]: {
          assetHash: asset[0],
          activitiesHash: asset[1].map((activityHash) => ({ activityHash })),
        },
      };
    },
    {}
  );
  return dbResponse.map((dbDetail, i) => {
    const tokenId = dbDetail.tokenId;
    return {
      tokenId: tokenId,
      transactionType: dbDetail.transactionType,
      blockchainAssetHash: assets[tokenId].assetHash,
      blockchainActivitiesHash: assets[tokenId].activitiesHash,
      updatedAt: dbDetail.updatedAt,
      database: dbDetail,
    };
  });
};

const COLUMNS = [
  {
    name: 'tokenId',
    title: 'Token ID',
    width: 150,
  },
  {
    name: 'transactionType',
    title: 'Transaction Type',
    width: 150,
  },
  {
    name: 'blockchainAssetHash',
    title: 'Asset Hash',
    width: 500,
  },
  {
    name: 'updatedAt',
    title: 'Updated At',
    width: 300,
  },
];

const COLUMN_EXTENSIONS = toColumnExtensions(COLUMNS);

const BlockchainTransactions = () => {
  const [transactions, setTransactions] = useState<TransactionType[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch({ type: SPINNER_TOGGLE_ON });
    fecthTransactions()
      .then(setTransactions)
      .catch((e) => {
        const error = e as WebErrorType;
        dispatch(
          toggleModal({
            status: 'failed',
            title: error.title,
            subtitle: error.message,
            button: 'Close',
          })
        );
      })
      .finally(() => {
        dispatch({ type: SPINNER_TOGGLE_OFF });
      });
  }, [dispatch]);

  return (
    <PageHeader
      config={{
        title: 'Latest 10 Transactions',
        margin: 1,
      }}
    >
      <Grid
        container
        direction='column'
        component={Box}
        style={{
          marginTop: 30,
          overflowY: 'auto',
        }}
      >
        <Typography variant='h3' style={{ marginRight: 5, marginBottom: 10 }}>
          Transactions
        </Typography>
        <DataTable
          rows={transactions}
          columns={COLUMNS}
          columnExtensions={COLUMN_EXTENSIONS}
        />
      </Grid>
    </PageHeader>
  );
};

export default BlockchainTransactions;
